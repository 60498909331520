import { useState, useContext } from 'react';
import { LoaderContext } from 'shared/context/loaderContext';
import { NotificationContext } from 'shared/context/notificationContext';
import { useCm360Api } from 'shared/const/api-backend';
import { UserDataContext } from 'shared/context/userDataContext';
import dayjs from 'dayjs';
import { CONFIG_APP } from '../../../../shared/const/app.config';
import { reportError } from 'shared/services/raygunService';

const useCalculateEndorsementPrice = () => {
  const { channel, dataCenter } = useContext(UserDataContext);
  const { setLoading } = useContext(LoaderContext);
  const [endorsementPrice, setEndorsementPrice] = useState(null);
  const { showNotification } = useContext(NotificationContext);
  const [error, setError] = useState(null);
  const API = useCm360Api();

  const calculateEndorsementPrice = async (policy, endorsement) => {
    setLoading(true);

    try {
      const apiUrl = `/ui-proxy/ws-partners/api/platforms/${channel.psPlatform}/catalogs/${policy.product.iac}/policies/${policy.policy.policyNumber}/endorsements/calculate`;
      const payload = {
        startDate: policy.insuranceData.startDate,
        endDate: dayjs(endorsement.travelEndDate).format(
          CONFIG_APP.DATE_STRING_FORMAT,
        ),
      };

      const response = await API.post(apiUrl, payload);
      const responseData = response.data;
      setEndorsementPrice(responseData.price);
    } catch (error) {
      setError(error);
      showNotification(
        t(`POLICY_DETAILS.ENDORSEMENTS.errorCalculatingEndorsementPrice`),
        'error',
      );
      reportError(error);
    } finally {
      setLoading(false);
    }
  };

  const resetEndorsementPrice = () => {
    setEndorsementPrice(null);
  };

  return {
    endorsementPrice,
    calculateEndorsementPrice,
    resetEndorsementPrice,
    error,
  };
};

export default useCalculateEndorsementPrice;
