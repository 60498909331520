/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://material-ui.com/store/items/soft-ui-pro-dashboard/
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Soft UI Dashboard PRO React base styles
import borders from 'assets/theme/base/borders';
import colors from 'assets/theme/base/colors';

// Soft UI Dashboard PRO React helper functions
import pxToRem from 'assets/theme/functions/pxToRem';

const { borderWidth } = borders;
const { light } = colors;

const tableCell = {
  styleOverrides: {
    root: {
      padding: `${pxToRem(8)} ${pxToRem(12)}`,
      borderBottom: `${borderWidth[1]} solid ${light.main}`,
      fontSize: `${pxToRem(12)}`,
    },
  },
};

export default tableCell;
