/**
 =========================================================
 * Soft UI Dashboard PRO React - v4.0.0
 =========================================================

 * Product Page: https://material-ui.com/store/items/soft-ui-pro-dashboard/
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import React, { useState, useEffect, useContext } from 'react';

// react-router components
import { useLocation, useNavigate } from 'react-router-dom';

// prop-types is a library for typechecking of props.
import PropTypes from 'prop-types';

// @mui core components
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import Icon from '@mui/material/Icon';

// Soft UI Dashboard PRO React components
import SoftBox from 'components/SoftBox';
import SoftTypography from 'components/SoftTypography';

// Soft UI Dashboard PRO React example components
import Breadcrumbs from 'examples/Breadcrumbs';
import NotificationItem from 'examples/Items/NotificationItem';

// Custom styles for DashboardNavbar
import {
  navbar,
  navbarContainer,
  navbarRow,
  navbarIconButton,
} from 'examples/Navbars/DashboardNavbar/styles';

// Soft UI Dashboard PRO React context
import { useSoftUIController, setTransparentNavbar } from 'context';

import { NotificationContext } from '../../../shared/context/notificationContext';
import SoftSelect from 'components/SoftSelect';
import { UserDataContext } from 'shared/context/userDataContext';
import { useTranslation } from 'react-i18next';
import { AuthContext } from '../../../AuthContext';
import { getLoginRouteByPlatform } from 'shared/helpers/loginPageRoute.helper';

function DashboardNavbar({ absolute, light, isMini, children }) {
  const { t } = useTranslation();
  const { businessUnits, saveBusinessUnit } = useContext(UserDataContext);
  const { platform, role, logout } = useContext(AuthContext);
  const [navbarType, setNavbarType] = useState();
  const [defaultValue, setDefaultValue] = useState([]);
  const [controller, dispatch] = useSoftUIController();
  const { transparentNavbar, fixedNavbar } = controller;
  const pathname = useLocation().pathname.toString();
  const [openMenu, setOpenMenu] = useState(false);
  const route = useLocation().pathname.split('/').slice(1);
  const navigate = useNavigate();
  const isDetailsPage = useLocation().pathname.includes('-details');
  const { showNotification } = useContext(NotificationContext);
  const options =
    businessUnits && businessUnits.length > 0
      ? businessUnits.map((unit) => ({
          value: unit.shopId,
          label: `${unit.country?.country} - ${unit.shopName}`,
        }))
      : [];

  useEffect(() => {
    setDefaultBusinessUnit();

    // Setting the navbar type
    if (fixedNavbar) {
      setNavbarType('sticky');
    } else {
      setNavbarType('static');
    }

    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      setTransparentNavbar(
        dispatch,
        (fixedNavbar && window.scrollY === 0) || !fixedNavbar,
      );
    }

    /**
         The event listener that's calling the handleTransparentNavbar function when
         scrolling the window.
         */
    window.addEventListener('scroll', handleTransparentNavbar);

    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener('scroll', handleTransparentNavbar);
  }, [dispatch, fixedNavbar, businessUnits]);

  const setDefaultBusinessUnit = () => {
    const savedBusinessUnitId = localStorage.getItem('selectedBusinessUnit');
    if (businessUnits && businessUnits.length > 0) {
      const savedBusinessUnit = businessUnits.find(
        (unit) => unit.shopId === savedBusinessUnitId,
      );
      const unit = savedBusinessUnit || businessUnits[0];

      setDefaultValue({
        value: unit.shopId,
        label: `${unit.country?.country} - ${unit.shopName}`,
      });
    } else {
      setDefaultValue([]);
    }
  };

  const handleCloseMenu = () => setOpenMenu(false);
  const handleLogout = () => {
    logout();
    localStorage.removeItem('platform');
    const loginRoute = getLoginRouteByPlatform(platform);
    navigate(loginRoute);
    showNotification('NOTIFICATION.success.logout', 'success');
  };

  const handleSelectChange = (selectedOption) => {
    const selectedBusinessUnit = businessUnits.find(
      (center) => center.shopId === selectedOption.value,
    );
    saveBusinessUnit(selectedBusinessUnit);
    setDefaultValue({
      value: selectedOption.value,
      label: selectedOption.label,
    });
  };

  // Render the notifications menu
  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      sx={{ mt: 2 }}
    >
      <NotificationItem
        color="secondary"
        image={
          <Icon
            fontSize="small"
            sx={{ color: ({ palette: { white } }) => white.main }}
          >
            payment
          </Icon>
        }
        title={['', 'Payment successfully completed']}
        date="2 days"
        onClick={handleCloseMenu}
      />
    </Menu>
  );

  return (
    <>
      {!pathname.includes('simplified-booking') && (
        <AppBar
          position={absolute ? 'absolute' : navbarType}
          color="inherit"
          sx={(theme) => navbar(theme, { transparentNavbar, absolute, light })}
        >
          <Toolbar sx={(theme) => navbarContainer(theme)}>
            <SoftBox
              color="inherit"
              mb={{ xs: 1, md: 0 }}
              sx={(theme) => navbarRow(theme, { isMini })}
            >
              {children ? (
                children
              ) : (
                <Breadcrumbs
                  icon="home"
                  title={route[route.length - 1]}
                  route={route}
                  light={light}
                />
              )}
            </SoftBox>

            {isMini ? null : (
              <SoftBox sx={(theme) => navbarRow(theme, { isMini })}>
                <SoftTypography pr={1} sx={{ fontSize: '1rem' }}>
                  {t('HOME.MENU.store')}
                </SoftTypography>
                <SoftBox pr={1}>
                  <SoftSelect
                    placeholder={t('HOME.MENU.storePlaceholder')}
                    options={options}
                    value={defaultValue}
                    isDisabled={isDetailsPage}
                    onChange={handleSelectChange}
                    className="businessUnitSelect"
                  />
                </SoftBox>
                <SoftBox color={light ? 'white' : 'inherit'}>
                  <IconButton
                    onClick={handleLogout}
                    sx={navbarIconButton}
                    size="small"
                  >
                    <Icon
                      sx={({ palette: { dark, white } }) => ({
                        color: light ? white.main : dark.main,
                      })}
                    >
                      logout_icon
                    </Icon>

                    <SoftTypography
                      variant="button"
                      fontWeight="medium"
                      color={light ? 'white' : 'dark'}
                    >
                      Logout
                    </SoftTypography>
                  </IconButton>
                  {renderMenu()}
                </SoftBox>
              </SoftBox>
            )}
          </Toolbar>
        </AppBar>
      )}
    </>
  );
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
  children: PropTypes.node,
};

export default DashboardNavbar;