import React from 'react';
import PropTypes from 'prop-types';
import Paper from '@mui/material/Paper';
import SoftTypography from '../../../../components/SoftTypography';
import './redirectToDashboard.scss';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import theme from '../../../../assets/theme';

const RedirectToDashboard = () => {
  const { t } = useTranslation();
  const { grey } = theme.palette;

  return (
    <Paper
      className="welcome-container-right welcome-message"
      variant="outlined"
      style={{
        height: '100%',
        marginBottom: '1rem',
        borderRadius: '0.75rem',
        borderColor: grey[500],
      }}
    >
      <SoftTypography component="span" variant="body1">
        <Link id="link" to={'/dashboard'}>
          {t('WELCOME_PAGE.REDIRECT_TO_DASHBOARD.title')}
        </Link>
      </SoftTypography>
    </Paper>
  );
};

export default RedirectToDashboard;
