import React, { memo, useContext } from 'react';
import Paper from '@mui/material/Paper';
import './welcomeTravelAlerts.scss';
import { Box, Skeleton, useMediaQuery } from '@mui/material';
import theme from '../../../../assets/theme';
import SoftTypography from '../../../../components/SoftTypography';
import { WelcomePageConfigContext } from '../../../../shared/context/welcome-page-config-context';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const WelcomeTravelAlerts = () => {
  const { t } = useTranslation();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  const { pinnedTravelAlert, isConfigLoading } = useContext(
    WelcomePageConfigContext,
  );
  const { shortDescription } = pinnedTravelAlert?.data?.attributes || {};
  const { grey } = theme.palette;
  const skeletonLoader = (
    <Skeleton variant="rounded" width="100%" height={40} />
  );
  const content = (
    <>
      <SoftTypography variant="body2" component="span">
        {shortDescription || t('TRAVEL_ALERTS.noneAvailable')}
      </SoftTypography>
    </>
  );

  return (
    <Paper
      variant="outlined"
      className={`${
        matches ? 'travel-alerts-desktop' : 'travel-alerts-mobile'
      } travel-alerts`}
      style={{
        borderColor: grey[500],
      }}
    >
      <SoftTypography variant="body3">
        {t('TRAVEL_ALERTS.title')}
      </SoftTypography>
      <Box sx={{ marginTop: '.5rem' }}>
        <SoftTypography component="span" variant="body2">
          {t('TRAVEL_ALERTS.subtext')}{' '}
          <Link to="/travel-alerts">{t('TRAVEL_ALERTS.subtextLinkText')}</Link>.
        </SoftTypography>
      </Box>
      <Box sx={{ marginTop: '.3rem' }}>
        {isConfigLoading ? skeletonLoader : content}
      </Box>
    </Paper>
  );
};

export default memo(WelcomeTravelAlerts);
