import { useCallback, useMemo } from 'react';
import countryCodes from '../../countryCodes.json';

// The phone number should have between 10 and 13 number of digits.
// It is because the regular phone number in Australia has 10 digits and adding “+61” makes it 13
// This function suppose to handle only positive scenarious of users input like
// User entered 10 digit number without dial code
// User entered 12 digit number without plus sign
// User entered 13 digit number with full dial code
// All edge cases like wrong characters, brackets, etc. should be handled on server
function formatPhoneNumber(phoneNumber, dialCode) {
	const MINIMAL_NUMBER_LENGTH = 10;
	const PHONE_NUMBER_WITH_CODE_LENGTH = 13;
  const PHONE_NUMBER_WITHOUT_PLUS_CHARACTER_LENGTH =
    PHONE_NUMBER_WITH_CODE_LENGTH - 1;
  if (!phoneNumber) return null;
	let phone = String(phoneNumber).trim();
  if (phone.length < MINIMAL_NUMBER_LENGTH) return null;
  
  const isNumberContainsDialCode =
    phone.length === PHONE_NUMBER_WITH_CODE_LENGTH &&
    phone.startsWith(dialCode);

  if (isNumberContainsDialCode) return `${phone.slice(0,3)} ${phone.slice(3)}`;

  const isPlusCharacterMissing =
    phone.length === PHONE_NUMBER_WITHOUT_PLUS_CHARACTER_LENGTH &&
    !phone.startsWith('+');

  if (isPlusCharacterMissing) return `+${phone.slice(0,2)} ${phone.slice(2)}`;

  return `${dialCode} ${phone}`;
}

const useCountry = (countryCode, stateCode) => {
  const foundCountry = countryCodes.find(
    (country) => country.code === countryCode,
  );

  const { countryName, dialCode, stateName } = useMemo(() => {
    if (!foundCountry) {
      return { countryName: '', dialCode: '', stateName: '' };
    }

    const countryName = foundCountry.name || '';
    const dialCode = foundCountry.dial_code || '';
    let stateName = '';

    if (stateCode && foundCountry.states) {
      const foundState = foundCountry.states.find(
        (state) => state.code === stateCode,
      );
      stateName = foundState?.label || '';
    }

    return { countryName, dialCode, stateName };
  }, [foundCountry, stateCode]);

  const humanizePhoneNumber = useCallback(
    (phoneNumber) => formatPhoneNumber(phoneNumber, dialCode),
    [dialCode],
  );

  return { stateName, dialCode, countryName, humanizePhoneNumber };
};

export default useCountry;
