import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import './endorsementsTab.scss';
import SuiButton from '../../../../components/SoftButton';
import EndorsementsTable from './endorsementsTable/endorsements-table';
import SoftTypography from 'components/SoftTypography';
import AddIcon from '@mui/icons-material/Add';
import PaymentIcon from '@mui/icons-material/Payment';
import BlockIcon from '@mui/icons-material/Block';
import SubjectIcon from '@mui/icons-material/Subject';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import SoftBox from 'components/SoftBox';
import SoftInput from 'components/SoftInput';
import SoftSelect from 'components/SoftSelect';
import SoftButton from 'components/SoftButton';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import dayjs from 'dayjs';
import SoftDatePicker from 'components/SoftDatePicker';
import EndorsementPayment from './endorsementPayment/endorsementPayment';
import useFetchEndorsementDetails from './useFetchEndorsementDetails';
import useAddEndorsement from './useAddEndorsement';
import useCancelEndorsement from './useCancelEndorsement';
import useCalculateEndorsementPrice from './useCalculateEndorsementPrice';
import { NotificationContext } from 'shared/context/notificationContext';
import useFetchEndorsementRefund from './useFetchEndorsementRefund';
import DialogConfirmation from 'shared/dialogs/DialogConfirmation';
import { CONFIG_APP } from '../../../../shared/const/app.config';
import CurrencyComponent from 'shared/components/Currency/Currency';
import PciPal from './pciPal/pciPal';
import { add, differenceInDays, format } from 'date-fns';
import Divider from '@mui/material/Divider';
import { calculateTripDuration } from '../../../../shared/helpers/tripDuration.helper';
import useDebouncedValue from '../../../../shared/const/debounce';
import { checkIfPolicyCanBeExtended } from '../policyDetails.helper';
import { UserDataContext } from '../../../../shared/context/userDataContext';

const EndorsementsTab = ({ policyDetails, endorsements, fetchData }) => {
  const { t } = useTranslation();
  const { userRoles } = useContext(UserDataContext);
  const {
    endorsementDetails,
    endorsementTripDurationDetails,
    fetchEndorsementDetails,
  } = useFetchEndorsementDetails();
  const { addEndorsement } = useAddEndorsement();
  const { cancelEndorsement } = useCancelEndorsement();
  const { endorsementPrice, calculateEndorsementPrice, resetEndorsementPrice } =
    useCalculateEndorsementPrice();
  const [endorsementsTripDuration, setEndorsementsTripDuration] =
    useState(null);
  const [endorsementsValuables, setEndorsementsValuables] = useState([]);
  const [isAddDialog, setIsAddDialog] = useState(false);
  const [isDetailsDialog, setIsDetailsDialog] = useState(false);
  const [isAddTripExtensionDialog, setIsAddTripExtensionDialog] =
    useState(false);
  const [isDetailsAddTripExtensionDialog, setIsDetailsAddTripExtensionDialog] =
    useState(false);
  const [isRefundDialog, setIsRefundDialog] = useState();
  const [endorsementValuablesPrice, setEndorsementValuablesPrice] =
    useState(null);
  const [isFormValid, setIsFormValid] = useState(false);
  const [isConfirmCancellationDialog, setIsConfirmCancellationDialog] =
    useState();
  const [
    isConfirmTripDurationCancellationDialog,
    setIsConfirmTripDurationCancellationDialog,
  ] = useState();
  const {
    control,
    register,
    handleSubmit,
    formState,
    reset,
    setValue,
    getValues,
    watch,
    trigger,
    setError,
    getErrors,
    clearErrors,
  } = useForm();
  const { showNotification } = useContext(NotificationContext);
  const {
    endorsementRefund,
    fetchRefundData,
    setEndorsementRefund,
    refundDate,
  } = useFetchEndorsementRefund();

  const addButtonHandler = () => {
    if (isPolicyStarted()) {
      showNotification(
        t('POLICY_DETAILS.valuableTypeCantBeAddedAfterStartDate'),
        'error',
      );
    } else {
      setIsAddDialog(true);
    }
  };

  const extendPolicyRejectReason = useMemo(() => {
    return checkIfPolicyCanBeExtended(policyDetails, endorsements);
  }, [policyDetails, endorsements]);

  const extendTripButtonHandler = () => {
    if (extendPolicyRejectReason) {
      if (extendPolicyRejectReason === 'extendPolicyBeforeStartDate') {
        showNotification(
          t('POLICY_DETAILS.ENDORSEMENTS.extendPolicyBeforeStartDate'),
          'error',
        );
      } else if (extendPolicyRejectReason === 'extendPolicyAfterEndDate') {
        showNotification(
          t('POLICY_DETAILS.ENDORSEMENTS.extendPolicyAfterEndDate'),
          'error',
        );
      }
      return;
    }

    if (endorsementTripDurationDetails) {
      setIsDetailsAddTripExtensionDialog(true);
    } else {
      setIsAddTripExtensionDialog(true);
    }
  };

  const closeDialogHandler = () => {
    setIsAddDialog(false);
    setIsDetailsDialog(false);
    setIsAddTripExtensionDialog(false);
    setIsDetailsAddTripExtensionDialog(false);
    setEndorsementValuablesPrice(null);
    resetEndorsementPrice();
    reset();
  };

  useEffect(() => {
    const handleChange = () => {
      validateEndorsements();
      if (getValues('endorsementType') === 'VALUABLES') {
        calculateEndorsementPrice(policyDetails, getValues());
      }
    };
    handleChange();
  }, [watch]);

  useEffect(() => {
    if (endorsements) {
      const endorsementsTripDurationValue = endorsements.filter(
        (endorsement) => endorsement.endorsementType === 'TRIP DURATION',
      );
      setEndorsementsTripDuration(
        endorsementsTripDurationValue.length > 0
          ? endorsementsTripDurationValue[0]
          : null,
      );
      if (endorsementsTripDurationValue.length > 0) {
        openDetailsHandler(
          endorsementsTripDurationValue[0].endorsementNumber,
          true,
        );
      }
      const endorsementsValuablesValue = endorsements.filter(
        (endorsement) => endorsement.endorsementType === 'VALUABLES',
      );
      setEndorsementsValuables(endorsementsValuablesValue);
    }
  }, [endorsements]);

  useEffect(() => {
    if (endorsementTripDurationDetails) {
      setValue('extensionStartDate', endorsementTripDurationDetails.startDate);
      setValue('extensionEndDate', endorsementTripDurationDetails.endDate);
    }
  }, [endorsementTripDurationDetails]);

  const debouncedItemValue = useDebouncedValue(getValues('itemValue'), 500);

  useEffect(() => {
    if (debouncedItemValue && debouncedItemValue > 0) {
      setEndorsementValuablesPrice((debouncedItemValue * 7) / 100);
      validateEndorsements();
    } else {
      setEndorsementValuablesPrice(null);
    }
  }, [debouncedItemValue]);

  const handleChangeValuableValue = (value) => {
    const itemValue = value.replace(/[^0-9.]/g, '').slice(0, 14);
    const maxValue = 4000;
    if (+itemValue <= maxValue) {
      setValue('itemValue', itemValue);
    } else {
      setValue('itemValue', maxValue);
    }
  };

  const validateEndorsements = () => {
    setIsFormValid(true);
    trigger();
    if (getValues('endorsementType') === 'TRIP_DURATION') {
      clearErrors();
      const { travelEndDate } = getValues();
      if (!travelEndDate) {
        setError('travelEndDate', { type: 'required' });
        setIsFormValid(false);
      }
      if (!travelEndDate) {
        setIsFormValid(false);
        return;
      }
    }
  };

  const payHandler = async (transactionId) => {
    if (Object.keys(formState.errors).length === 0) {
      const endorsementType = isAddTripExtensionDialog
        ? 'TRIP_DURATION'
        : 'VALUABLES';
      await addEndorsement(
        policyDetails,
        getValues(),
        endorsementPrice,
        transactionId,
        endorsementType,
      );
      closeDialogHandler();
      fetchData();
    }
  };

  const beneficiarySelectOptions = policyDetails.beneficiaries.map((b) => ({
    value: b.index,
    label: `${b.firstName} ${b.lastName}`,
  }));

  const openDetailsHandler = async (id, onlyTripDuration = false) => {
    await fetchEndorsementDetails(
      id,
      policyDetails.product.iac,
      onlyTripDuration,
    );
    if (!onlyTripDuration) {
      setIsDetailsDialog(true);
    }
  };

  const endDateHandler = (value) => {
    clearErrors('travelEndDate');
    setValue('travelEndDate', value);
    validateEndorsements('getValues()', getValues());
    calculateEndorsementPrice(policyDetails, getValues());
  };

  const cancelTripExtensionEndorsementHandler = async () => {
    const today = new Date();
    const endorsementEndDate = endorsementsTripDuration.endDate;
    if (today > new Date(endorsementEndDate)) {
      showNotification(
        t('POLICY_DETAILS.ENDORSEMENTS.endorsementCantBeCancelledAfterEndDate'),
        'error',
      );
      return;
    }
    await cancelEndorsement(
      policyDetails,
      endorsementTripDurationDetails.endorsementNumber,
      endorsementRefund,
    );
    fetchData();
    setIsDetailsDialog(false);
    setIsRefundDialog(false);
    setIsConfirmTripDurationCancellationDialog(false);
  };

  const cancelEndorsementHandler = async () => {
    await cancelEndorsement(
      policyDetails,
      endorsementDetails.endorsementNumber,
      endorsementRefund,
    );
    setEndorsementRefund(undefined);
    fetchData();
    setIsDetailsDialog(false);
    setIsRefundDialog(false);
    setIsConfirmCancellationDialog(false);
  };

  const refundHandler = async () => {
    if (
      endorsementDetails.endorsementType === 'VALUABLES' &&
      dayjs(endorsementDetails.startDate).isBefore(dayjs())
    ) {
      showNotification(
        t('POLICY_DETAILS.cancellationOfValuablesEndorsement'),
        'error',
      );
      return;
    }
    setIsRefundDialog(true);
    fetchRefundData(
      policyDetails,
      dayjs().toDate(),
      endorsementDetails.endorsementNumber,
    );
  };

  const refundRequestDateHandler = async (value) => {
    if (
      endorsementsTripDuration.endorsementType === 'TRIP DURATION' &&
      dayjs().isAfter(dayjs(policyDetails.insuranceData.endDate))
    ) {
      showNotification(
        t('POLICY_DETAILS.cancellationOfTripDurationEndorsement'),
        'error',
      );
      return;
    }
    const refundData = await fetchRefundData(
      policyDetails,
      value,
      endorsementsTripDuration.endorsementNumber,
    );
    if (refundData) {
      setEndorsementRefund(refundData.refundAmount);
      setIsConfirmTripDurationCancellationDialog(true);
    }
  };

  const isTopProduct = () => {
    const productCode = policyDetails?.product?.productCode?.toLowerCase();
    return (
      productCode &&
      (productCode.includes('gld') || productCode.includes('top'))
    );
  };

  const isPolicyStarted = () => {
    return !dayjs(policyDetails.insuranceData?.startDate).isAfter(
      dayjs(),
      'date',
    );
  };

  const calculateDaysBetweenDates = (startDate, endDate) => {
    if (!startDate || !endDate) return '-';
    return differenceInDays(new Date(endDate), new Date(startDate));
  };

  // @TODO: remove this function, only for testing purposes
  const handleEndroesmentWithoutPayment = async () => {
    payHandler('1234567');
  };

  return (
    <div className="endorsementsTab">
      <div className="trip-duration-section">
        <div className="header trip-extension">
          <h4>{t('POLICY_DETAILS.ENDORSEMENTS.tripExtension.title')}</h4>
        </div>

        <div className="initital-dates">
          <div className="label">
            <span>
              {t('POLICY_DETAILS.ENDORSEMENTS.tripExtension.initialDates')}
            </span>
            <span>
              (
              {calculateDaysBetweenDates(
                policyDetails?.insuranceData?.startDate,
                policyDetails?.insuranceData?.endDate,
              )}{' '}
              {t('COMMON.days')})
            </span>
          </div>
          <div className="dates-picker-container initial-dates">
            <span>
              <span className="start-date">
                {t('POLICY_DETAILS.ENDORSEMENTS.tripExtension.startDate')}:
              </span>
              <span>
                {format(
                  new Date(policyDetails?.insuranceData?.startDate),
                  CONFIG_APP.DATE_FORMAT,
                )}
              </span>
              <span className="end-date">
                {t('POLICY_DETAILS.ENDORSEMENTS.tripExtension.endDate')}:
              </span>
              <span>
                {format(
                  new Date(policyDetails?.insuranceData?.endDate),
                  CONFIG_APP.DATE_FORMAT,
                )}
              </span>
            </span>
            {!endorsementTripDurationDetails && userRoles.postSales && (
              <SoftButton
                size="small"
                sx={{ marginLeft: '1rem' }}
                onClick={extendTripButtonHandler}
                color={!!extendPolicyRejectReason ? 'secondary' : 'info'}
                variant="gradient"
                className="button"
                disabled={!!extendPolicyRejectReason}
              >
                {t('POLICY_DETAILS.ENDORSEMENTS.tripExtension.extend')}
              </SoftButton>
            )}
          </div>
        </div>

        {endorsementTripDurationDetails && (
          <div
            className={
              'extended-dates' +
              (endorsementTripDurationDetails.statusId === 13
                ? ' extended-dates-inactive'
                : '')
            }
          >
            <div className="label">
              <span>
                {t(
                  'POLICY_DETAILS.ENDORSEMENTS.tripExtension.extendedTripDates',
                )}
              </span>
              <span>
                (
                {calculateDaysBetweenDates(
                  endorsementTripDurationDetails?.startDate,
                  endorsementTripDurationDetails?.endDate,
                )}{' '}
                {t('COMMON.days')})
              </span>
            </div>
            <div className="dates-picker-container">
              <span>
                <span className="start-date">
                  {t('POLICY_DETAILS.ENDORSEMENTS.tripExtension.startDate')}:{' '}
                </span>
                <span>
                  {endorsementTripDurationDetails &&
                  endorsementTripDurationDetails.startDate
                    ? format(
                        new Date(endorsementTripDurationDetails?.startDate),
                        CONFIG_APP.DATE_FORMAT,
                      )
                    : ''}
                </span>
                <span className="end-date">
                  {t('POLICY_DETAILS.ENDORSEMENTS.tripExtension.endDate')}:
                </span>
                <span>
                  {' '}
                  {endorsementTripDurationDetails &&
                  endorsementTripDurationDetails.endDate
                    ? format(
                        new Date(endorsementTripDurationDetails?.endDate),
                        CONFIG_APP.DATE_FORMAT,
                      )
                    : ''}
                </span>
              </span>
              <SoftButton
                disabled={endorsementTripDurationDetails.statusId === 13}
                size="small"
                sx={{ marginLeft: '1rem' }}
                onClick={() => refundRequestDateHandler(new Date())}
                color="error"
                variant="gradient"
                className="button"
              >
                {endorsementTripDurationDetails.statusId === 13
                  ? t('COMMON.cancelled')
                  : t('COMMON.cancel')}
              </SoftButton>
            </div>
          </div>
        )}
      </div>

      <Divider mt={2} mb={2} />

      <div className="valuables-section">
        <div className="header">
          <h4>{t('POLICY_DETAILS.ENDORSEMENTS.valuables')}</h4>
          {isTopProduct() && userRoles.postSales && (
            <SuiButton
              size="small"
              variant="gradient"
              color={!!extendPolicyRejectReason ? 'secondary' : 'info'}
              className="button button-with-icon"
              onClick={addButtonHandler}
              disabled={!!extendPolicyRejectReason}
            >
              {t('POLICY_DETAILS.ENDORSEMENTS.addValuables')}
            </SuiButton>
          )}
        </div>
        {isTopProduct() ? (
          <EndorsementsTable
            data={endorsementsValuables}
            openDetailsHandler={openDetailsHandler}
          />
        ) : (
          <SoftTypography
            sx={{
              mb: 1,
              fontSize: '1rem',
              color: 'red',
              marginTop: '1rem',
              textAlign: 'center',
            }}
          >
            {t('POLICY_DETAILS.valuableTypeNotSupported')}
          </SoftTypography>
        )}
      </div>

      <Dialog
        fullWidth
        open={isAddDialog || isDetailsDialog}
        onClose={closeDialogHandler}
      >
        {(isAddDialog || isDetailsDialog) && (
          <>
            <DialogTitle>
              <SoftTypography>
                {isDetailsDialog ? (
                  <>
                    <SubjectIcon sx={{ paddingTop: '0.3rem' }} />
                    {t('POLICY_DETAILS.ENDORSEMENTS.valuables')}
                  </>
                ) : (
                  <>
                    <AddIcon sx={{ paddingTop: '0.3rem' }} />
                    {t('POLICY_DETAILS.ENDORSEMENTS.addValuables')}
                  </>
                )}
              </SoftTypography>
            </DialogTitle>
            <DialogContent
              dividers
              sx={{ paddingTop: 1, minHeight: '14rem', overflowY: 'auto' }}
            >
              {isDetailsDialog && (
                <SoftTypography
                  textTransform="capitalize"
                  sx={{ fontSize: '1rem', mt: 2, mb: 2 }}
                >
                  <SoftBox sx={{ fontWeight: 'bold', display: 'inline' }}>
                    {t('POLICY_DETAILS.ENDORSEMENTS.status')}:
                  </SoftBox>{' '}
                  {endorsementDetails.statusId === 8 && (
                    <SoftBox
                      sx={{
                        display: 'inline',
                        color: 'green',
                        textTransform: 'uppercase',
                      }}
                    >
                      {t('POLICY_DETAILS.ENDORSEMENTS.validated')}
                    </SoftBox>
                  )}
                  {endorsementDetails.statusId === 13 && (
                    <SoftBox
                      sx={{
                        display: 'inline',
                        color: 'red',
                        textTransform: 'uppercase',
                      }}
                    >
                      {t('POLICY_DETAILS.ENDORSEMENTS.cancelled')}
                    </SoftBox>
                  )}
                </SoftTypography>
              )}
              <SoftBox mb={2}>
                <SoftTypography
                  component="label"
                  variant="caption"
                  fontWeight="bold"
                  textTransform="capitalize"
                >
                  {t('POLICY_DETAILS.ENDORSEMENTS.beneficiary')}*
                </SoftTypography>
                <Controller
                  name="beneficiary"
                  control={control}
                  required
                  rules={{ required: 'This field is required' }}
                  render={({ field, value }) => (
                    <SoftSelect
                      {...field}
                      error={!!formState.errors.beneficiary}
                      placeholder={t('POLICY_DETAILS.ENDORSEMENTS.beneficiary')}
                      value={value}
                      defaultValue={
                        isDetailsDialog &&
                        endorsementDetails?.beneficiaries?.length &&
                        beneficiarySelectOptions.find(
                          (o) =>
                            o.value ===
                            endorsementDetails?.beneficiaries[0].index,
                        )
                      }
                      isDisabled={isDetailsDialog}
                      options={beneficiarySelectOptions}
                      onChange={(v) => {
                        setValue('beneficiary', v.value);
                      }}
                    />
                  )}
                  onChange={([selected]) => {
                    trigger();
                  }}
                />
              </SoftBox>
              <SoftBox mb={2}>
                <SoftTypography
                  component="label"
                  variant="caption"
                  fontWeight="bold"
                  textTransform="capitalize"
                >
                  {t('POLICY_DETAILS.ENDORSEMENTS.itemValue')}*
                </SoftTypography>
                <SoftInput
                  placeholder={t('POLICY_DETAILS.ENDORSEMENTS.itemValue')}
                  error={!!formState.errors.itemValue}
                  inputProps={{ disabled: isDetailsDialog }}
                  defaultValue={
                    isDetailsDialog ? endorsementDetails?.itemValue : ''
                  }
                  {...register('itemValue', {
                    required: true,
                    onChange: (v) => handleChangeValuableValue(v.target.value),
                  })}
                />
              </SoftBox>
              <SoftBox mb={2}>
                <SoftTypography
                  component="label"
                  variant="caption"
                  fontWeight="bold"
                  textTransform="capitalize"
                >
                  {t('POLICY_DETAILS.ENDORSEMENTS.itemCategory')}
                </SoftTypography>
                <SoftInput
                  type="text"
                  placeholder={t('POLICY_DETAILS.ENDORSEMENTS.itemCategory')}
                  defaultValue={
                    isDetailsDialog ? endorsementDetails?.itemCategory : ''
                  }
                  inputProps={{ disabled: isDetailsDialog, maxLength: 255 }}
                  error={!!formState.errors.itemCategory}
                  {...register('itemCategory')}
                />
              </SoftBox>
              <SoftBox mb={2}>
                <SoftTypography
                  component="label"
                  variant="caption"
                  fontWeight="bold"
                  textTransform="capitalize"
                >
                  {t('POLICY_DETAILS.ENDORSEMENTS.itemDescription')}
                </SoftTypography>
                <SoftInput
                  type="text"
                  placeholder={t('POLICY_DETAILS.ENDORSEMENTS.itemDescription')}
                  defaultValue={
                    isDetailsDialog ? endorsementDetails?.itemDescription : ''
                  }
                  inputProps={{ disabled: isDetailsDialog, maxLength: 255 }}
                  error={!!formState.errors.itemDescription}
                  {...register('itemDescription')}
                />
              </SoftBox>
              <SoftBox mb={2}>
                <SoftTypography
                  component="label"
                  variant="caption"
                  fontWeight="bold"
                  textTransform="capitalize"
                >
                  {t('POLICY_DETAILS.ENDORSEMENTS.itemAge')}*
                </SoftTypography>
                <SoftInput
                  placeholder={t('POLICY_DETAILS.ENDORSEMENTS.itemAge')}
                  error={!!formState.errors.itemAge}
                  inputProps={{ disabled: isDetailsDialog, max: '199' }}
                  type="number"
                  maxlength="3"
                  max="199"
                  defaultValue={
                    isDetailsDialog ? endorsementDetails?.itemAge : ''
                  }
                  {...register('itemAge', {
                    required: true,
                    onChange: (v) => {
                      const value = v.target.value
                        .replace(/\D/g, '')
                        .slice(0, 14);
                      setValue('itemAge', value);
                      validateEndorsements();
                    },
                  })}
                />
              </SoftBox>
              <SoftBox mb={2}>
                <SoftTypography
                  component="label"
                  variant="caption"
                  fontWeight="bold"
                  textTransform="capitalize"
                >
                  {t('POLICY_DETAILS.ENDORSEMENTS.itemMake')}
                </SoftTypography>
                <SoftInput
                  type="text"
                  placeholder={t('POLICY_DETAILS.ENDORSEMENTS.itemMake')}
                  defaultValue={
                    isDetailsDialog ? endorsementDetails?.itemMake : ''
                  }
                  inputProps={{ disabled: isDetailsDialog, maxLength: 255 }}
                  error={!!formState.errors.itemMake}
                  {...register('itemMake')}
                />
              </SoftBox>
              <SoftBox mb={2}>
                <SoftTypography
                  component="label"
                  variant="caption"
                  fontWeight="bold"
                  textTransform="capitalize"
                >
                  {t('POLICY_DETAILS.ENDORSEMENTS.serialNumber')}
                </SoftTypography>
                <SoftInput
                  type="text"
                  placeholder={t('POLICY_DETAILS.ENDORSEMENTS.serialNumber')}
                  defaultValue={
                    isDetailsDialog ? endorsementDetails?.itemSerialNumber : ''
                  }
                  inputProps={{ disabled: isDetailsDialog, maxLength: 255 }}
                  error={!!formState.errors.itemSerialNumber}
                  {...register('itemSerialNumber')}
                />
              </SoftBox>
              {isDetailsDialog && (
                <EndorsementPayment
                  policyDetails={policyDetails}
                  endorsementDetails={endorsementDetails}
                />
              )}
              {watch('itemValue') && !isDetailsDialog && (
                <SoftTypography
                  textTransform="capitalize"
                  sx={{ fontSize: '1rem' }}
                >
                  <SoftBox sx={{ fontWeight: 'bold', display: 'inline' }}>
                    {t('POLICY_DETAILS.ENDORSEMENTS.priceGross')}:
                  </SoftBox>{' '}
                  {endorsementValuablesPrice && (
                    <CurrencyComponent
                      value={endorsementValuablesPrice}
                      countryCode={policyDetails.policy.price.currency}
                    ></CurrencyComponent>
                  )}
                </SoftTypography>
              )}
            </DialogContent>
            <DialogActions>
              {isDetailsDialog ? (
                <>
                  {endorsementDetails.statusId !== 13 && (
                    <SoftButton
                      size="small"
                      color="error"
                      onClick={refundHandler}
                      sx={{ marginRight: 'auto' }}
                    >
                      <BlockIcon
                        sx={{ marginRight: '0.3rem', marginTop: '-2px' }}
                      />
                      {t('POLICY_DETAILS.ENDORSEMENTS.cancelEndorsement')}
                    </SoftButton>
                  )}
                  <SoftButton size="small" onClick={closeDialogHandler}>
                    <CloseIcon sx={{ marginRight: '0.2rem' }} />
                    {t('COMMON.close')}
                  </SoftButton>
                </>
              ) : (
                <>
                  <SoftButton size="small" onClick={closeDialogHandler}>
                    <CloseIcon sx={{ marginRight: '0.2rem' }} />
                    {t('COMMON.close')}
                  </SoftButton>
                  {localStorage.getItem('debugModeEA') === 'true' &&
                    formState.isValid && (
                      <SoftButton
                        size="small"
                        onClick={handleEndroesmentWithoutPayment}
                        color="info"
                        variant="gradient"
                      >
                        Create without payment
                      </SoftButton>
                    )}
                  {formState.isValid && (
                    <PciPal
                      policyDetails={policyDetails}
                      endorsementPrice={
                        +endorsementPrice || endorsementValuablesPrice
                      }
                      isFormValid={isFormValid}
                      registerEndorsement={payHandler}
                    ></PciPal>
                  )}
                </>
              )}
            </DialogActions>
          </>
        )}
      </Dialog>
      <Dialog
        fullWidth
        open={isAddTripExtensionDialog || isDetailsAddTripExtensionDialog}
        onClose={closeDialogHandler}
      >
        {(isAddTripExtensionDialog || isDetailsAddTripExtensionDialog) && (
          <>
            <DialogTitle>
              <SoftTypography>
                {isDetailsDialog ? (
                  <>
                    <SubjectIcon sx={{ paddingTop: '0.3rem' }} />
                    {t('POLICY_DETAILS.ENDORSEMENTS.tripExtension')}
                  </>
                ) : (
                  <>
                    <AddIcon sx={{ paddingTop: '0.3rem' }} />
                    {t('POLICY_DETAILS.ENDORSEMENTS.addTripExtension')}
                  </>
                )}
              </SoftTypography>
            </DialogTitle>
            <DialogContent
              dividers
              sx={{ paddingTop: 1, minHeight: '14rem', overflowY: 'auto' }}
            >
              {isDetailsAddTripExtensionDialog && (
                <SoftTypography
                  textTransform="capitalize"
                  sx={{ fontSize: '1rem', mt: 2, mb: 2 }}
                >
                  <SoftBox sx={{ fontWeight: 'bold', display: 'inline' }}>
                    {t('POLICY_DETAILS.ENDORSEMENTS.status')}:
                  </SoftBox>{' '}
                  {endorsementsTripDuration.statusId === 8 && (
                    <SoftBox
                      sx={{
                        display: 'inline',
                        color: 'green',
                        textTransform: 'uppercase',
                      }}
                    >
                      {t('POLICY_DETAILS.ENDORSEMENTS.validated')}
                    </SoftBox>
                  )}
                  {endorsementsTripDuration.statusId === 13 && (
                    <SoftBox
                      sx={{
                        display: 'inline',
                        color: 'red',
                        textTransform: 'uppercase',
                      }}
                    >
                      {t('POLICY_DETAILS.ENDORSEMENTS.cancelled')}
                    </SoftBox>
                  )}
                </SoftTypography>
              )}

              <>
                <SoftTypography
                  textTransform="capitalize"
                  sx={{ mb: 1, mt: 3, fontSize: '1rem' }}
                >
                  <SoftBox sx={{ fontWeight: 'bold', display: 'inline' }}>
                    {t('POLICY_DETAILS.ENDORSEMENTS.originalDates')}:
                  </SoftBox>{' '}
                  {format(
                    new Date(policyDetails.insuranceData.startDate),
                    CONFIG_APP.DATE_FORMAT,
                  )}{' '}
                  -{' '}
                  {format(
                    new Date(policyDetails.insuranceData.endDate),
                    CONFIG_APP.DATE_FORMAT,
                  )}{' '}
                  (
                  {calculateTripDuration(
                    policyDetails.insuranceData.startDate,
                    policyDetails.insuranceData.endDate,
                  )}{' '}
                  {t('POLICY_DETAILS.ENDORSEMENTS.days')})
                </SoftTypography>
                <SoftBox mb={2}>
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    textTransform="capitalize"
                  >
                    {t('POLICY_DETAILS.ENDORSEMENTS.startDate')}
                  </SoftTypography>
                  {isDetailsAddTripExtensionDialog ? (
                    <SoftBox mb={2}>
                      <SoftInput
                        type="text"
                        placeholder={t('POLICY_DETAILS.ENDORSEMENTS.startDate')}
                        defaultValue={format(
                          new Date(policyDetails?.insuranceData?.startDate),
                          CONFIG_APP.DATE_FORMAT,
                        )}
                        inputProps={{ disabled: true }}
                        {...register('travelStartDate', { required: true })}
                      />
                    </SoftBox>
                  ) : (
                    <SoftDatePicker
                      input={{
                        required: true,
                        disabled: true,
                        placeholder: t(`POLICY_DETAILS.ENDORSEMENTS.startDate`),
                        error: formState.errors.travelStartDate,
                      }}
                      defaultValue={format(
                        new Date(policyDetails?.insuranceData?.startDate),
                        CONFIG_APP.DATE_FORMAT,
                      )}
                      options={{
                        required: true,
                        dateFormat: CONFIG_APP.DATE_FORMAT_DATEPICKER,
                      }}
                    />
                  )}
                </SoftBox>
                <SoftBox mb={2}>
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    textTransform="capitalize"
                  >
                    {t('POLICY_DETAILS.ENDORSEMENTS.endDate')}
                  </SoftTypography>
                  {isDetailsAddTripExtensionDialog ? (
                    <SoftBox mb={2}>
                      <SoftInput
                        type="text"
                        placeholder={t('POLICY_DETAILS.ENDORSEMENTS.endDate')}
                        defaultValue={
                          isDetailsAddTripExtensionDialog
                            ? format(
                                new Date(
                                  endorsementTripDurationDetails?.endDate,
                                ),
                                CONFIG_APP.DATE_FORMAT,
                              )
                            : null
                        }
                        inputProps={{ disabled: isDetailsDialog }}
                        {...register('endDate', { required: true })}
                      />
                    </SoftBox>
                  ) : (
                    <SoftDatePicker
                      input={{
                        required: true,
                        placeholder: t(`POLICY_DETAILS.ENDORSEMENTS.endDate`),
                        error: formState.errors.travelEndDate,
                      }}
                      options={{
                        minDate: add(
                          new Date(policyDetails?.insuranceData?.endDate),
                          { days: 1 },
                        ),
                        maxDate: add(
                          new Date(policyDetails.insuranceData.startDate),
                          { years: 1 },
                        ),
                        dateFormat: CONFIG_APP.DATE_FORMAT_DATEPICKER,
                        parseDate: function (dateStr, format) {
                          return dayjs(dateStr, { format }).toDate();
                        },
                        required: true,
                        formatDate: function (date, format) {
                          return dayjs(date).format(format);
                        },
                      }}
                      onChange={(event) => {
                        endDateHandler(event[0]);
                      }}
                    />
                  )}
                </SoftBox>

                {watch('travelEndDate') && !formState.errors.travelEndDate && (
                  <SoftTypography
                    textTransform="capitalize"
                    sx={{ mb: 1, mt: 3, fontSize: '1rem' }}
                  >
                    <SoftBox sx={{ fontWeight: 'bold', display: 'inline' }}>
                      {t('POLICY_DETAILS.ENDORSEMENTS.updatedDuration')}:
                    </SoftBox>{' '}
                    {dayjs(getValues('travelEndDate')).diff(
                      dayjs(new Date(policyDetails.insuranceData.startDate)),
                      'days',
                    ) + 1}{' '}
                    {t('POLICY_DETAILS.ENDORSEMENTS.days')}
                  </SoftTypography>
                )}

                {isDetailsDialog && (
                  <EndorsementPayment
                    policyDetails={policyDetails}
                    endorsementDetails={endorsementDetails}
                  />
                )}
                {!isDetailsDialog &&
                  endorsementPrice &&
                  watch('travelEndDate') && (
                    <SoftTypography
                      textTransform="capitalize"
                      sx={{ fontSize: '1rem' }}
                    >
                      <SoftBox sx={{ fontWeight: 'bold', display: 'inline' }}>
                        {t('POLICY_DETAILS.ENDORSEMENTS.priceGross')}:
                      </SoftBox>{' '}
                      <CurrencyComponent
                        value={endorsementPrice}
                        countryCode={policyDetails.policy.price.currency}
                      ></CurrencyComponent>
                    </SoftTypography>
                  )}
              </>

              <SoftTypography color="error" sx={{ fontSize: '14px' }}>
                {t('POLICY_DETAILS.ENDORSEMENTS.yearOffsetCalculationWarning')}
              </SoftTypography>
            </DialogContent>
            <DialogActions>
              {isDetailsDialog ? (
                <>
                  {endorsementDetails.statusId !== 13 && (
                    <SoftButton
                      size="small"
                      color="error"
                      onClick={refundHandler}
                      sx={{ marginRight: 'auto' }}
                    >
                      <BlockIcon
                        sx={{ marginRight: '0.3rem', marginTop: '-2px' }}
                      />
                      {t('POLICY_DETAILS.ENDORSEMENTS.cancelEndorsement')}
                    </SoftButton>
                  )}
                  <SoftButton size="small" onClick={closeDialogHandler}>
                    <CloseIcon sx={{ marginRight: '0.2rem' }} />
                    {t('COMMON.close')}
                  </SoftButton>
                </>
              ) : (
                <>
                  <SoftButton size="small" onClick={closeDialogHandler}>
                    <CloseIcon sx={{ marginRight: '0.2rem' }} />
                    {t('COMMON.close')}
                  </SoftButton>
                  {localStorage.getItem('debugModeEA') === 'true' && (
                    <SoftButton
                      size="small"
                      onClick={handleEndroesmentWithoutPayment}
                      color="info"
                      variant="gradient"
                    >
                      Create without payment
                    </SoftButton>
                  )}
                  <PciPal
                    policyDetails={policyDetails}
                    endorsementPrice={
                      +endorsementPrice || endorsementValuablesPrice
                    }
                    isFormValid={isFormValid}
                    registerEndorsement={payHandler}
                  ></PciPal>
                </>
              )}
            </DialogActions>
          </>
        )}
      </Dialog>
      <Dialog
        open={isRefundDialog}
        onClose={() => {
          setIsRefundDialog(false);
          setPolicyRefund(undefined);
        }}
        fullWidth
      >
        <DialogTitle>
          <SoftTypography>
            <PaymentIcon sx={{ paddingTop: '0.3rem' }} />{' '}
            {t('POLICY_DETAILS.REFUND.title')}
          </SoftTypography>
        </DialogTitle>
        <DialogContent>
          <SoftBox mb={2} mt={2}>
            <FormControl fullWidth>
              <SoftTypography
                component="label"
                variant="caption"
                fontWeight="bold"
                textTransform="capitalize"
                mb={0.5}
              >
                {t('POLICY_DETAILS.REFUND.requestDate')}
              </SoftTypography>
              <SoftDatePicker
                input={{
                  required: true,
                  placeholder: t(`POLICY_DETAILS.REFUND.requestDate`),
                }}
                options={{
                  required: true,
                  minDate: dayjs(
                    endorsementDetails?.subscriptionDate,
                  ).toISOString(),
                  maxDate: 'today',
                  defaultDate: 'today',
                  dateFormat: CONFIG_APP.DATE_FORMAT_DATEPICKER,
                  parseDate: function (dateStr, format) {
                    return dayjs(dateStr, { format }).toDate();
                  },
                  formatDate: function (date, format) {
                    return dayjs(date).format(format);
                  },
                }}
                onChange={(event) => {
                  refundRequestDateHandler(event[0]);
                }}
              />
            </FormControl>
            {endorsementRefund && (
              <FormControl fullWidth>
                <SoftBox mb={2}>
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    textTransform="capitalize"
                  >
                    {t('POLICY_DETAILS.refundAmount')}
                  </SoftTypography>
                  <SoftInput
                    placeholder={t('POLICY_DETAILS.refundAmount')}
                    value={endorsementRefund}
                    defaultValue={endorsementRefund}
                    onChange={(v) => {
                      const value = v.target.value
                        .replace(/[^\d.]/g, '')
                        .replace(/(\..*)\./g, '$1')
                        .slice(0, 14);
                      setEndorsementRefund(value);
                    }}
                  />
                </SoftBox>
              </FormControl>
            )}
          </SoftBox>
        </DialogContent>
        <DialogActions>
          <SoftButton
            size="small"
            onClick={() => {
              setIsRefundDialog(false);
              setEndorsementRefund(undefined);
            }}
          >
            <CloseIcon sx={{ marginRight: '0.2rem' }} />
            {t('COMMON.close')}
          </SoftButton>
          <SoftButton
            size="small"
            type="submit"
            color="success"
            disabled={!endorsementRefund}
            onClick={() => setIsConfirmCancellationDialog(true)}
          >
            <CheckIcon sx={{ marginRight: '0.2rem' }} />
            {t('COMMON.confirm')}
          </SoftButton>
        </DialogActions>
      </Dialog>
      <DialogConfirmation
        open={isConfirmCancellationDialog}
        onClose={() => setIsConfirmCancellationDialog(false)}
        onAction={cancelEndorsementHandler}
        actionColor="error"
        actionLabel="confirm"
        path="POLICY_DETAILS.ENDORSEMENT_CANCELLATION"
      />
      <DialogConfirmation
        open={isConfirmTripDurationCancellationDialog}
        onClose={() => setIsConfirmTripDurationCancellationDialog(false)}
        onAction={cancelTripExtensionEndorsementHandler}
        actionColor="error"
        actionLabel="confirm"
        path="POLICY_DETAILS.ENDORSEMENT_REFUND_AND_CANCELLATION"
      />
    </div>
  );
};

EndorsementsTab.propTypes = {
  policyDetails: PropTypes.object,
  endorsements: PropTypes.array,
  fetchData: PropTypes.func,
};

export default EndorsementsTab;
