import React, { memo, useContext } from 'react';
import Paper from '@mui/material/Paper';
import SoftTypography from '../../../../components/SoftTypography';
import './welcomeLinksList.scss';
import theme from '../../../../assets/theme';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { WelcomePageConfigContext } from '../../../../shared/context/welcome-page-config-context';
import { Box, Skeleton } from '@mui/material';

const WelcomeLinksList = ({ quickLinks, title }) => {
  const navigate = useNavigate();
  const {
    palette: { grey },
    typography: { size },
  } = theme;

  const handleClickLink = (link) => {
    if (link.internalLink) navigate(link.internalLink);
    else if (link.externalLink) window.location.href = link.externalLink;
  };

  const { isConfigLoading } = useContext(WelcomePageConfigContext);

  const skeletonLoaderContainer = (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Skeleton width="100%" height="1.5625rem"></Skeleton>
        <Skeleton width="100%" height="1.5625rem"></Skeleton>
        <Skeleton width="100%" height="1.5625rem"></Skeleton>
        <Skeleton width="100%" height="1.5625rem"></Skeleton>
      </Box>
    </>
  );

  const listElement = (
    <>
      {quickLinks &&
        quickLinks.map((link) => (
          <li key={link.title} onClick={() => handleClickLink(link)}>
            <ArrowForwardIcon sx={{ fontSize: '0.625rem !important' }} />
            <SoftTypography
              className="quick-link-title"
              component="span"
              variant="body2"
              fontSize={size.md}
            >
              {link.title}
            </SoftTypography>
          </li>
        ))}
    </>
  );

  return (
    <Paper
      className="quick-links-container"
      variant="outlined"
      style={{
        minHeight: '100px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        marginBottom: '1rem',
        gap: '.4rem',
        padding: '1rem',
        borderRadius: '0.75rem',
        borderColor: grey[500],
      }}
    >
      <SoftTypography className="title" component="span" fontWeight="regular">
        {title}
      </SoftTypography>

      <ul className="quick-links">
        {isConfigLoading ? skeletonLoaderContainer : listElement}
      </ul>
    </Paper>
  );
};

WelcomeLinksList.propTypes = {
  quickLinks: PropTypes.any,
  title: PropTypes.string.isRequired,
  children: PropTypes.node,
};

export default memo(WelcomeLinksList);
