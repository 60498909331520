import { useContext } from 'react';
import { LoaderContext } from '../../shared/context/loaderContext';
import { NotificationContext } from '../../shared/context/notificationContext';
import { usePortalApi } from 'shared/const/api-backend';
import { reportError } from 'shared/services/raygunService';

const useFetchUserDetails = () => {
  const { setLoading } = useContext(LoaderContext);
  const { showNotification } = useContext(NotificationContext);
  const API = usePortalApi();

  const fetchUserDetails = async (userId) => {
    setLoading(true);

    try {
      const [rolesResponse, businessUnitsResponse, getUserResponse] =
        await Promise.all([
          API.get(`/users-permissions/roles`),
          API.get(`/business-units`),
          API.get(`/users/${userId}?populate=*`),
        ]);

      const rolesOptions = rolesResponse.data.roles
        .filter(
          (role) => role.name !== 'Public' && role.name !== 'Authenticated',
        )
        .map((r) => ({ value: r.id, label: r.name }));

      const businessUnitsOptions = businessUnitsResponse.data.data.map((u) => ({
        value: u.id,
        label: u.attributes.name,
      }));

      const userWithDetails = {
        ...getUserResponse.data,
        role: rolesOptions.find(
          (r) => r.value === getUserResponse.data.role.id,
        ),
        businessUnits: businessUnitsOptions.filter((o) =>
          getUserResponse.data.businessUnits.find((u) => u.id === o.value),
        ),
      };

      return {
        roles: rolesOptions,
        businessUnits: businessUnitsOptions,
        userDetails: userWithDetails,
      };
    } catch (error) {
      showNotification('COMMON.error.fetchingData', 'error');
      reportError(error);
    } finally {
      setLoading(false);
    }

    return null;
  };

  return { fetchUserDetails };
};

export default useFetchUserDetails;
