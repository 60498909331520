import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { AuthContext } from '../../AuthContext';
import { getLoginRouteByPlatform } from 'shared/helpers/loginPageRoute.helper';

const ProtectedRoute = (props) => {
  const { platform } = useContext(AuthContext);
  const navigate = useNavigate();
  let location = useLocation();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const checkUserToken = () => {
    const userToken = localStorage.getItem('token');
    if (!userToken || userToken === 'undefined') {
      setIsLoggedIn(false);
      const loginRoute = getLoginRouteByPlatform(platform);
      const encodedRedirectTo = encodeURIComponent(
        `${location.pathname}${location.search}`,
      );
      return navigate(`${loginRoute}?redirectTo=${encodedRedirectTo}`);
    }
    setIsLoggedIn(true);
  };
  useEffect(() => {
    checkUserToken();
  }, [isLoggedIn]);
  return isLoggedIn ? props.children : null;
};

ProtectedRoute.propTypes = {
  children: PropTypes.node,
};

export default ProtectedRoute;
