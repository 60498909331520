import React, { useContext, useMemo, useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PaymentIcon from '@mui/icons-material/Payment';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import styles from './policyDetails.module.scss';
import DashboardNavbar from '../../../examples/Navbars/DashboardNavbar';
import DashboardLayout from '../../../examples/LayoutContainers/DashboardLayout';
import SoftBox from 'components/SoftBox';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  Tab,
  Tabs,
} from '@mui/material';
import PolicyTab from './policyTab/policyTab';
import useFetchPolicyDetails from './useFetchPolicyDetails';
import PolicyHolderTab from './policyHolderTab/policyHolderTab';
import SoftButton from 'components/SoftButton';
import PaymentTab from './paymentTab/paymentTab';
import EndorsementsTab from './endorsementsTab/endorsementsTab';
import useCancelPolicy from '../policyCancellation/helpers/useCancelPolicy';
import useSendMedicalCertificate from './useSendMedicalCertificate';
import useFetchCertificate from './useFetchCertificate';
import TravellersTab from './travellersTab/travellersTab';
import DialogConfirmation from 'shared/dialogs/DialogConfirmation';
import SoftTypography from 'components/SoftTypography';
import TimelineList from 'examples/Timeline/TimelineList';
import TimelineItem from 'examples/Timeline/TimelineItem';
import useFetchAuditHistory from './useFetchAuditHistory';
import SoftInput from 'components/SoftInput';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import SoftDatePicker from 'components/SoftDatePicker';
import useFetchPolicyRefund from '../policyCancellation/helpers/useFetchPolicyRefund';
import { CONFIG_APP } from '../../../shared/const/app.config';
import { AppConfigurationContext } from '../../../shared/context/configurationContext';
import { usePortalApi } from '../../../shared/const/api-backend';
import useFetchEndorsements from './endorsementsTab/useFetchEndorsements';
import {
  checkIfFullRefundPossible,
  checkIfPartialRefundPossible,
} from './policyDetails.helper.js';
import useFetchEndorsementDetails from './endorsementsTab/useFetchEndorsementDetails';
import SoftSelect from '../../../components/SoftSelect';
import { reportError } from 'shared/services/raygunService';
import useFetchMedicalCertificate from './useFetchMedicalCertificate';
import useModifyPolicy from './useModifyPolicy';
import { UserDataContext } from '../../../shared/context/userDataContext';
import colors from '../../../assets/theme/base/colors';
import base64ToBlob from 'shared/utils/base64ToBlod';
import Breadcrumbs from 'examples/Breadcrumbs';

dayjs.extend(utc);

const PolicyDetails = () => {
  const { t } = useTranslation();
  // do not rely on this Policy ID. It could be undefined if policy was opened with Booking UUID
  // rely on policyDetails?.policy.policyNumber instead.
  const { policyId: paramsPolicyId } = useParams();
  const urlParams = new URLSearchParams(window.location.search);
  const bookingDataId = urlParams.get('bookingUuid');
  const API = usePortalApi();
  const [dialogRefundType, setDialogRefundType] = useState(null);

  const { policyDetails, fetchData } = useFetchPolicyDetails({
    policyId: paramsPolicyId,
    persistentQuoteId: null,
    bookingDataId,
  });
  const { policyRefund, fetchRefundData, setPolicyRefund, refundDate } =
    useFetchPolicyRefund();
  const { endorsements, fetchEndorsementsData } = useFetchEndorsements(
    policyDetails?.policy.policyNumber,
  );
  const { fetchEndorsementDetails } = useFetchEndorsementDetails();
  const { cancelPolicy } = useCancelPolicy();
  const { modifyPolicy } = useModifyPolicy();
  const sendMedicalCertificate = useSendMedicalCertificate();
  const { fetchCertificateData } = useFetchCertificate();
  const { fetchMedicalCertificateData } = useFetchMedicalCertificate();
  const { auditHistory, fetchAuditHistory } = useFetchAuditHistory();
  const { appConfiguration, updateConfiguration } = useContext(
    AppConfigurationContext,
  );
  const { userRoles, channel } = useContext(UserDataContext);
  const navigate = useNavigate();

  const [state, setState] = useState({
    activeTab: 0,
    dialogs: {
      showAuditHistoryPopup: false,
      showRefundPopup: false,
      showConfirmCancellation: false,
      showDownloadCertificate: false,
      showDownloadMedicalCertificate: false,
    },
  });
  const showModify = useMemo(
    () => policyDetails?.policy?.status?.label === 'validated',
    [policyDetails],
  );

  const getApplicationConfig = async () => {
    try {
      const configurationRes = await API.get(`/configuration`, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_PORTAL_API_KEY}`,
        },
      });
      updateConfiguration({ appConfiguration: configurationRes.data.data });
      return await configurationRes.data;
    } catch (error) {
      reportError(error);
    }
  };

  useEffect(() => {
    if (!appConfiguration) {
      getApplicationConfig();
    }
  }, [appConfiguration]);

  const shouldShowSendMedicalCertificate = useMemo(
    () =>
      policyDetails?.beneficiaries?.some(
        (beneficiary) => beneficiary.wasScreened,
      ),
    [policyDetails],
  );

  const handleTabChange = (event, newValue) => {
    setState((prevState) => ({ ...prevState, activeTab: newValue }));
  };

  const handleDialogToggle = (dialog) => {
    setState((prevState) => ({
      ...prevState,
      dialogs: { ...prevState.dialogs, [dialog]: !prevState.dialogs[dialog] },
    }));
  };

  const handleDialogRefundToggle = (show, refundType) => {
    setDialogRefundType(refundType);
    setState((prevState) => ({
      ...prevState,
      dialogs: { ...prevState.dialogs, showRefundPopup: show },
    }));
  };

  const handleConfirmPolicyCancellation = async () => {
    try {
      const result = await cancelPolicy(
        policyDetails,
        policyRefund,
        refundDate,
      );
      if (result) {
        const url = `/policies`;
        navigate(url);
      }
    } catch (error) {
      reportError(error);
    }
    handleDialogToggle('showConfirmCancellation');
    handleDialogToggle('showRefundPopup');
  };

  const handleDownloadMedicalCertificate = async () => {
    await fetchMedicalCertificateData(policyDetails?.bookingDataId)
      .then((medCertResponse) => {
        const base64Data = medCertResponse.file;
        const filename = medCertResponse.name;
        const blobData = base64ToBlob(base64Data);
        const blob = new Blob([blobData], { type: 'application/pdf' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      })
      .finally(() => handleDialogToggle('showDownloadMedicalCertificate'));
  };

  const handleDownloadCertificate = async () => {
    await fetchCertificateData(
      policyDetails?.policy.policyNumber,
      policyDetails?.product.iac,
    )
      .then((certificateData) => {
        const base64Data = certificateData.file;
        const filename = certificateData.name;
        const blobData = base64ToBlob(base64Data);
        const blob = new Blob([blobData], { type: 'application/pdf' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      })
      .finally(() => handleDialogToggle('showDownloadCertificate'));
  };

  const handlePolicyDetailsUpdate = () => {
    fetchData(policyDetails?.policy.policyNumber);
  };

  const openAuditHistory = async () => {
    await fetchAuditHistory(policyDetails.bookingDataId);
    handleDialogToggle('showAuditHistoryPopup');
  };

  const navigateToCancel = () => {
    const queryParams = new URLSearchParams(location.search);
    const persistentQuoteId = queryParams.get('persistentQuoteId');
    const searchParams = new URLSearchParams();

    if (persistentQuoteId)
      searchParams.set('persistentQuoteId', persistentQuoteId);

    navigate(
      `/policy-details/${
        policyDetails?.policy.policyNumber
      }/cancel?${searchParams.toString()}`,
    );
  };

  const refundHandler = () => {
    // if (policyDetails.policy.price.grossPremium < policyRefund) {
    //   showNotification(t('POLICY_DETAILS.cancellationOfPolicyRefundAmountError'), 'error');
    //   return;
    // }
    // if (policyDetails.policy.price.grossPremium > policyRefund &&
    //   (dayjs(refundDate).isBefore(dayjs(policyDetails.insuranceData.bookingDate).add(14, 'day')) ||
    //     dayjs(refundDate).isAfter(dayjs(policyDetails.insuranceData.endDate)))) {
    //   showNotification(t('POLICY_DETAILS.cancellationOfPolicyDateError'), 'error');
    //   return;
    // }
    // if (policyDetails.policy.price.grossPremium === policyRefund && (dayjs(refundDate).isBefore(dayjs(policyDetails.insuranceData.bookingDate)) || dayjs(refundDate).isAfter(dayjs(policyDetails.insuranceData.startDate).subtract(14, 'day')))) {
    //   showNotification(t('POLICY_DETAILS.cancellationOfPolicyFullRefundError'), 'error');
    //   return;
    // }
    handleDialogToggle('showConfirmCancellation');
  };

  const getEndorsementsTripDurationDetails = async (endorsement) => {
    return fetchEndorsementDetails(
      endorsement.endorsementNumber,
      policyDetails.product.iac,
      true,
    );
  };

  const handleRefundNewPolicyCreated = (value) => {
    fetchRefundData(policyDetails, refundDate, value);
  };

  const refundRequestDateHandler = (value) => {
    if (checkIfFullRefundPossible(policyDetails, endorsements)) {
      setDialogRefundType('FULL_REFUND');
      fetchRefundData(policyDetails, value);
    }
    if (checkIfPartialRefundPossible(policyDetails, endorsements)) {
      setDialogRefundType('PRO_RATA_REFUND');
      fetchRefundData(policyDetails, value);
    }
    console.error('ERROR REFUND REQUEST DATE HANDLER');
  };

  const modifyPolicyButtonHandler = async () => {
    if (!policyDetails) {
      return;
    }
    await modifyPolicy(policyDetails);
  };

  const fetchEndorsementsDataHandler = () => {
    fetchEndorsementsData();
    // fetchEndorsementsData(policyDetails.policy.policyNumber);
  };

  if (!policyDetails) {
    return null;
  }

  return (
    <DashboardLayout>
      <DashboardNavbar>
        <Breadcrumbs
          title={policyDetails?.policy.policyNumber}
          route={[
            { title: t('POLICIES.header'), link: '/policies' },
            {
              title: policyDetails?.policy.policyNumber,
              link: `/policy-details/${policyDetails?.policy.policyNumber}`,
            },
          ]}
        />
      </DashboardNavbar>
      <div className={styles.PolicyDetails} data-testid="PolicyDetails">
        <Tabs
          value={state.activeTab}
          onChange={handleTabChange}
          className={styles.tabs}
        >
          <Tab label={t('POLICY_DETAILS.tabPolicy')} className={styles.tab} />
          <Tab
            label={t('POLICY_DETAILS.tabPolicyHolder')}
            className={styles.tab}
          />
          <Tab
            label={t('POLICY_DETAILS.tabTravellers')}
            className={styles.tab}
          />
          <Tab label={t('POLICY_DETAILS.tabPayment')} className={styles.tab} />
          {channel && channel.hasEndorsements && (
            <Tab
              label={t('POLICY_DETAILS.tabEndorsements')}
              className={styles.tab}
            />
          )}
        </Tabs>

        <SoftBox bgColor="white" borderRadius="lg" shadow="lg" mb={2}>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            className={styles.policyInfo}
          >
            <Grid item xs={4} md={3}>
              <span>
                <span
                  style={{ marginRight: '0.5rem', color: colors.grey[600] }}
                >
                  {t('POLICY_DETAILS.policyNumber')}:
                </span>
                <b>{policyDetails.policy.policyNumber}</b>
                {policyDetails.legacyPolicyId && (
                  <span>
                    ({t('POLICY_DETAILS.legacyPolicyId')}:{' '}
                    {policyDetails.legacyPolicyId})
                  </span>
                )}
              </span>
            </Grid>
            <Grid item xs={4} md={3}>
              <span className={styles.policyNumber}>
                <span
                  style={{ marginRight: '0.5rem', color: colors.grey[600] }}
                >
                  {t('POLICY_DETAILS.bookingReference')}:
                </span>
                <b>{policyDetails.externalId || '-'}</b>
              </span>
            </Grid>
            <Grid item xs={4} md={6} paddingRight="2rem" textAlign="right">
              <span>
                <span
                  style={{ marginRight: '0.5rem', color: colors.grey[600] }}
                >
                  {t('POLICY_DETAILS.status')}:
                </span>
                <b>
                  <span
                    style={{
                      textTransform: 'uppercase',
                      color:
                        policyDetails.policy.status.label === 'cancelled'
                          ? 'red'
                          : policyDetails.policy.status.label === 'validated'
                          ? 'green'
                          : 'initial',
                    }}
                  >
                    {' '}
                    {policyDetails.policy.status.label}
                  </span>
                </b>
              </span>
            </Grid>
          </Grid>
        </SoftBox>

        <SoftBox bgColor="white" borderRadius="lg" shadow="lg" px={4.5} py={6}>
          {state.activeTab === 0 && <PolicyTab policyDetails={policyDetails} />}
          {state.activeTab === 1 && (
            <PolicyHolderTab policyDetails={policyDetails} />
          )}
          {state.activeTab === 2 && (
            <TravellersTab
              policyDetails={policyDetails}
              onPolicyDetailsUpdate={handlePolicyDetailsUpdate}
            />
          )}
          {state.activeTab === 3 && (
            <PaymentTab policyDetails={policyDetails} />
          )}
          {state.activeTab === 4 && (
            <EndorsementsTab
              policyDetails={policyDetails}
              endorsements={endorsements}
              fetchData={fetchEndorsementsDataHandler}
            />
          )}
        </SoftBox>

        <div className={styles.buttonsContainer}>
          {policyDetails.policy.status.label !== 'cancelled' && (
            <SoftButton
              variant="outlined"
              color="secondary"
              onClick={() => handleDialogToggle('showDownloadCertificate')}
            >
              {t('POLICY_DETAILS.downloadPolicyCertificate')}
            </SoftButton>
          )}
          {shouldShowSendMedicalCertificate &&
            policyDetails.policy.status.label !== 'cancelled' && (
              <SoftButton
                variant="outlined"
                color="secondary"
                onClick={() =>
                  handleDialogToggle('showDownloadMedicalCertificate')
                }
              >
                {t('POLICY_DETAILS.downloadMedicalCertificate')}
              </SoftButton>
            )}

          <div className={styles.additionalButtons}>
            <SoftButton
              variant="outlined"
              color="secondary"
              onClick={() => openAuditHistory()}
            >
              {t('POLICY_DETAILS.auditHistory')}
            </SoftButton>
          </div>
        </div>
        <div className={styles.actionButtonsContainer}>
          {policyDetails.policy.status.label !== 'cancelled' &&
            userRoles.postSales && (
              <SoftButton
                variant="contained"
                style={{ backgroundColor: '#0a76ec' }}
                onClick={navigateToCancel}
              >
                {t('POLICY_DETAILS.cancelPolicy')}
              </SoftButton>
            )}
          {showModify && userRoles.postSales && (
            <SoftButton
              variant="contained"
              style={{ backgroundColor: '#0a76ec' }}
              onClick={() => modifyPolicyButtonHandler()}
            >
              {t('POLICY_DETAILS.modifyPolicy')}
            </SoftButton>
          )}
        </div>
      </div>

      <Dialog
        sx={{
          '& .MuiPaper-root': {
            boxShadow: 'none',
            elevation: 0,
            maxWidth: '600px',
            backgroundColor: 'transparent',
          },
        }}
        fullWidth
        open={state.dialogs.showAuditHistoryPopup}
      >
        {state.dialogs.showAuditHistoryPopup && (
          <>
            <DialogTitle
              sx={{
                padding: '2.5rem 2rem 2rem 2rem',
                color: '#6c757d',
                fontWeight: '400',
                fontSize: '1.4625rem',
              }}
            >
              {t('POLICY_DETAILS.AUDIT_HISTORY.title')}
            </DialogTitle>
            <SoftBox>
              <DialogContent sx={{ paddingTop: 0, maxHeight: '60vh' }}>
                <TimelineList>
                  {auditHistory.map((h) => (
                    <TimelineItem
                      key={h.id}
                      color="light"
                      icon="circle"
                      title={t(`POLICY_DETAILS.AUDIT_HISTORY.${h.action}`)}
                      dateTime={dayjs(h.createdDate)
                        .local()
                        .format('MM/DD/YYYY HH:mm')}
                      subtitle={`${t(
                        `POLICY_DETAILS.AUDIT_HISTORY.${h.userType}`,
                      )}${h.username && ` (${h.username})`}`}
                      description={h.message}
                    />
                  ))}
                </TimelineList>
              </DialogContent>
              <DialogActions>
                <SoftButton
                  size="small"
                  variant="outlined"
                  color="secondary"
                  onClick={() => handleDialogToggle('showAuditHistoryPopup')}
                  sx={{
                    color: '#5f5f5f',
                    '&:hover': {
                      borderColor: '#0a76ec',
                    },
                  }}
                >
                  {t('COMMON.close')}
                </SoftButton>
              </DialogActions>
            </SoftBox>
          </>
        )}
      </Dialog>
      <Dialog
        open={state.dialogs.showRefundPopup}
        onClose={() => {
          handleDialogToggle('showRefundPopup');
          setPolicyRefund(undefined);
        }}
        fullWidth
      >
        <DialogTitle>
          <SoftTypography>
            <PaymentIcon sx={{ paddingTop: '0.3rem' }} />{' '}
            {t('POLICY_DETAILS.REFUND.title')}
          </SoftTypography>
        </DialogTitle>
        <DialogContent>
          <SoftBox mb={2} mt={2}>
            {dialogRefundType === 'PRO_RATA_REFUND' && (
              <FormControl>
                <SoftTypography
                  component="label"
                  variant="caption"
                  fontWeight="bold"
                  textTransform="capitalize"
                  mb={0.5}
                >
                  {t('POLICY_DETAILS.REFUND.newPolicyCreated')}
                </SoftTypography>
                <SoftSelect
                  input={{
                    placeholder: t(`POLICY_DETAILS.REFUND.newPolicyCreated`),
                  }}
                  defaultValue={{ value: true, label: t(`COMMON.no`) }}
                  options={[
                    { value: true, label: t(`COMMON.yes`) },
                    { value: false, label: t(`COMMON.no`) },
                  ]}
                  onChange={(event) => {
                    handleRefundNewPolicyCreated(event.value);
                  }}
                />
              </FormControl>
            )}
            <FormControl fullWidth sx={{ marginTop: '0.3rem' }}>
              <SoftTypography
                component="label"
                variant="caption"
                fontWeight="bold"
                textTransform="capitalize"
                mb={0.5}
              >
                {t('POLICY_DETAILS.REFUND.requestDate')}
              </SoftTypography>
              <SoftDatePicker
                input={{
                  required: true,
                  placeholder: t(`POLICY_DETAILS.REFUND.requestDate`),
                }}
                options={{
                  required: true,
                  maxDate: 'today',
                  minDate: policyDetails.insuranceData.bookingDate,
                  defaultDate: 'today',
                  dateFormat: CONFIG_APP.DATE_FORMAT_DATEPICKER,
                  parseDate: function (dateStr, format) {
                    return dayjs(dateStr, { format }).toDate();
                  },
                  formatDate: function (date, format) {
                    return dayjs(date).format(format);
                  },
                }}
                onChange={(event) => {
                  refundRequestDateHandler(event[0]);
                }}
              />
            </FormControl>
            {policyRefund !== null && policyRefund !== undefined && (
              <FormControl fullWidth>
                <SoftBox mb={2}>
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    textTransform="capitalize"
                  >
                    {t('POLICY_DETAILS.refundAmount')}
                  </SoftTypography>
                  <SoftInput
                    placeholder={t('POLICY_DETAILS.refundAmount')}
                    value={policyRefund}
                    defaultValue={policyRefund}
                    onChange={(v) => {
                      const value = v.target.value
                        .replace(/[^\d.]/g, '')
                        .replace(/(\..*)\./g, '$1')
                        .slice(0, 14);
                      setPolicyRefund(value);
                    }}
                    error={
                      +policyRefund > +policyDetails.policy.price.grossPremium
                    }
                  />
                  {+policyRefund > +policyDetails.policy.price.grossPremium && (
                    <SoftTypography
                      component="label"
                      variant="caption"
                      textTransform="capitalize"
                      sx={{
                        color: 'red',
                        position: 'absolute',
                        marginTop: '5px',
                      }}
                    >
                      {t(
                        'POLICY_DETAILS.cancellationOfPolicyRefundAmountError',
                      )}
                    </SoftTypography>
                  )}
                </SoftBox>
              </FormControl>
            )}
          </SoftBox>
        </DialogContent>
        <DialogActions>
          <SoftButton
            size="small"
            onClick={() => {
              handleDialogToggle('showRefundPopup');
              setPolicyRefund(undefined);
            }}
          >
            <CloseIcon sx={{ marginRight: '0.2rem' }} />
            {t('COMMON.cancel')}
          </SoftButton>
          <SoftButton
            size="small"
            type="submit"
            color="success"
            disabled={
              !policyRefund ||
              +policyRefund > +policyDetails.policy.price.grossPremium
            }
            onClick={refundHandler}
          >
            <CheckIcon sx={{ marginRight: '0.2rem' }} />
            {t('COMMON.confirm')}
          </SoftButton>
        </DialogActions>
      </Dialog>
      <DialogConfirmation
        open={state.dialogs.showConfirmCancellation}
        onClose={() => handleDialogToggle('showConfirmCancellation')}
        onAction={handleConfirmPolicyCancellation}
        actionColor="error"
        actionLabel="confirm"
        path="POLICY_DETAILS.POLICY_CANCELLATION"
      />
      <DialogConfirmation
        open={state.dialogs.showDownloadCertificate}
        onClose={() => handleDialogToggle('showDownloadCertificate')}
        onAction={handleDownloadCertificate}
        actionColor="success"
        actionLabel="confirm"
        path="POLICY_DETAILS.DOWNLOAD_POLICY"
      />
      <DialogConfirmation
        open={state.dialogs.showDownloadMedicalCertificate}
        onClose={() => handleDialogToggle('showDownloadMedicalCertificate')}
        onAction={handleDownloadMedicalCertificate}
        actionColor="success"
        actionLabel="confirm"
        path="POLICY_DETAILS.DOWNLOAD_MEDICAL_CERTIFICATE"
      />
    </DashboardLayout>
  );
};

export default PolicyDetails;
