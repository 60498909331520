import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { reportError } from 'shared/services/raygunService';
import { LoaderContext } from '../../../shared/context/loaderContext';
import { NotificationContext } from '../../../shared/context/notificationContext';
import { useCm360Api } from '../../../shared/const/api-backend';
import { UserDataContext } from '../../../shared/context/userDataContext';
import { getToken } from '../../../shared/const/token.helper';
import { getBrandNameForInpathPolicy } from '../../../shared/helpers/inpath.helper';

const useModifyPolicy = () => {
  const { selectedBusinessUnit } = useContext(UserDataContext);
  const { setLoading } = useContext(LoaderContext);
  const { showNotification } = useContext(NotificationContext);
  const API = useCm360Api();
  const navigate = useNavigate();

  const modifyPolicy = async (policy) => {
    const policyId = policy.policy.policyNumber;
    setLoading(true);
    let result;

    try {
      const apiUrl = `/booking/from-policy`;
      const body = {
        policyId,
      };
      const { data } = await API.post(apiUrl, body);
      showNotification('New version of policy created', 'success');
      result = true;
      const token = getToken();
      let selectedBrand = selectedBusinessUnit.brand;
      if (
        policy.policy?.genericData?.brand &&
        policy.policy?.genericData?.salesChannel === 'INPATH'
      ) {
        selectedBrand = getBrandNameForInpathPolicy(policy?.product?.iac);
      }
      const redirectUrl = `/quote?brand=${selectedBrand}&bookingDataId=${data.bookingData.id}&agent=true&token=${token}`;
      navigate(redirectUrl);
    } catch (error) {
      showNotification('Error creating new version of policy', 'error');
      reportError(error);
    } finally {
      setLoading(false);
      return result;
    }
  };

  return { modifyPolicy };
};

export default useModifyPolicy;
