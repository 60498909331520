import React, { useContext, useState, useEffect } from 'react';
import DashboardNavbar from '../../../examples/Navbars/DashboardNavbar';
import DashboardLayout from '../../../examples/LayoutContainers/DashboardLayout';
import Grid from '@mui/material/Grid';
import { getToken } from 'shared/const/token.helper';
import './quote.scss';
import { UserDataContext } from '../../../shared/context/userDataContext';
import { useLocation } from 'react-router-dom';

const Quote = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const { selectedBusinessUnit, dataCenter, userRoles, sessionContext } =
    useContext(UserDataContext);
  const [iframeUrl, setIframeUrl] = useState();
  const quoteId = urlParams.get('quoteId');
  const bookingDataId = urlParams.get('bookingDataId');
  const brand = urlParams.get('brand');
  const token = getToken();
  const location = useLocation();

  useEffect(() => {
    let additonalData = null;
    if (location.state && location.state.welcomePageRedirection) {
      const { welcomePageRedirection, tail } = location.state;
      additonalData = new URLSearchParams(welcomePageRedirection).toString();
      window.history.replaceState(tail, '');
    }
    const brandCode = brand ? brand : selectedBusinessUnit.brand;
    setIframeUrl(
      `${
        dataCenter.widgetUrl
      }?brand=${brandCode}&token=${token}&sessionToken=${sessionContext}${
        quoteId ? `&quoteId=${quoteId}` : ''
      }${bookingDataId ? `&bookingDataId=${bookingDataId}` : ''}${
        additonalData ? `&${additonalData}` : ''
      }`,
    );
  }, [sessionContext, userRoles]);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Grid container style={{ marginTop: '0' }} spacing={3}>
        {dataCenter.widgetUrl && (
          <iframe src={iframeUrl} allow="clipboard-write"></iframe>
        )}
      </Grid>
    </DashboardLayout>
  );
};

Quote.propTypes = {};

Quote.defaultProps = {};

export default Quote;
