/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://material-ui.com/store/items/soft-ui-pro-dashboard/
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/**
  All of the routes for the Soft UI Dashboard PRO React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav.
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
 */

// Soft UI Dashboard PRO React layouts
import React from 'react';
import PendingQuotes from './layouts/home/pending-quotes/pending-quotes';
import Customers from './layouts/home/customers/customers';
import Policies from './layouts/home/policies/policies';
import MyProfile from './layouts/home/my-profile/my-profile';
import Users from './layouts/users/users';
import HomeIcon from '@mui/icons-material/Home';
import FavoriteIcon from '@mui/icons-material/Favorite';
import AddBoxIcon from '@mui/icons-material/AddBox';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import PeopleIcon from '@mui/icons-material/People';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Quote from './layouts/home/quote/quote';
import CustomerDetails from 'layouts/home/customerDetails/customerDetails';
import PolicyDetails from 'layouts/home/policyDetails/policyDetails';
import DiscountApproval from 'layouts/home/discount-approval/discount-approval';
import WelcomePage from './layouts/home/welcomePage/welcomePage';
import QuoteExternal from './layouts/home/quoteExternal/quoteExternal';
import Dashboard from 'layouts/home/dashboard/dashboard';
import GetQuote from 'layouts/home/get-quote/get-quote';
import ComplianceIcon from '@mui/icons-material/VerifiedUser';
import UpdatesIcon from '@mui/icons-material/Update';
import TravelIcon from '@mui/icons-material/TravelExplore';
import WebinarsIcon from '@mui/icons-material/VideoLibrary';
import BookIcon from '@mui/icons-material/Book';
import ContactIcon from '@mui/icons-material/ContactMail';
import FaqIcon from '@mui/icons-material/QuestionAnswer';
import DescriptionIcon from '@mui/icons-material/Description';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import PolicyCancellation from './layouts/home/policyCancellation/policyCancellation';
import TravelAlerts from 'layouts/home/travel-alerts/travel-alerts/travel-alerts';
import TravelAlert from 'layouts/home/travel-alerts/travel-alert/travel-alert';
import ImportanUpdate from 'layouts/home/important-updates/important-update/important-update';
import ImportantUpdates from 'layouts/home/important-updates/important-updates/important-updates';
import Webinars from 'layouts/home/webinars/webinars';
import Resources from 'layouts/home/resources/resources';
import FAQ from 'layouts/home/FAQ/FAQs';
import BookABDM from './layouts/home/book-a-bdm/bookABDM';
import SubmitAClaim from './layouts/home/Submit-a-claim/SubmitAClaim';
import ContactUs from './layouts/home/contact-us/ContactUs';
import ComplianceTraining from './layouts/home/compliance-training/ComplianceTraining';
import {BRAND, USER_ROLE} from './shared/const/roles.const';
import SimplifiedBooking from "./layouts/home/simplified-booking/simplifiedBooking";

const routesAvailableForAll = [
  {
    type: 'collapse',
    name: 'Home',
    key: 'welcome-page',
    route: '/welcome-page',
    component: <WelcomePage />,
    icon: <FavoriteIcon size="10px" />,
    noCollapse: true,
  },
  {
    route: '/simplified-booking/:bookingUUID',
    component: <SimplifiedBooking />,
  },
];

const routes = [
  ...routesAvailableForAll,
  {
    type: 'collapse',
    name: 'Dashboard',
    key: 'dashboard',
    route: '/dashboard',
    component: <Dashboard />,
    icon: <HomeIcon size="10px" />,
    noCollapse: true,
  },
  {
    type: 'title',
    title: 'CUSTOMER MANAGEMENT',
    key: 'customer-management',
  },
  {
    type: 'collapse',
    name: 'Customers',
    key: 'customers',
    route: '/customers',
    component: <Customers />,
    icon: <PeopleIcon size="10px" />,
    noCollapse: true,
  },
  {
    route: '/customer-details/:customerId',
    component: <CustomerDetails />,
  },
  {
    type: 'title',
    title: 'POLICY MANAGEMENT',
    key: 'policy-management',
  },
  {
    type: 'collapse',
    name: 'Policies',
    key: 'policies',
    route: '/policies',
    component: <Policies />,
    icon: <FileCopyIcon size="10px" />,
    noCollapse: true,
    routesTriggeringActiveState:['policies','policy-details']
  },
  {
    route: '/policy-details/:policyId',
    component: <PolicyDetails />,
  },
  {
    route: '/policy-details/:policyId/cancel',
    component: <PolicyCancellation />,
    availableForRoles: [USER_ROLE.SALES_POST],
  },
  {
    route: '/policy-details',
    component: <PolicyDetails />,
  },
  {
    type: 'title',
    title: 'QUOTES',
    key: 'quotes',
    availableForRoles: [USER_ROLE.SALES, USER_ROLE.SALES_ADVANCE],
  },
  {
    type: 'collapse',
    name: 'Quotes',
    key: 'pending-quotes',
    route: '/pending-quotes',
    component: <PendingQuotes />,
    icon: <DescriptionIcon size="10px" />,
    noCollapse: true,
    availableForRoles: [USER_ROLE.SALES, USER_ROLE.SALES_ADVANCE],
  },
  {
    type: 'collapse',
    name: 'Get a quote',
    key: 'new-quote',
    route: '/new-quote',
    component: <GetQuote />,
    icon: <AddBoxIcon size="10px" />,
    noCollapse: true,
    availableForRoles: [USER_ROLE.SALES, USER_ROLE.SALES_ADVANCE],
    availableForQuotesLocked: false,
    routesTriggeringActiveState:['new-quote','quote']
  },
  {
    type: 'title',
    title: 'ONLINE CLAIMS',
    key: 'online-claims',
    availableForRoles: [USER_ROLE.CLAIMS],
  },
  {
    type: 'collapse',
    name: 'Submit a claim',
    key: 'submit-a-claim',
    route: '/submit-a-claim',
    component: <SubmitAClaim />,
    icon: <NoteAddIcon size="10px" />,
    noCollapse: true,
    availableForRoles: [USER_ROLE.CLAIMS],
  },
  { type: 'title', title: 'TRAINING & RESOURCES', key: 'training-resources' },
  {
    type: 'collapse',
    name: 'Compliance training',
    key: 'compliance-training',
    route: '/compliance-training',
    component: <ComplianceTraining />,
    icon: <ComplianceIcon size="10px" />,
    noCollapse: true,
  },
  {
    type: 'collapse',
    name: 'Important Updates',
    key: 'important-updates',
    route: '/important-updates',
    component: <ImportantUpdates />,
    icon: <UpdatesIcon size="10px" />,
    noCollapse: true,
  },
  {
    route: '/important-updates/:id',
    component: <ImportanUpdate />,
  },
  {
    type: 'collapse',
    name: 'Travel Alerts',
    key: 'travel-alerts',
    route: '/travel-alerts',
    component: <TravelAlerts />,
    icon: <TravelIcon size="10px" />,
    noCollapse: true,
  },
  {
    route: '/travel-alerts/:id',
    component: <TravelAlert />,
  },
  {
    type: 'collapse',
    name: 'Webinars',
    key: 'webinars',
    route: '/webinars',
    component: <Webinars />,
    icon: <WebinarsIcon size="10px" />,
    noCollapse: true,
  },
  {
    type: 'collapse',
    name: 'Resources',
    key: 'resources',
    route: '/resources',
    component: <Resources />,
    icon: <PictureAsPdfIcon size="10px" />,
    noCollapse: true,
  },
  { type: 'title', title: 'SUPPORT', key: 'support' },
  {
    type: 'collapse',
    name: 'Book A BDM',
    key: 'book-a-bdm',
    route: '/book-a-bdm',
    component: <BookABDM />,
    icon: <BookIcon size="10px" />,
    noCollapse: true,
  },
  {
    type: 'collapse',
    name: 'Contact us',
    key: 'contact-us',
    route: '/contact-us',
    component: <ContactUs />,
    icon: <ContactIcon size="10px" />,
    noCollapse: true,
  },
  {
    type: 'collapse',
    name: 'FAQ',
    key: 'faq',
    route: '/faq',
    component: <FAQ />,
    icon: <FaqIcon size="10px" />,
    noCollapse: true,
  },
  { type: 'title', title: 'MY ACCOUNT', key: 'account' },
  {
    type: 'collapse',
    name: 'My Profile',
    key: 'my-profile',
    route: '/my-profile',
    component: <MyProfile />,
    icon: <AccountCircleIcon size="10px" />,
    noCollapse: true,
  },
  { type: 'divider', key: 'divider-1', roles: ['admin'] },
  { type: 'title', title: 'ADMIN', key: 'admin', roles: ['admin'] },
  {
    type: 'collapse',
    name: 'Users',
    key: 'users',
    route: '/admin/users',
    component: <Users />,
    icon: <HomeIcon size="10px" />,
    noCollapse: true,
    roles: ['admin'],
  },
  {
    route: '/quote',
    component: <Quote />,
  },
  {
    route: '/quote-external',
    component: <QuoteExternal />,
  },
  {
    type: 'collapse',
    key: 'discount',
    route: '/discount',
    component: <DiscountApproval />,
    noCollapse: true,
    roles: ['agent', 'admin'],
  },
];

export { routesAvailableForAll, routes };
