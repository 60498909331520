import React, { useState } from 'react';
import styles from './pending-quotes.module.scss';
import DashboardNavbar from '../../../examples/Navbars/DashboardNavbar';
import DashboardLayout from '../../../examples/LayoutContainers/DashboardLayout';
import SoftTypography from '../../../components/SoftTypography';
import { Button, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PendingQuotesTable from './pending-quotes-table/pending-quotes-table';
import useFetchPendingQuotes from './useFetchPendingQuotes';
import SuiInput from '../../../components/SoftInput';
import SoftButton from '../../../components/SoftButton';
import { emit$ } from 'shared/services/app-events';
import { AppEvents } from 'shared/services/app-events';
import SoftBox from '../../../components/SoftBox';
import { useGenerateUuid } from 'shared/utils/useGenerateUuid';

const PendingQuotes = () => {
  const { t } = useTranslation();
  const [searchTerm, setSearchTerm] = useState('');
  const { data, error, fetchData } = useFetchPendingQuotes(searchTerm);
  const tableUuid = useGenerateUuid();

  const handleSearchButtonClick = () => {
    emit$(AppEvents.RESET_TABLE_COUNTER, tableUuid);
    fetchData(searchTerm.trim());
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSearchButtonClick();
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <div className={styles.PendingQuotes} data-testid="PendingQuotes">
        <SoftBox sx={{ marginTop: '6rem' }}>
          <SoftTypography
            variant="h2"
            fontWeight="bold"
            sx={{ marginBottom: '2rem' }}
          >
            {t('HOME.PENDING_QUOTES.table.label')}
          </SoftTypography>
        </SoftBox>
        <div className={`test ${styles['search-container']}`}>
          <div className={styles['controls-wrapper']}>
            <SuiInput
              type="search"
              placeholder={t('HOME.PENDING_QUOTES.search')}
              label={t('HOME.PENDING_QUOTES.search')}
              value={searchTerm}
              onChange={(event) =>
                setSearchTerm(event.target.value.slice(0, 50))
              }
              variant="outlined"
              fullWidth
              className={styles['search-input']}
              onKeyPress={handleKeyPress}
            />
            <SoftButton
              variant="outlined"
              color="primary"
              style={{ color: '#0a76ec', borderColor: '#0a76ec' }}
              onClick={handleSearchButtonClick}
            >
              {t('HOME.PENDING_QUOTES.searchButton')}
            </SoftButton>
          </div>
        </div>
      </div>
      <PendingQuotesTable tableUuid={tableUuid} data={data} />
    </DashboardLayout>
  );
};

PendingQuotes.propTypes = {};

PendingQuotes.defaultProps = {};

export default PendingQuotes;
