import React, { useEffect } from 'react';
import WelcomeBrandBanner from './welcomeBrandBanner/welcomeBrandBanner';
import { Box } from '@mui/material';
import WelcomeTravelAlerts from './welcomeTravelAlerts/welcomeTravelAlerts';
import RedirectToDashboard from './welcomeMessage/redirectToDashboard';
import WelcomeQuickLinks from './welcomeQuickLinks/welcomeQuickLinks';
import WelcomeGetQuote from './welcomeGetQuote/welcomeGetQuote';
import DashboardNavbar from '../../../examples/Navbars/DashboardNavbar';
import WelcomeContactUs from './welcomeContactUs/welcomeContactUs';
import DashboardLayout from '../../../examples/LayoutContainers/DashboardLayout';
import Breadcrumbs from '../../../examples/Breadcrumbs';
import './welcomePage.scss';
import { useNavigate } from 'react-router-dom';

const WelcomePage = () => {
  const navigate = useNavigate();
  useEffect(() => {
    const handleMessage = (event) => {
      if (event.data && event.data.type === 'OPEN_NEW_TAB') {
        const { url: rawUrl } = event.data || {};

        if (!rawUrl || typeof rawUrl !== 'string') {
          throw new Error('[welcome page] url is not valid');
        }

        const urlParsed = rawUrl.replace(/[/?]/g, '');
        const urlParams = new URLSearchParams(urlParsed);
        const params = Object.fromEntries(urlParams);
        navigate('/quote', { state: {welcomePageRedirection: params} });
      }
    };
    window.addEventListener('message', handleMessage);
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar>
        <Breadcrumbs title="home" icon="home" route={['welcome-page']} />
      </DashboardNavbar>
      <Box sx={{ flexGrow: -1 }} className={'welcome-page'}>
        <div className="welcome-page-container">
          <div className="banner-area">
            <WelcomeBrandBanner />
          </div>
          <div className="travel-alerts-area">
            <WelcomeTravelAlerts />
          </div>
          <div className="widget-area">
            <WelcomeGetQuote />
          </div>
          <div className="go-to-dashboard-area">
            <RedirectToDashboard />
          </div>
          <div className="quick-links-area">
            <WelcomeQuickLinks />
            <WelcomeContactUs />
          </div>
        </div>
      </Box>
    </DashboardLayout>
  );
};
export default WelcomePage;
