import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
} from '@mui/material';
import SoftButton from 'components/SoftButton';
import useFetchMedicalScreening from '../useFetchMedicalScreening';
import './travellersTab.scss';
import { format } from 'date-fns';
import { reportError } from 'shared/services/raygunService';

const renderTextField = (label, value, index) => (
  <Grid item xs={12} md={6} key={index} className="field-container">
    <div className={'label-text'}>{label}</div>
    <div className="text-value">{value}</div>
  </Grid>
);

const TravellersTab = ({ policyDetails }) => {
  const { t } = useTranslation();
  const { fetchMedicalScreeningData } = useFetchMedicalScreening();
  const [state, setState] = useState({
    editedValues: [],
    conditionsData: null,
    medicalPreview: {},
    previewOpen: false,
  });

  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      editedValues: [...policyDetails.beneficiaries],
    }));
  }, [policyDetails]);

  const handlePreviewMedicalConditions = async (companionId) => {
    if (state.medicalPreview[companionId]) {
      setState((prevState) => ({
        ...prevState,
        conditionsData: state.medicalPreview[companionId],
        previewOpen: true,
      }));
    } else {
      try {
        const medicalData = await fetchMedicalScreeningData(companionId);
        setState((prevState) => ({
          ...prevState,
          conditionsData: medicalData,
          medicalPreview: {
            ...state.medicalPreview,
            [companionId]: medicalData,
          },
          previewOpen: true,
        }));
      } catch (error) {
        reportError(error);
      }
    }
  };

  const handleClosePreview = () => {
    setState((prevState) => ({
      ...prevState,
      previewOpen: false,
    }));
  };

  return (
    <div className="travelersTab">
      <Grid container spacing={2}>
        {state.editedValues.map((beneficiary, index) => (
          <Grid item xs={12} key={index}>
            <div className="header">
              <h3>
                {t('POLICY_DETAILS.traveller')} {index + 1}{' '}
                {beneficiary?.subscriber ? (
                  <span className="">
                    ({t('POLICY_DETAILS.tabPolicyHolder')})
                  </span>
                ) : (
                  ''
                )}
              </h3>
            </div>

            {renderTextField(
              t('POLICY_DETAILS.TRAVELERS.firstName'),
              beneficiary.firstName,
              index,
            )}
            {renderTextField(
              t('POLICY_DETAILS.TRAVELERS.lastName'),
              beneficiary.lastName,
              index,
            )}
            {renderTextField(
              t('POLICY_DETAILS.TRAVELERS.age'),
              format(new Date(beneficiary.birthDate), 'dd/MM/yyyy'),
              index,
            )}
            <div className="medical-info-row">
              {renderTextField(
                t('POLICY_DETAILS.TRAVELERS.medicalInfo'),
                beneficiary.wasScreened ? t('COMMON.yes') : t('COMMON.no'),
                index,
              )}
              {beneficiary.wasScreened && (
                <SoftButton
                  variant="outlined"
                  color="secondary"
                  className="previewButton"
                  onClick={() =>
                    handlePreviewMedicalConditions(beneficiary.companionId)
                  }
                >
                  {t('POLICY_DETAILS.TRAVELERS.previewMedicalConditions')}
                </SoftButton>
              )}
            </div>
          </Grid>
        ))}
      </Grid>

      <Dialog
        open={state.previewOpen}
        onClose={handleClosePreview}
        PaperProps={{ style: { minWidth: '30rem' } }}
      >
        <DialogTitle>
          {t('POLICY_DETAILS.TRAVELERS.MEDICAL_CONDITIONS.medicalConditions')}
        </DialogTitle>
        <DialogContent>
          {state.conditionsData?.conditions &&
          state.conditionsData?.conditions.length > 0 ? (
            state.conditionsData.conditions.map((condition, index) => (
              <div key={index} className="travellersTab-medicalConditions">
                <h2>{condition.name}</h2>
                {condition.questions.map((question, qIndex) => (
                  <div key={qIndex}>
                    <p>
                      <b>
                        {t(
                          'POLICY_DETAILS.TRAVELERS.MEDICAL_CONDITIONS.question',
                        )}
                        :
                      </b>{' '}
                      {question.question}
                    </p>
                    <p>
                      <b>
                        {t(
                          'POLICY_DETAILS.TRAVELERS.MEDICAL_CONDITIONS.answer',
                        )}
                        :
                      </b>{' '}
                      {question.answer}
                    </p>
                  </div>
                ))}
                {index < state.conditionsData.conditions.length - 1 && (
                  <Divider />
                )}
              </div>
            ))
          ) : (
            <div className="beneficiariesTab-noMedicalConditions">
              {t(
                'POLICY_DETAILS.TRAVELERS.MEDICAL_CONDITIONS.noMedicalConditions',
              )}
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <SoftButton
            onClick={handleClosePreview}
            variant="outlined"
            color="secondary"
            sx={{ '&:hover': { borderColor: '#0a76ec' } }}
          >
            {t('COMMON.close')}
          </SoftButton>
        </DialogActions>
      </Dialog>
    </div>
  );
};

TravellersTab.propTypes = {
  policyDetails: PropTypes.shape({
    policy: PropTypes.shape({
      policyNumber: PropTypes.string.isRequired,
      status: PropTypes.object.isRequired,
      subscriptionDate: PropTypes.string.isRequired,
    }),
    beneficiaries: PropTypes.arrayOf(
      PropTypes.shape({
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        birthDate: PropTypes.string,
        countryCode: PropTypes.string,
        index: PropTypes.string,
        companionId: PropTypes.number,
      }),
    ),
    insuranceData: PropTypes.shape({
      marketingOffers: PropTypes.bool,
      startDate: PropTypes.date,
      endDate: PropTypes.date,
    }),
  }).isRequired,
  onPolicyDetailsUpdate: PropTypes.func.isRequired,
};

export default TravellersTab;
