import React, { useContext } from 'react';
import DashboardNavbar from '../../../examples/Navbars/DashboardNavbar';
import DashboardLayout from '../../../examples/LayoutContainers/DashboardLayout';
import { Container, Link } from '@mui/material';
import { useTranslation } from 'react-i18next';
import SoftTypography from 'components/SoftTypography';
import typography from '../../../assets/theme/base/typography';
import { WelcomePageConfigContext } from '../../../shared/context/welcome-page-config-context';

const BookABDM = () => {
  const { t } = useTranslation();
  const { h4 } = typography;
  const { bookABdmLink } = useContext(WelcomePageConfigContext);

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <Container maxWidth="sm" style={{ marginTop: '6rem' }}>
        <SoftTypography
          variant="h2"
          fontWeight="bold"
          sx={{ marginBottom: '2rem' }}
        >
          {t('BOOK_A_BDM.bookABdm')}
        </SoftTypography>

        <SoftTypography variant="body2" sx={{ marginBottom: '2rem' }}>
          {t('BOOK_A_BDM.description')}
        </SoftTypography>

        <SoftTypography variant="body1">
          <Link
            href={`mailto:${bookABdmLink}`}
            fontSize={h4.fontSize}
            sx={{
              color: '#0a76ec',
            }}
          >
            {bookABdmLink}
          </Link>
        </SoftTypography>
      </Container>
    </DashboardLayout>
  );
};

export default BookABDM;
