import { useContext, useCallback } from 'react';
import { NotificationContext } from 'shared/context/notificationContext';
import { LoaderContext } from 'shared/context/loaderContext';
import { useCm360Api } from '../../../shared/const/api-backend';
import { reportError } from 'shared/services/raygunService';

const useSendMedicalCertificate = () => {
  const { showNotification } = useContext(NotificationContext);
  const { setLoading } = useContext(LoaderContext);
  const API = useCm360Api();

  const sendMedicalCertificate = useCallback(
    async (bookingId) => {
      try {
        setLoading(true);
        const apiUrl = `/booking/${bookingId}/certificate`;
        await API.post(apiUrl);
        setLoading(false);
        return { success: true };
      } catch (error) {
        setLoading(false);
        showNotification('Error sending medical certificate', 'error');
        reportError(error);
        return { success: false, error: error.message };
      }
    },
    [showNotification],
  );

  return sendMedicalCertificate;
};

export default useSendMedicalCertificate;
