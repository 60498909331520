import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import { LoaderContext } from 'shared/context/loaderContext';
import SuiButton from '../../../../components/SoftButton';
import useUpdateCustomerDetails from './useUpdateCustomerDetails';
import './customerTab.scss';
import SoftSelect from 'components/SoftSelect';
import { MAX_FIELD_LENGTH } from './customerTab.consts';
import { NotificationContext } from 'shared/context/notificationContext';
import SoftDatePicker from 'components/SoftDatePicker';
import { format } from 'date-fns';
import ValidatableSuiInput from 'shared/components/ValidatableSuiInput/ValidatableSuiInput';
import {
  emailValidationRegex,
  optionalPhoneNumberValidationRegex,
  phoneNumberValidationRegex,
} from 'shared/const/regexps';
import { titleOptions } from 'shared/const/titleOptions';
import { CONFIG_APP } from '../../../../shared/const/app.config';
import dayjs from 'dayjs';
import { reportError } from 'shared/services/raygunService';

const CustomerTab = ({ customerDetails: initialCustomerDetails }) => {
  const { t } = useTranslation();
  const { setLoading } = useContext(LoaderContext);
  const { showNotification } = useContext(NotificationContext);
  const { updateCustomerDetails } = useUpdateCustomerDetails();
  const [editing, setEditing] = useState(false);
  const [customerDetails, setCustomerDetails] = useState(
    initialCustomerDetails,
  );
  const [editedCustomer, setEditedCustomer] = useState({
    ...initialCustomerDetails.customer,
  });
  const [fieldErrors, setFieldErrors] = useState({});
  const [saveDisabled, setSaveDisabled] = useState(true);
  const [selectedTitle, setSelectedTitle] = useState(
    initialCustomerDetails.customer.title || '',
  );
  const [formattedDateOfBirth, setFormattedDateOfBirth] = useState(
    initialCustomerDetails.customer.dateOfBirth,
  );
  const [fieldTooLongError, setFieldTooLongError] = useState({});

  const requiredFields = [
    'title',
    'firstName',
    'lastName',
    'dateOfBirth',
    'emailAddress',
    'phoneNumber1',
  ];

  const handleEditClick = () => {
    setEditedCustomer({ ...customerDetails.customer });
    setEditing(true);
    setSaveDisabled(false);
  };

  const validateForm = () => {
    const newFieldErrors = {};
    let isValidForm = true;

    for (const key in editedCustomer) {
      if (editedCustomer.hasOwnProperty(key)) {
        const value = editedCustomer[key];
        let isValidField = true;

        if (requiredFields.includes(key)) {
          isValidField =
            value && (typeof value !== 'string' || value.trim() !== '');
        }

        if (value.length > MAX_FIELD_LENGTH) {
          isValidField = false;
          setFieldTooLongError((prevErrors) => ({
            ...prevErrors,
            [key]: true,
          }));
        } else {
          setFieldTooLongError((prevErrors) => ({
            ...prevErrors,
            [key]: false,
          }));
        }

        if (key === 'emailAddress') {
          isValidField =
            emailValidationRegex.test(value) &&
            value.length <= MAX_FIELD_LENGTH;
        } else if (key === 'phoneNumber1') {
          isValidField =
            phoneNumberValidationRegex.test(value) &&
            value.length <= MAX_FIELD_LENGTH;
        } else if (key === 'phoneNumber2') {
          isValidField =
            optionalPhoneNumberValidationRegex.test(value) &&
            value.length <= MAX_FIELD_LENGTH;
        } else if (key === 'postalCode') {
          isValidField = value.length <= 15;
        } else if (key === 'dateOfBirth') {
          isValidField = value;
        }

        newFieldErrors[key] = !isValidField;

        if (!isValidField) {
          isValidForm = false;
        }
      }
    }

    setFieldErrors(newFieldErrors);

    return isValidForm;
  };

  const handleFieldChange = (field, value) => {
    setSaveDisabled(false);

    if (field === 'countryCode') {
      return;
    }

    const isValidLength = value.length <= MAX_FIELD_LENGTH;
    let isValid = true;

    if (requiredFields.includes(field)) {
      isValid = value && (typeof value !== 'string' || value.trim() !== '');
    }

    if (!isValidLength) {
      isValid = false;
      setFieldTooLongError((prevErrors) => ({
        ...prevErrors,
        [field]: true,
      }));
    } else {
      setFieldTooLongError((prevErrors) => ({
        ...prevErrors,
        [field]: false,
      }));
    }

    if (field === 'emailAddress') {
      isValid = emailValidationRegex.test(value);
    } else if (field === 'phoneNumber1') {
      isValid = phoneNumberValidationRegex.test(value);
    } else if (field === 'phoneNumber2') {
      isValid = optionalPhoneNumberValidationRegex.test(value);
    } else if (field === 'postalCode') {
      isValid = value.length <= 15;
    } else if (field === 'dateOfBirth') {
      isValid = value;
    }

    setFieldErrors((prevFieldErrors) => ({
      ...prevFieldErrors,
      [field]: !isValid,
    }));

    setEditedCustomer((prevEditedCustomer) => ({
      ...prevEditedCustomer,
      [field]: value,
    }));
  };

  const handleTitleChange = (selectedOption) => {
    const selectedTitle = selectedOption.value;
    setSelectedTitle(selectedTitle);
    setEditedCustomer((prevEditedCustomer) => ({
      ...prevEditedCustomer,
      title: selectedTitle,
    }));
    setSaveDisabled(false);
  };

  const handleDateOfBirthChange = (date) => {
    if (!date[0] || date[0].length === 0) {
      setFormattedDateOfBirth('');
      handleFieldChange('dateOfBirth', '');
    } else {
      handleFieldChange(
        'dateOfBirth',
        dayjs(date[0]).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
      );
    }
  };

  const fieldGroups = [
    {
      keys: ['title', 'firstName', 'lastName', 'dateOfBirth'],
      layout: 'fourColumnsFirstNarrow',
    },
    {
      keys: ['emailAddress', 'phoneNumber1', 'phoneNumber2'],
      layout: 'threeColumns',
    },
    {
      keys: ['addressLine1', 'addressLine2', 'addressLine3', 'addressLine4'],
      layout: 'fourColumns',
    },
    { keys: ['postalCode', 'countryCode'], layout: 'twoColumns' },
  ];

  const handleSaveClick = async () => {
    const isValidForm = validateForm();

    if (!isValidForm) return;

    try {
      await updateCustomerDetails(customerDetails.customer.id, editedCustomer);
      setEditing(false);
      setCustomerDetails({
        ...customerDetails,
        customer: { ...editedCustomer },
      });
    } catch (error) {
      showNotification('CUSTOMERS.ERRORS.updateFailed', 'error');
      reportError(error);
    } finally {
      setLoading(false);
    }
  };

  const handleCancelClick = () => {
    setEditedCustomer({ ...customerDetails.customer });
    setSelectedTitle(customerDetails.customer.title);
    setFormattedDateOfBirth(customerDetails.customer.dateOfBirth);
    setEditing(false);
    setSaveDisabled(true);
    setFieldErrors({});
  };

  const renderField = (key) => {
    return (
      <Box key={key}>
        <label htmlFor={key}>
          {t(`CUSTOMERS.${key}`)}
          {requiredFields.includes(key) && (
            <span className="required-field">*</span>
          )}
        </label>
        {key === 'title' ? (
          <SoftSelect
            id="title"
            value={{ value: selectedTitle, label: selectedTitle }}
            onChange={handleTitleChange}
            isDisabled={!editing}
            required={requiredFields.includes(key)}
            readOnly={!editing}
            placeholder="Select title"
            options={titleOptions}
          />
        ) : key === 'dateOfBirth' ? (
          <SoftDatePicker
            input={{
              id: key,
              placeholder: t(`CUSTOMERS.${key}`),
              disabled: !editing,
              readOnly: !editing,
              className: fieldErrors[key] ? 'invalid' : '',
              error: fieldErrors[key],
            }}
            options={{
              maxDate: 'today',
              dateFormat: CONFIG_APP.BIRTH_DATE_FORMAT_DATEPICKER,
              allowInput: true,
            }}
            value={formattedDateOfBirth}
            onChange={(event) => handleDateOfBirthChange(event)}
            onClose={(event) => handleDateOfBirthChange(event)}
          />
        ) : (
          <ValidatableSuiInput
            id={key}
            type={key === 'emailAddress' ? 'email' : 'text'}
            placeholder={t(`CUSTOMERS.${key}`)}
            value={
              editing ? editedCustomer[key] : customerDetails.customer[key]
            }
            onChange={(event) => handleFieldChange(key, event.target.value)}
            error={fieldErrors[key]}
            readOnly={!editing || key === 'countryCode'}
            fullWidth
            errorMessage={fieldErrors[key]}
            required={requiredFields.includes(key)}
          />
        )}
        {fieldErrors[key] && (
          <p className="error-message">
            {key === 'emailAddress' && t('Invalid email format (xxxx@yy.zz)')}
            {(key === 'phoneNumber1' || key === 'phoneNumber2') &&
              t('Invalid phone number (9-13 characters)')}
            {key === 'postalCode' && t('Postal code is too long')}
            {key === 'dateOfBirth' && t('Date of birth is not valid')}
            {fieldTooLongError[key] && t('Text is too long')}
          </p>
        )}
      </Box>
    );
  };

  return (
    <div className="customerDetails-customerTab">
      <div className="header">
        <h4>{t('CUSTOMERS.tabCustomers')}</h4>
        <div>
          {editing ? (
            <>
              <SuiButton
                size="small"
                variant="gradient"
                color="success"
                className="button"
                disabled={saveDisabled}
                onClick={handleSaveClick}
              >
                {t('Save')}
              </SuiButton>
              <SuiButton
                size="small"
                variant="outlined"
                color="info"
                className="button ml"
                onClick={handleCancelClick}
              >
                {t('Cancel')}
              </SuiButton>
            </>
          ) : (
            <SuiButton
              size="small"
              variant="gradient"
              color="info"
              className="button"
              onClick={handleEditClick}
            >
              {t('Edit')}
            </SuiButton>
          )}
        </div>
      </div>
      <div className="field-groups-container">
        {fieldGroups.map(({ keys, layout }, index) => (
          <div key={index} className={`field-group ${layout}`}>
            {keys.map((key) => renderField(key))}
          </div>
        ))}
      </div>
    </div>
  );
};

CustomerTab.propTypes = {
  customerDetails: PropTypes.shape({
    customer: PropTypes.shape({
      dateOfBirth: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default CustomerTab;
