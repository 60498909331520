import rgba from 'assets/theme/functions/rgba';
import pxToRem from 'assets/theme/functions/pxToRem';
import colors from 'assets/theme/base/colors';

const { grey, transparent } = colors;

const scrollbar = {
  '*': {
    scrollbarWidth: 'thin',
    scrollbarColor: `${rgba(grey[600], 0.5)} ${transparent.main}`,
  },
  '*::-webkit-scrollbar': {
    width: pxToRem(8),
    height: pxToRem(8),
  },
  '*::-webkit-scrollbar-thumb': {
    backgroundColor: rgba(grey[600], 0.5),
    borderRadius: pxToRem(4),
  },
  '*::-webkit-scrollbar-track': {
    backgroundColor: transparent.main,
  },
};

export default scrollbar;
