import React, { useContext } from 'react';
import styles from './my-profile.module.scss';
import DashboardNavbar from '../../../examples/Navbars/DashboardNavbar';
import DashboardLayout from '../../../examples/LayoutContainers/DashboardLayout';
import {
  Box,
  Card,
  CardContent,
  Grid,
  Divider,
  Container,
} from '@mui/material';
import { UserDataContext } from 'shared/context/userDataContext';
import { useTranslation } from 'react-i18next';
import SoftTypography from 'components/SoftTypography';
import typography from '../../../assets/theme/base/typography';
import SoftBox from '../../../components/SoftBox';

const MyProfile = () => {
  const { t } = useTranslation();
  const joinWithCommas = (items) => (items ? items.join(', ') : '');
  const { userData } = useContext(UserDataContext);
  const { h4, caption } = typography;

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Container maxWidth="sm" style={{ marginTop: '6rem' }}>
        <SoftBox className={styles.MyProfile} data-testid="MyProfile">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <Card>
                <Box
                  className="table-toolbar"
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    py: 2,
                    ml: 5,
                  }}
                >
                  <SoftTypography
                    variant="h2"
                    fontWeight="bold"
                    sx={{ marginBottom: '1rem' }}
                  >
                    {t('My profile')}
                  </SoftTypography>
                </Box>
                <CardContent className={styles.cardContent} sx={{ ml: 3 }}>
                  {userData && (
                    <>
                      <SoftTypography fontSize={h4.fontSize} color="secondary">
                        {t('MY_PROFILE.userdata')}
                      </SoftTypography>
                      <Grid container mt={3} mb={3}>
                        <Grid item xs={4}>
                          <SoftTypography
                            color="secondary"
                            fontSize={caption.fontSize}
                          >
                            {t('MY_PROFILE.name')}
                          </SoftTypography>
                          <SoftTypography variant="body2">
                            {userData.firstName} {userData.lastName}
                          </SoftTypography>
                        </Grid>
                        <Grid item xs={4}>
                          <SoftTypography
                            color="secondary"
                            fontSize={caption.fontSize}
                          >
                            {t('MY_PROFILE.email')}
                          </SoftTypography>
                          <SoftTypography variant="body2">
                            {userData.email}
                          </SoftTypography>
                        </Grid>
                        <Grid item xs={4}>
                          <SoftTypography
                            color="secondary"
                            fontSize={caption.fontSize}
                          >
                            {t('MY_PROFILE.role')}
                          </SoftTypography>
                          <SoftTypography variant="body2">
                            {userData.roleName}
                          </SoftTypography>
                        </Grid>
                      </Grid>
                      <Divider variant="fullWidth" />
                      <SoftTypography fontSize={h4.fontSize} color="secondary">
                        {t('MY_PROFILE.dataCenters')}
                      </SoftTypography>
                      <Grid container mt={3} mb={3}>
                        <Grid item xs={4}>
                          <SoftTypography
                            color="secondary"
                            fontSize={caption.fontSize}
                          >
                            {t('MY_PROFILE.brands')}
                          </SoftTypography>
                          <SoftTypography variant="body2">
                            {joinWithCommas(
                              userData.brands?.map((brand) => brand.name),
                            )}
                          </SoftTypography>
                        </Grid>
                        <Grid item xs={4}>
                          <SoftTypography
                            color="secondary"
                            fontSize={caption.fontSize}
                          >
                            {t('MY_PROFILE.stores')}
                          </SoftTypography>
                          <SoftTypography variant="body2">
                            {joinWithCommas(
                              userData.businessUnits?.map((bu) => bu.shopName),
                            )}
                          </SoftTypography>
                        </Grid>
                      </Grid>
                      <Divider variant="fullWidth" />
                    </>
                  )}
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </SoftBox>
      </Container>
    </DashboardLayout>
  );
};

MyProfile.propTypes = {};

MyProfile.defaultProps = {};

export default MyProfile;
