import { useEffect, useState } from 'react';

export const useGenerateUuid = () => {
  const [uuid, setUuid] = useState('');

  useEffect(() => {
    if (typeof crypto !== 'undefined' && crypto.randomUUID) {
        setUuid(crypto.randomUUID());
      } else {
        setUuid(`${Date.now()}-${Math.random().toString(36).substring(2, 15)}`);
      }
  }, []);
  return uuid;
};
