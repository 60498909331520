import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardContent, CardActionArea } from '@mui/material';
import { format } from 'date-fns';
import { Link } from 'react-router-dom';
import SoftTypography from '../../../../../components/SoftTypography';
import typography from 'assets/theme/base/typography';
import colors from '../../../../../assets/theme/base/colors';

const TravelAlertCard = ({ travelAlert }) => {
  const { body2, size, fontWeightBold } = typography;

  return (
    <Card sx={{ mb: '1.5rem' }}>
      <CardActionArea component={Link} to={`/travel-alerts/${travelAlert.id}`}>
        <CardContent sx={{ px: '4rem', py: '1.5rem' }}>
          <SoftTypography
            variant="h5"
            sx={{
              color: colors.text.headingGrey,
              fontWeight: fontWeightBold,
              fontSize: size.lg,
            }}
          >
            {travelAlert.attributes.title}
          </SoftTypography>
          <SoftTypography
            variant="body2"
            mt={1}
            sx={{ color: colors.black.main }}
          >
            {travelAlert.attributes.shortDescription}
          </SoftTypography>
          <SoftTypography variant="caption" mt={1} fontSize={body2.fontSize}>
            {format(
              new Date(travelAlert.attributes.date),
              travelAlert.attributes.dateFormat,
            )}
          </SoftTypography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

TravelAlertCard.propTypes = {
  travelAlert: PropTypes.shape({
    id: PropTypes.string.isRequired,
    attributes: PropTypes.shape({
      title: PropTypes.string.isRequired,
      shortDescription: PropTypes.string,
      date: PropTypes.string.isRequired,
      dateFormat: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default TravelAlertCard;
