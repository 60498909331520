/**
 =========================================================
 * Soft UI Dashboard PRO React - v4.0.0
 =========================================================

 * Product Page: https://material-ui.com/store/items/soft-ui-pro-dashboard/
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// prop-types is a library for typechecking of props
import PropTypes from 'prop-types';

// @mui material components
import Grid from '@mui/material/Grid';

// Soft UI Dashboard PRO React components
import SoftBox from 'components/SoftBox';
import SoftTypography from 'components/SoftTypography';

// Soft UI Dashboard PRO React example components
import PageLayout from 'examples/LayoutContainers/PageLayout';
import typography from '../../../../assets/theme/base/typography';

function AuthLayout({ image, children }) {
  const { size } = typography;

  return (
    <PageLayout backgroundImage={image}>
      <SoftBox
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <SoftBox width="24rem">{children}</SoftBox>
      </SoftBox>
      <SoftTypography
        sx={{
          position: 'absolute',
          bottom: '20px',
          left: '20px',
          fontSize: size.md,
        }}
      >{`${new Date().getFullYear()} Europ Assistance`}</SoftTypography>
    </PageLayout>
  );
}

AuthLayout.propTypes = {
  image: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default AuthLayout;
