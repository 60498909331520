import React, { useContext } from 'react';
import './Loader.scss';
import { LoaderContext } from '../../context/loaderContext';

const Loader = () => {
  const { loading } = useContext(LoaderContext);
  if (!loading) {
    return null;
  }

  return (
    <div className="loader">
      <div className="spinner"></div>
    </div>
  );
};

export default Loader;
