import React, { useEffect } from 'react';
import DashboardNavbar from '../../../examples/Navbars/DashboardNavbar';
import DashboardLayout from '../../../examples/LayoutContainers/DashboardLayout';
import { Grid, Container } from '@mui/material';
import useFetchWebinarData from './useFetchWebinarData';
import { useTranslation } from 'react-i18next';
import SoftTypography from 'components/SoftTypography';
import WebinarCard from './components/WebinarCard';
import SoftBox from '../../../components/SoftBox';

const Webinars = () => {
  const { t } = useTranslation();
  const { data, fetchData } = useFetchWebinarData();

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Container maxWidth="sm" style={{ marginTop: '6rem' }}>
        <SoftTypography
          variant="h2"
          fontWeight="bold"
          sx={{ marginBottom: '2rem' }}
        >
          {t('WEBINARS.title')}
        </SoftTypography>
        <SoftBox data-testid="Webinars">
          <Grid container ml={0} pr={15}>
            <Grid item xs={12}>
              {data && data.length > 0 ? (
                data.map((webinar, index) => (
                  <WebinarCard webinar={webinar} key={index} />
                ))
              ) : (
                <SoftTypography>{t('WEBINARS.noneAvailable')}</SoftTypography>
              )}
            </Grid>
          </Grid>
        </SoftBox>
      </Container>
    </DashboardLayout>
  );
};

Webinars.propTypes = {};

Webinars.defaultProps = {};

export default Webinars;
