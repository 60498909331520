import { getLocaleByCurrency } from '../../helpers/currency.helper';
import PropTypes from 'prop-types';

const CurrencyComponent = ({ value, countryCode, showCountryCode = false }) => {
  if (countryCode) {
    const formattedCurrency = new Intl.NumberFormat(
      getLocaleByCurrency(countryCode),
      {
        style: 'currency',
        currency: countryCode,
      },
    ).format(value);
    return showCountryCode
      ? `${formattedCurrency} ${countryCode}`
      : formattedCurrency;
  }
  return value;
};

CurrencyComponent.propTypes = {
  value: PropTypes.number.isRequired,
  countryCode: PropTypes.string.isRequired,
};

export default CurrencyComponent;
