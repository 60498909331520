import { useContext, useEffect, useState } from 'react';
import { NotificationContext } from '../../../../shared/context/notificationContext';
import { useCm360Api } from 'shared/const/api-backend';
import { UserDataContext } from 'shared/context/userDataContext';
import { reportError } from 'shared/services/raygunService';

const useFetchEndorsements = (policyId) => {
  const { channel } = useContext(UserDataContext);
  const [endorsements, setEndorsements] = useState(null);
  const [error, setError] = useState(null);
  const { showNotification } = useContext(NotificationContext);
  const API = useCm360Api();

  const fetchEndorsementsData = async () => {
    if (!policyId) return;
    try {
      if (Object.keys(channel).length > 0) {
        const apiUrl = `/ui-proxy/ws-partners/api/platforms/${channel.psPlatform}/catalogGroups/${channel.catalogGroup}/policies/${policyId}/endorsements`;
        const response = await API.get(apiUrl);
        const responseData = response.data;
        setEndorsements(responseData);
      }
    } catch (error) {
      if (error?.response?.status === 404) {
        showNotification('COMMON.error.policyNotFound', 'error');
      } else {
        showNotification('COMMON.error.fetchingData', 'error');
        setError(error);
      }
      reportError(error);
    }
  };

  useEffect(() => {
    if (channel && channel.hasEndorsements) {
      fetchEndorsementsData();
    }
  }, [policyId, channel]);

  return { endorsements, error, fetchEndorsementsData };
};

export default useFetchEndorsements;
