import { useContext, useState, useEffect } from 'react';
import { LoaderContext } from '../../shared/context/loaderContext';
import { NotificationContext } from '../../shared/context/notificationContext';
import { UserDataContext } from 'shared/context/userDataContext';
import { usePortalApi } from 'shared/const/api-backend';

const useFetchUsers = () => {
  const { sessionContext } = useContext(UserDataContext);
  const { showNotification } = useContext(NotificationContext);
  const { setLoading } = useContext(LoaderContext);
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const API = usePortalApi();

  const fetchData = async (email) => {
    setLoading(true);

    try {
      let usersResponse;

      if (email) {
        usersResponse = await API.get(
          `/users?populate=*&filters[email][$contains]=${email}`,
        );
      } else {
        usersResponse = await API.get(`/users?populate=*`);
      }

      // const [rolesResponse, businessUnitsResponse] = await Promise.all([
      //   API.get(`${apiStrapiUrl}users-permissions/roles`),
      //   API.get(`${apiStrapiUrl}business-units`),
      // ]);

      // const rolesOptions = rolesResponse.data.roles
      //   .filter((role) => role.name !== 'Public' && role.name !== 'Authenticated')
      //   .map((r) => ({ value: r.id, label: r.name }));
      // const businessUnitsOptions = businessUnitsResponse.data.data.map((u) => ({ value: u.id, label: u.attributes.name }));

      // const usersWithRolesAndUnitsPromises = usersResponse.data.map(async (user) => {
      //   const userWithDetailsResponse = await API.get(`${apiStrapiUrl}users/${user.id}?populate=*`);
      //   const userWithDetails = userWithDetailsResponse.data;

      //   const role = rolesOptions.find((r) => r.value === userWithDetails.role.id);
      //   const businessUnits = businessUnitsOptions.filter((u) =>
      //     userWithDetails.businessUnits.find((bu) => bu.id === u.value)
      //   );

      //   return { ...userWithDetails, role, businessUnits };
      // });

      setData(usersResponse.data);
    } catch (error) {
      // console.error('Error fetching data:', error);
      showNotification('COMMON.error.fetchingData', 'error');
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [sessionContext]);

  return { data, error, fetchData };
};

export default useFetchUsers;
