import React, { useContext } from 'react';
import DashboardNavbar from '../../../examples/Navbars/DashboardNavbar';
import DashboardLayout from '../../../examples/LayoutContainers/DashboardLayout';
import { Card, CardContent, Container, Link, Icon } from '@mui/material';
import { useTranslation } from 'react-i18next';
import SoftTypography from '../../../components/SoftTypography';
import SoftBox from '../../../components/SoftBox';
import SoftButton from '../../../components/SoftButton';
import { WelcomePageConfigContext } from '../../../shared/context/welcome-page-config-context';

const ComplianceTraining = () => {
  const { t } = useTranslation();
  const { lmsLink } = useContext(WelcomePageConfigContext);

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <Container
        maxWidth="sm"
        style={{ marginTop: '6rem' }}
        data-testid="ComplianceTraining"
      >
        <SoftTypography
          variant="h2"
          fontWeight="bold"
          sx={{ marginBottom: '2rem' }}
        >
          {t('COMPLIANCE_TRAINING.complianceTraining')}
        </SoftTypography>
        {lmsLink ? (
          <Card sx={{ padding: '3rem' }}>
            <CardContent
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <SoftBox>
                <SoftTypography variant="body1">
                  {t('COMPLIANCE_TRAINING.description')}
                </SoftTypography>
              </SoftBox>
              <SoftBox>
                <SoftButton
                  variant="outlined"
                  color="secondary"
                  size="small"
                  href={lmsLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{
                    display: 'inline-flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    width: 'auto',
                    '&:hover': { borderColor: '#0a76ec' }
                  }}
                >
                  {t('COMPLIANCE_TRAINING.openTraining')}
                  <Icon sx={{ fontWeight: 'bold', ml: 3 }}>
                    arrow_forward_ios
                  </Icon>
                </SoftButton>
              </SoftBox>
            </CardContent>
          </Card>
        ) : (
          <SoftTypography>
            {t('COMPLIANCE_TRAINING.noneAvailable')}
          </SoftTypography>
        )}
      </Container>
    </DashboardLayout>
  );
};

export default ComplianceTraining;
