import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@mui/material';

import formatPriceHelper from '../helpers/formatPrice.helper';

import './PriceDiffSection.scss';
import SoftBox from '../../../../components/SoftBox';

const PriceDiffSection = ({ policyDetails, policyRefund, isCancelled }) => {
  const { t } = useTranslation();
  const { policy } = policyDetails;

  const priceDiff = useMemo(
    () => +policy.price.grossPremium.toFixed(2) - policyRefund,
    [policy, policyRefund],
  );

  const formattedPriceDiff = useMemo(() => {
    const diffSymbol = priceDiff < 0 ? '-' : '+';
    return `${diffSymbol} ${formatPriceHelper(
      Math.abs(priceDiff),
      policy.price.currency,
    )}`;
  }, [policy, priceDiff]);

  if (isCancelled) {
    return (
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        className="price-diff-price-section"
        sx={{ borderTop: 1, borderColor: 'grey.400', marginTop: 3 }}
      >
        <Typography variant="body2" color="gradients.info.main">
          {t('POLICY_CANCELLATION.refundAmount')}
        </Typography>
        <Typography variant="h5" color="gradients.info.main">
          + {formatPriceHelper(policyRefund, policy.price.currency) ?? 0}
        </Typography>
      </Grid>
    );
  }

  return (
    <div className="price-diff">
      <SoftBox
        className="price-diff-price-section"
        sx={{ borderBottom: 1, borderColor: 'grey.400' }}
      >
        <div className="price-diff-price-section-initial">
          <Typography variant="body2" color="grey.600">
            {t('POLICY_CANCELLATION.initialPolicy')}
          </Typography>
          <Typography
            className="price-diff-price-section-initial-value"
            variant="h5"
            color="grey.600"
          >
            {formatPriceHelper(
              policy.price.grossPremium ?? 0,
              policy.price.currency,
            )}
          </Typography>
        </div>
        <div className="price-diff-price-section-new">
          <Typography variant="body2" color="gradients.info.main">
            {t('POLICY_CANCELLATION.refundAmount')}
          </Typography>
          <Typography variant="h5" color="gradients.info.main">
            {formatPriceHelper(policyRefund, policy.price.currency) ?? 0}
          </Typography>
        </div>
      </SoftBox>
      <div className="price-diff-difference-row">
        <Typography variant="body2" color="grey.700">
          {t('POLICY_CANCELLATION.priceDifference')}{' '}
        </Typography>
        <Typography variant="body1" color="grey.700">
          {formattedPriceDiff}
        </Typography>
      </div>
    </div>
  );
};

PriceDiffSection.propTypes = {
  policyDetails: PropTypes.object.isRequired,
  policyRefund: PropTypes.number.isRequired,
  isCancelled: PropTypes.bool.isRequired,
};
export default PriceDiffSection;
