import React, { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styles from './customerDetails.module.scss';
import DashboardNavbar from '../../../examples/Navbars/DashboardNavbar';
import DashboardLayout from '../../../examples/LayoutContainers/DashboardLayout';
import SoftBox from 'components/SoftBox';
import { Tab, Tabs } from '@mui/material';
import { LoaderContext } from '../../../shared/context/loaderContext';
import { NotificationContext } from '../../../shared/context/notificationContext';
import useFetchCustomerDetails from './useFetchCustomerDetails';
import CustomerTab from './customerTab/customerTab';
import PoliciesTab from './policiesTab/policiesTab';
import PendingQuotesTab from './pendingQuotesTab/pendingQuotesTab';
import Breadcrumbs from 'examples/Breadcrumbs';

const CustomerDetails = () => {
  const { t } = useTranslation();
  const { customerId } = useParams();
  const [activeTab, setActiveTab] = useState(0); // 0 for Customers, 1 for Policies, 2 for Pending Quotes
  const { customerDetails, error, fetchData } =
    useFetchCustomerDetails(customerId);
  const { setLoading } = useContext(LoaderContext);
  const { showNotification } = useContext(NotificationContext);

  if (error) {
    setLoading(false);
    showNotification('COMMON.error.fetchingData', 'error');
  }

  if (!customerDetails) {
    return;
  }

  const { customer, policies, quotes } = customerDetails;

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleGoBack = () => {
    history.goBack();
  };

  return (
    <DashboardLayout>
      <DashboardNavbar>
      <Breadcrumbs
          title={customerId}
          route={[
            { title: t('CUSTOMERS.header'), link: '/customers' },
            {
              title: customerId,
              link: `/customer-details/${customerId}`,
            },
          ]}
        />
      </DashboardNavbar>
      <div className={styles.CustomerDetails} data-testid="CustomerDetails">
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          className={styles.tabs}
        >
          <Tab
            label={t('CUSTOMERS.tabCustomers')}
            style={{ flexGrow: 0, marginRight: '1rem', padding: '0 1rem' }}
          />
          <Tab
            label={t('CUSTOMERS.tabPolicies')}
            style={{ flexGrow: 0, marginRight: '1rem', padding: '0 1rem' }}
          />
          <Tab
            label={t('CUSTOMERS.tabPendingQuotes')}
            style={{ flexGrow: 0, padding: '0 1rem' }}
          />
        </Tabs>

        <SoftBox bgColor="white" borderRadius="lg" shadow="lg" p={2}>
          {activeTab === 0 && <CustomerTab customerDetails={customerDetails} />}
          {activeTab === 1 && <PoliciesTab policies={policies} />}
          {activeTab === 2 && <PendingQuotesTab pendingQuotes={quotes} />}
        </SoftBox>
      </div>
    </DashboardLayout>
  );
};

export default CustomerDetails;
