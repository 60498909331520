import { useState, useContext } from 'react';
import { LoaderContext } from 'shared/context/loaderContext';
import { NotificationContext } from 'shared/context/notificationContext';
import { useCm360Api } from 'shared/const/api-backend';
import { UserDataContext } from 'shared/context/userDataContext';
import { endorsementTypes } from './endorsementsTab.consts';
import dayjs from 'dayjs';
import { reportError } from 'shared/services/raygunService';

const useAddEndorsement = () => {
  const { channel } = useContext(UserDataContext);
  const { setLoading } = useContext(LoaderContext);
  const { showNotification } = useContext(NotificationContext);
  const [error, setError] = useState(null);
  const API = useCm360Api();

  const addEndorsement = async (
    policy,
    endorsement,
    calculatedPrice,
    transactionId,
    endorsementType,
  ) => {
    setLoading(true);
    try {
      const apiUrl = `/ui-proxy/ws-partners/api/platforms/${channel.psPlatform}/catalogs/${policy.product.iac}/policies/${policy.policy.policyNumber}/endorsements?ignorePaymentCheck=true`;
      const {
        beneficiary,
        itemCategory,
        itemDescription,
        itemAge,
        itemMake,
        itemSerialNumber,
        itemValue,
        travelStartDate,
        travelEndDate,
      } = endorsement;
      let payload;
      if (endorsementType === 'VALUABLES') {
        payload = {
          endorsementTypeId: endorsementTypes[0].endorsementTypeId,
          pointOfSale: 'PORTAL',
          appliesTo: 'SINGLE TRAVELLER',
          summarizeDetail:
            `${itemCategory} ${itemMake} ${itemDescription}`.slice(0, 255),
          travelStartDate: policy.insuranceData.startDate,
          travelEndDate: policy.insuranceData.endDate,
          itemValue: itemValue,
          itemMake: itemMake,
          itemSerialNumber: itemSerialNumber,
          itemDescription: itemDescription,
          itemCategory: itemCategory,
          itemAge: itemAge,
          paymentReference: transactionId,
          netEndorsementPrice: 0,
          grossEndorsementPrice: (itemValue * 7) / 100,
          beneficiaries: [beneficiary],
        };
      }
      if (endorsementType === 'TRIP_DURATION') {
        payload = {
          appliesTo: 'POLICY',
          endorsementTypeId: endorsementTypes[1].endorsementTypeId,
          pointOfSale: 'PORTAL',
          travelStartDate: dayjs(travelStartDate).toDate(),
          travelEndDate: dayjs(travelEndDate).toDate(),
          paymentReference: transactionId,
          netEndorsementPrice: calculatedPrice,
          grossEndorsementPrice: calculatedPrice,
          beneficiaries: [...policy.beneficiaries.map((b) => b.index)],
        };
      }
      await API.post(apiUrl, payload);
    } catch (error) {
      setError(error);
      showNotification('Error adding endorsement', 'error');
      reportError(error);
    } finally {
      setLoading(false);
    }
  };

  return { addEndorsement, error };
};

export default useAddEndorsement;
