import { useEffect, useState, useContext } from 'react';
import { LoaderContext } from '../../../shared/context/loaderContext';
import { NotificationContext } from '../../../shared/context/notificationContext';
import { useCm360Api } from '../../../shared/const/api-backend';
import { UserDataContext } from 'shared/context/userDataContext';
import { reportError } from 'shared/services/raygunService';

const useFetchCustomerDetails = (customerId) => {
  const [customerDetails, setCustomerDetails] = useState(null);
  const { setLoading } = useContext(LoaderContext);
  const [error, setError] = useState(null);
  const { showNotification } = useContext(NotificationContext);
  const API = useCm360Api();
  const { selectedBusinessUnit, selectedBrand } = useContext(UserDataContext);

  const fetchData = async () => {
    setLoading(true);
    try {
      if (Object.keys(selectedBusinessUnit).length > 0) {
        const response = await API.get(`customers/${customerId}`);
        setCustomerDetails(response.data);
      }
    } catch (error) {
      setError(error);
      showNotification('CUSTOMERS.error.fetchingData', 'error');
      reportError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [customerId, selectedBusinessUnit, selectedBrand]);

  return { customerDetails, error, fetchData };
};

export default useFetchCustomerDetails;
