import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Typography, Box, Icon } from '@mui/material';
import { BlocksRenderer } from '@strapi/blocks-react-renderer';
import SoftTypography from '../SoftTypography';
import SoftBox from '../SoftBox';

const createContactUsBlocks = (scrollToSection, sectionsRefs) => ({
  heading: ({ children, level }) => {
    const headingVariants = {
      1: 'h1',
      2: 'h2',
      3: 'h3',
      4: 'h4',
      5: 'h5',
      6: 'h6',
    };

    const buildHeadingElement = (level, sectionId, ref, children) => {
      switch (level) {
        case 1:
          return (
            <SoftTypography
              variant="h2" // variant h2 matches what should be shown for h1
              fontWeight="bold"
              sx={{ marginBottom: '2rem' }}
              id={sectionId}
              ref={ref}
            >
              {children}
            </SoftTypography>
          );
        case 2:
          return (
            <SoftTypography
              variant="h3" // variant h3 matches what should be shown for h2
              fontWeight="regular"
              id={sectionId}
              ref={ref}
            >
              {children}
            </SoftTypography>
          );
        default:
          return (
            <SoftTypography
              variant={headingVariants[level]}
              id={sectionId}
              ref={ref}
            >
              {children}
            </SoftTypography>
          );
      }
    };

    const sectionId = children[0].props.text
      .split(' ')
      .join('')
      .toLowerCase()
      .trim();

    sectionsRefs.current[sectionId] =
      sectionsRefs.current[sectionId] || React.createRef();

    const refToBeAttached = sectionsRefs.current[sectionId];

    return buildHeadingElement(level, sectionId, refToBeAttached, children);
  },
  list: ({ children, format }) => {
    if (format === 'unordered') {
      return (
        <SoftBox
          sx={{
            display: 'flex',
            alignItems: 'center',
            listStyle: 'none',
            margin: '0',
            padding: '0',
          }}
        >
          <Icon
            sx={{
              fontWeight: 'bold',
              marginRight: '0.5rem',
              marginLeft: '2rem',
            }}
          >
            arrow_forward
          </Icon>
          <SoftTypography component="span" variant="body2">
            {children}
          </SoftTypography>
        </SoftBox>
      );
    } else {
      return (
        <Typography variant="body2" sx={{ marginLeft: '2rem' }}>
          {children}
        </Typography>
      );
    }
  },
  link: ({ children, url }) => {
    const anchor = url.split('/contact-us/')[1]?.replace('#', '') || '';

    const isExternalLink = !anchor;
    if (isExternalLink) {
      return (
        <a href={url} target="_blank" rel="noopener noreferrer">
          {children}
        </a>
      );
    }

    return (
      <Link
        to={`#${anchor}`}
        onClick={(e) => {
          scrollToSection(anchor);
        }}
      >
        {children}
      </Link>
    );
  },
  paragraph: ({ children }) => (
    <Typography variant="body2">{children}</Typography>
  ),
});

const ContactUsBlocksRenderer = ({
  content,
  scrollToSection,
  sectionsRefs,
}) => {
  const blocks = createContactUsBlocks(scrollToSection, sectionsRefs);

  return <BlocksRenderer content={content} blocks={blocks} />;
};

ContactUsBlocksRenderer.propTypes = {
  content: PropTypes.array.isRequired, // Assuming content is an array, adjust based on actual data type
  scrollToSection: PropTypes.func.isRequired, // Function to scroll to a section
  sectionsRefs: PropTypes.object.isRequired, // Ref object storing section references
};

export default ContactUsBlocksRenderer;
