import { addDays } from 'date-fns';

const DAYS_POLICY_COOLDOWN_LIMIT = 14;

export const CancellationRejectCauses = {
  POLICY_ALREADY_ENDED: 'POLICY_ALREADY_ENDED',
  POLICY_CONTAINS_ENDORSEMENTS: 'POLICY_CONTAINS_ENDORSEMENTS',
  POLICY_COOLDOWN_PERIOD: 'POLICY_COOLDOWN_PERIOD',
};

export const checkForPolicyCancellationRejectCause = (policyDetails) => {
  const today = new Date();

  // policy already ended
  if (new Date(policyDetails.insuranceData.endDate) <= today) {
    return CancellationRejectCauses.POLICY_ALREADY_ENDED;
  }

  return null;
};

export const checkIfFullRefundPossible = (policyDetails) => {
  const today = new Date();
  // policy in cooldown period
  const subscriptionDate = new Date(policyDetails.policy.subscriptionDate);
  const endOfCooldown = addDays(
    new Date(policyDetails.policy.subscriptionDate),
    DAYS_POLICY_COOLDOWN_LIMIT,
  );
  if (today > subscriptionDate && today < endOfCooldown) {
    return CancellationRejectCauses.POLICY_COOLDOWN_PERIOD;
  }

  return false;
};

// PRO RATA REFUND
export const checkIfPartialRefundPossible = (policyDetails) => {
  const today = new Date();
  const coolingOffPeriod = addDays(
    new Date(policyDetails.policy.subscriptionDate),
    DAYS_POLICY_COOLDOWN_LIMIT,
  );
  return (
    today > coolingOffPeriod &&
    today < new Date(policyDetails.insuranceData.endDate)
  );
};

export const checkIfPolicyCanBeExtended = (policyDetails, endorsements) => {
  const today = new Date();
  const policyStartDate = new Date(policyDetails.insuranceData.startDate);
  const policyEndDate = new Date(policyDetails.insuranceData.endDate);
  if (today < policyStartDate) {
    return 'extendPolicyBeforeStartDate';
  } else if (today > policyEndDate) {
    return 'extendPolicyAfterEndDate';
  }
  return false;
};
