import React, { useEffect } from 'react';
import DashboardNavbar from '../../../examples/Navbars/DashboardNavbar';
import DashboardLayout from '../../../examples/LayoutContainers/DashboardLayout';
import { Box, Container, Grid } from '@mui/material';
import useFetchResourcesData from './useFetchResourcesData';
import { useTranslation } from 'react-i18next';
import SoftTypography from 'components/SoftTypography';
import typography from '../../../assets/theme/base/typography';
import ResourceCard from './components/ResourceCard';
import SoftBox from '../../../components/SoftBox';

const Resources = () => {
  const { t } = useTranslation();
  const { data, fetchData } = useFetchResourcesData();
  const { h2 } = typography;

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Container maxWidth="sm" style={{ marginTop: '6rem' }}>
        <SoftTypography
          variant="h2"
          fontWeight="bold"
          sx={{ marginBottom: '2rem' }}
        >
          {t('RESOURCES.title')}
        </SoftTypography>
        <SoftBox data-testid="Resources">
          <Grid container ml={0} pr={15}>
            <Grid item xs={12}>
              {data && data.length > 0 ? (
                data.map((resource, index) => (
                  <ResourceCard resource={resource} key={index} />
                ))
              ) : (
                <SoftTypography>{t('RESOURCES.noneAvailable')}</SoftTypography>
              )}
            </Grid>
          </Grid>
        </SoftBox>
      </Container>
    </DashboardLayout>
  );
};

Resources.propTypes = {};

Resources.defaultProps = {};

export default Resources;
