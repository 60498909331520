import React, { useContext, useState } from 'react';
import DashboardLayout from '../../examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from '../../examples/Navbars/DashboardNavbar';
import useFetchUsers from './useFetchUsers';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import { useTranslation } from 'react-i18next';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { LoaderContext } from '../../shared/context/loaderContext';
import { NotificationContext } from '../../shared/context/notificationContext';
import SoftButton from 'components/SoftButton';
import SoftTypography from 'components/SoftTypography';
import SoftInput from 'components/SoftInput';
import SuiInput from 'components/SoftInput';
import SoftBox from 'components/SoftBox';
import { Controller, useForm } from 'react-hook-form';
import SoftSelect from 'components/SoftSelect';
import styles from './users.module.scss';
import useFetchUserDetails from './useFetchUserDetails';
import GenericTable from 'shared/components/GenericTable/genericTable';
import useUpdateUser from './useUpdateUser';
import { reportError } from 'shared/services/raygunService';
import { useGenerateUuid } from 'shared/utils/useGenerateUuid';
import { emit$, AppEvents } from 'shared/services/app-events';

const Users = () => {
  const { t } = useTranslation();
  const { data, error, fetchData } = useFetchUsers();
  const path = 'HOME.USERS.table.header';
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [userToEdit, setUserToEdit] = useState();
  const { setLoading } = useContext(LoaderContext);
  const { showNotification } = useContext(NotificationContext);
  const [roles, setRoles] = useState();
  const [businessUnits, setBusinessUnits] = useState();
  const [searchEmail, setSearchEmail] = useState('');
  const [searching, setSearching] = useState(false);
  const { fetchUserDetails } = useFetchUserDetails();
  const { updateUser } = useUpdateUser();

  const tableUuid = useGenerateUuid();

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm();

  const handleSearchButtonClick = async () => {
    emit$(AppEvents.RESET_TABLE_COUNTER, tableUuid);
    setSearching(true);
    await fetchData(searchEmail);
    setSearching(false);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSearchButtonClick();
    }
  };

  const handleEditClick = (user) => {
    reset();
    setLoading(true);

    fetchUserDetails(user.id)
      .then((data) => {
        setRoles(data.roles);
        setBusinessUnits(data.businessUnits);
        setUserToEdit(data.userDetails);
        setOpenEditDialog(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const editUser = async (updatedUser) => {
    setLoading(true);
    if (!updatedUser.role) {
      updatedUser.role = userToEdit.role.value;
    }

    await updateUser(userToEdit, updatedUser)
      .then(() => {
        fetchData(searchEmail);
        setOpenEditDialog(false);
        showNotification('NOTIFICATION.success.userHasBeenEdited', 'success');
      })
      .catch((error) => {
        showNotification('COMMON.error.editingData', 'error');
        reportError(error);
      })
      .finally(() => setLoading(false));
  };

  const handleRoleChange = (role) => {
    return setValue('role', role.value);
  };

  const handleBusinessUnitsChange = (value) => {
    return setValue(
      'businessUnits',
      value.map((v) => v.value),
    );
  };

  const columns = [
    {
      label: t(`${path}.id`),
      value: (user) => user.id,
    },
    {
      label: t(`${path}.name`),
      value: (user) => `${user.firstName} ${user.lastName}`,
    },
    {
      label: t(`${path}.email`),
      value: (user) => user.email,
    },
    {
      label: t(`${path}.role`),
      value: (user) => user.role.name,
    },
    {
      label: t(`${path}.businessUnits`),
      value: (user) =>
        user.businessUnits && user.businessUnits.length > 0
          ? user.businessUnits
              .map((businessUnit) => businessUnit.name)
              .join(', ')
          : '',
    },
    {
      label: t(`${path}.options`),
      value: () => (
        <IconButton size="small" color="success">
          <EditIcon />
        </IconButton>
      ),
      width: '70px',
    },
  ];

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <div className={styles.Users}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            py: 2,
          }}
        >
          <SoftTypography>{t('HOME.USERS.table.label')}</SoftTypography>
        </Box>
        {data && (
          <>
            <div className={styles.searchContainer}>
              <div className={styles.searchInputs}>
                <SuiInput
                  type="search"
                  placeholder={t('CUSTOMERS.searchEmail')}
                  label={t('CUSTOMERS.searchEmail')}
                  value={searchEmail}
                  onChange={(e) => setSearchEmail(e.target.value)}
                  variant="outlined"
                  fullWidth
                  className={styles['search-input']}
                  onKeyPress={handleKeyPress}
                />
              </div>
              <div className={styles.searchButton}>
                <SoftButton
                  variant="outlined"
                  color="info"
                  onClick={handleSearchButtonClick}
                  disabled={searching}
                >
                  {t('CUSTOMERS.search')}
                </SoftButton>
              </div>
            </div>
            <GenericTable
              tableUuid={tableUuid}
              data={data}
              columns={columns}
              path={path}
              onRowClick={handleEditClick}
            />
          </>
        )}
      </div>

      <Dialog
        fullWidth
        open={openEditDialog}
        onClose={() => setOpenEditDialog(false)}
      >
        {openEditDialog && (
          <>
            <DialogTitle>
              <SoftTypography>
                <EditIcon sx={{ paddingTop: '0.3rem' }} />{' '}
                {t('HOME.USERS.dialog.editUserHeader')}
              </SoftTypography>
            </DialogTitle>
            <SoftBox
              component="form"
              role="form"
              onSubmit={handleSubmit(editUser)}
            >
              <DialogContent>
                <SoftBox mb={2}>
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    textTransform="capitalize"
                  >
                    {t('HOME.USERS.dialog.firstNameLabel')}
                  </SoftTypography>
                  <SoftInput
                    type="text"
                    placeholder={t('HOME.USERS.dialog.firstNameLabel')}
                    defaultValue={userToEdit?.firstName}
                    disabled
                    {...register('firstName', { required: true })}
                  />
                </SoftBox>
                <SoftBox mb={2}>
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    textTransform="capitalize"
                  >
                    {t('HOME.USERS.dialog.lastNameLabel')}
                  </SoftTypography>
                  <SoftInput
                    type="text"
                    placeholder={t('HOME.USERS.dialog.lastNameLabel')}
                    defaultValue={userToEdit?.lastName}
                    disabled
                    {...register('lastName', { required: true })}
                  />
                </SoftBox>
                <SoftBox mb={2}>
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    textTransform="capitalize"
                  >
                    {t('HOME.USERS.dialog.emailLabel')}
                  </SoftTypography>
                  <SoftInput
                    type="email"
                    placeholder="Email"
                    defaultValue={userToEdit?.email}
                    disabled
                    {...register('email', {
                      required: t('COMMON.validation.required'),
                      pattern: /^\S+@\S+$/i,
                    })}
                  />
                </SoftBox>
                <SoftBox mb={2}>
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    textTransform="capitalize"
                  >
                    {t('HOME.USERS.dialog.roleLabel')}
                  </SoftTypography>
                  <Controller
                    name="role"
                    control={control}
                    render={({ field, value }) => (
                      <SoftSelect
                        {...field}
                        placeholder={t('HOME.USERS.dialog.roleLabel')}
                        value={value}
                        defaultValue={userToEdit.role}
                        options={roles}
                        onChange={handleRoleChange}
                      />
                    )}
                  />
                </SoftBox>
                <SoftBox mb={2}>
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    textTransform="capitalize"
                  >
                    {t('HOME.USERS.dialog.businessUnitsLabel')}
                  </SoftTypography>
                  <Controller
                    name="businessUnits"
                    control={control}
                    render={({ field, value }) => (
                      <SoftSelect
                        {...field}
                        placeholder={t('HOME.USERS.dialog.businessUnitsLabel')}
                        isMulti
                        value={value}
                        defaultValue={userToEdit.businessUnits}
                        options={businessUnits}
                        onChange={handleBusinessUnitsChange}
                      />
                    )}
                  />
                </SoftBox>
              </DialogContent>
              <DialogActions>
                <SoftButton
                  size="small"
                  onClick={() => setOpenEditDialog(false)}
                >
                  <CloseIcon sx={{ marginRight: '0.2rem' }} />
                  {t('COMMON.cancel')}
                </SoftButton>
                <SoftButton size="small" type="submit" color="info" autoFocus>
                  <CheckIcon sx={{ marginRight: '0.2rem' }} />
                  {t('COMMON.save')}
                </SoftButton>
              </DialogActions>
            </SoftBox>
          </>
        )}
      </Dialog>
    </DashboardLayout>
  );
};

Users.propTypes = {};

Users.defaultProps = {};

export default Users;
