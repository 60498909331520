import WelcomeQuickLinks from '../welcomeLinksList/welcomeLinksList';
import { useTranslation } from 'react-i18next';

const WelcomeContactUs = () => {
  const { t } = useTranslation();
  const contactUs = [
    {
      title: t('WELCOME_PAGE.CONTACT_US.firstLink'),
      internalLink: '/contact-us#medical&travelemergencies',
    },
    {
      title: t('WELCOME_PAGE.CONTACT_US.secondLink'),
      internalLink: '/contact-us#partnershipmanagers',
    },
    {
      title: t('WELCOME_PAGE.CONTACT_US.thirdLink'),
      internalLink: '/contact-us#complaints',
    },
    {
      title: t('WELCOME_PAGE.CONTACT_US.fourthLink'),
      internalLink: '/contact-us#serviceandclaims',
    },
  ];

  return (
    <WelcomeQuickLinks
      title={t('WELCOME_PAGE.CONTACT_US.title')}
      quickLinks={contactUs}
    />
  );
};

export default WelcomeContactUs;
