import { useState, useContext } from 'react';
import { LoaderContext } from 'shared/context/loaderContext';
import { NotificationContext } from 'shared/context/notificationContext';
import { useCm360Api } from 'shared/const/api-backend';
import { UserDataContext } from 'shared/context/userDataContext';
import dayjs from 'dayjs';
import { reportError } from 'shared/services/raygunService';

const useFetchEndorsementRefund = () => {
  const { channel } = useContext(UserDataContext);
  const [endorsementRefund, setEndorsementRefund] = useState(null);
  const [refundDate, setRefundDate] = useState(null);
  const { setLoading } = useContext(LoaderContext);
  const [error, setError] = useState(null);
  const { showNotification } = useContext(NotificationContext);
  const API = useCm360Api();

  const fetchRefundData = async (policy, refundDate, endorsementId) => {
    setLoading(true);
    const formattedDate = dayjs(refundDate)
      .startOf('day')
      .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
    setRefundDate(formattedDate);
    const policyId = policy.policy.policyNumber;
    const catalog = policy.product.iac;
    try {
      if (Object.keys(channel).length > 0) {
        const apiUrl = `/ui-proxy/ws-partners/api/platforms/${channel.psPlatform}/catalogs/${catalog}/policies/${policyId}/endorsements/refund/calculate`;
        const body = {
          type: 'ENDORSEMENT',
          refundDate: formattedDate,
          endorsementId,
        };
        const response = await API.post(apiUrl, body);
        const responseData = response.data;
        setEndorsementRefund(responseData.refundAmount);
        return responseData;
      }
    } catch (error) {
      setError(error);
      showNotification('COMMON.error.fetchingData', 'error');
      reportError(error);
    } finally {
      setLoading(false);
    }
  };

  return {
    endorsementRefund,
    error,
    fetchRefundData,
    setEndorsementRefund,
    refundDate,
  };
};

export default useFetchEndorsementRefund;
