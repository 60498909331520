import { Skeleton } from '@mui/material';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import './skeletonPage.scss';

const SkeletonPage = () => {
  return (
    <DashboardLayout className="skeleton-page-container">
      <Skeleton
        variant="rectangular"
        sx={{
          height: '50px',
          borderRadius: '8px',
        }}
      />
      <Skeleton
        variant="rectangular"
        sx={{
          height: 200,
          borderRadius: '8px',
        }}
      />
      <Skeleton
        variant="rectangular"
        sx={{
          flexGrow: 1,
          borderRadius: '8px',
        }}
      />
    </DashboardLayout>
  );
};

export default SkeletonPage;
