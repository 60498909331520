import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import './endorsementPayment.scss';
import SoftBox from 'components/SoftBox';

const EndorsementPayment = ({ policyDetails, endorsementDetails }) => {
  const { t } = useTranslation();
  const initialValues = {
    grossPremium: `${endorsementDetails.grossEndorsementPrice} ${policyDetails.policy.price.currency}`,
    reference: endorsementDetails.paymentReference,
  };
  const nonEmptyInitialValues = Object.fromEntries(
    Object.entries(initialValues).filter(([_, value]) => value !== undefined),
  );

  return (
    <SoftBox className="endorsement-payment">
      <SoftBox className="header" sx={{ mb: 1, mt: 3, fontSize: '1.25rem' }}>
        <h4>{t('POLICY_DETAILS.tabPayment')}</h4>
      </SoftBox>
      {Object.entries(nonEmptyInitialValues).map(([name, value]) => (
        <SoftBox className="field" key={name} sx={{ display: 'flex' }}>
          <SoftBox className="label-text" sx={{ mr: 1, fontWeight: 'bold' }}>
            {t(`POLICY_DETAILS.PAYMENT.${name}`)}:
          </SoftBox>
          <SoftBox className="text-value">{value}</SoftBox>
        </SoftBox>
      ))}
    </SoftBox>
  );
};

EndorsementPayment.propTypes = {
  policyDetails: PropTypes.object,
  endorsementDetails: PropTypes.object,
};

export default EndorsementPayment;
