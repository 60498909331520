import { useState, useContext } from 'react';
import { LoaderContext } from 'shared/context/loaderContext';
import { NotificationContext } from 'shared/context/notificationContext';
import { useCm360Api } from 'shared/const/api-backend';
import { UserDataContext } from 'shared/context/userDataContext';
import { reportError } from 'shared/services/raygunService';

const useCancelEndorsement = () => {
  const { channel } = useContext(UserDataContext);
  const { setLoading } = useContext(LoaderContext);
  const { showNotification } = useContext(NotificationContext);
  const [error, setError] = useState(null);
  const API = useCm360Api();

  const cancelEndorsement = async (policy, id, refundAmount) => {
    setLoading(true);
    try {
      const apiUrl = `/ui-proxy/ws-partners/api/platforms/${channel.psPlatform}/catalogs/${policy.product.iac}/endorsements/${id}`;
      await API.post(apiUrl, { refundAmount });
    } catch (error) {
      setError(error);
      showNotification('Error cancelling endorsement', 'error');
      reportError(error);
    } finally {
      setLoading(false);
    }
  };

  return { cancelEndorsement, error };
};

export default useCancelEndorsement;
