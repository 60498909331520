import { useContext } from 'react';
import { LoaderContext } from '../../shared/context/loaderContext';
import { NotificationContext } from '../../shared/context/notificationContext';
import { usePortalApi } from 'shared/const/api-backend';
import { reportError } from 'shared/services/raygunService';

const useUpdateUser = () => {
  const { setLoading } = useContext(LoaderContext);
  const { showNotification } = useContext(NotificationContext);
  const API = usePortalApi();

  const updateUser = async (userToEdit, updatedUser) => {
    setLoading(true);
    try {
      if (!updatedUser.role) {
        updatedUser.role = userToEdit.role.value;
      }

      await API.put(`/users/${userToEdit.id}`, updatedUser);
      showNotification('NOTIFICATION.success.userHasBeenEdited', 'success');
    } catch (error) {
      showNotification('COMMON.error.editingData', 'error');
      reportError(error);
    } finally {
      setLoading(false);
    }
  };

  return { updateUser };
};

export default useUpdateUser;
