import React, { useEffect, useRef, useState, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ArrowUpward } from '@mui/icons-material';
import {
  Card,
  CardContent,
  Typography,
  IconButton,
  Container,
} from '@mui/material';
import DashboardNavbar from '../../../examples/Navbars/DashboardNavbar';
import DashboardLayout from '../../../examples/LayoutContainers/DashboardLayout';
import ContactUsBlocksRenderer from '../../../components/StrapiBlockRenderer/ContactUsBlocksRenderer';
import { WelcomePageConfigContext } from '../../../shared/context/welcome-page-config-context';

const headerHeight = 100;

const ScrollToTopButton = () => {
  const [isVisible, setIsVisible] = useState(false);

  const checkScrollTop = () => {
    if (window.scrollY > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    window.addEventListener('scroll', checkScrollTop);
    return () => {
      window.removeEventListener('scroll', checkScrollTop);
    };
  }, []);

  return (
    <>
      {isVisible && (
        <IconButton
          onClick={scrollToTop}
          style={{
            position: 'fixed',
            bottom: 40,
            right: 40,
            backgroundColor: '#0a76ec',
            color: '#fff',
            zIndex: 1000,
          }}
        >
          <ArrowUpward />
        </IconButton>
      )}
    </>
  );
};

const ContactUs = () => {
  const { t } = useTranslation();
  const [hasScrolled, setHasScrolled] = useState(false);
  const sectionsRefs = useRef({});
  const location = useLocation();
  const { contactUsContent } = useContext(WelcomePageConfigContext);

  const scrollToSection = (sectionId) => {
    const targetElement = sectionsRefs.current[sectionId]?.current;

    if (targetElement) {
      const elementPosition =
        targetElement.getBoundingClientRect().top + window.scrollY;
      const offsetPosition = elementPosition - headerHeight;
      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      });
    }
  };

  useEffect(() => {
    const sectionId = location.hash.substring(1); // remove the `#` from the hash

    const handleScroll = () => {
      if (
        !hasScrolled &&
        sectionId &&
        sectionsRefs.current[sectionId]?.current
      ) {
        scrollToSection(sectionId);
        setHasScrolled(true);
      }
    };

    const timer = setTimeout(() => {
      handleScroll();
    }, 1000);

    return () => {
      clearTimeout(timer);
    };
  }, [contactUsContent, location.hash, hasScrolled]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Container
        sx={{ marginTop: '6rem', marginBottom: '30vh' }}
        maxWidth="sm"
        data-testid="ContactUs"
      >
        {contactUsContent?.length ? (
          <ContactUsBlocksRenderer
            content={contactUsContent}
            scrollToSection={scrollToSection}
            sectionsRefs={sectionsRefs}
          />
        ) : (
          <Typography>{t('CONTACT_US.noneAvailable')}</Typography>
        )}
        <ScrollToTopButton />
      </Container>
    </DashboardLayout>
  );
};

export default ContactUs;
