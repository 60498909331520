import { useContext, useEffect, useState } from 'react';
import { LoaderContext } from '../../../shared/context/loaderContext';
import { NotificationContext } from '../../../shared/context/notificationContext';
import { useCm360Api } from '../../../shared/const/api-backend';
import { UserDataContext } from 'shared/context/userDataContext';
import { useNavigate } from 'react-router-dom';
import { reportError } from 'shared/services/raygunService';

const useFetchPolicyDetails = ({
  policyId,
  persistentQuoteId,
  bookingDataId,
}) => {
  const {
    dataCenter,
    selectedBusinessUnit,
    fetchShopId,
    saveBusinessUnit,
    selectedBrand,
    channel
  } = useContext(UserDataContext);
  const [policyDetails, setPolicyDetails] = useState(null);
  const [persistentQuoteDetails, setPersistentQuoteDetails] = useState(null);
  const { setLoading } = useContext(LoaderContext);
  const [error, setError] = useState(null);
  const { showNotification } = useContext(NotificationContext);
  const navigate = useNavigate();
  const API = useCm360Api();

  const fetchData = async () => {
    setLoading(true);
    try {
      if (Object.keys(channel).length > 0) {
        const url = `/ui-proxy`;
        const requests = [];
        if (!policyId && !bookingDataId)
          throw new Error('Policy identifier required');

        const fetchPolicyUrl = policyId
          ? `${url}/policies/${policyId}`
          : `${url}/policies?bookingDataId=${encodeURIComponent(
              bookingDataId,
            )}`;
        requests.push(API.get(fetchPolicyUrl));
        persistentQuoteId &&
          requests.push(API.get(`${url}/quotes/${persistentQuoteId}`));

        const [response, quoteResponse] = await Promise.all(requests);
        const responseData = response.data;
        const quoteResponseData = quoteResponse?.data || null;

        setPolicyDetails(responseData);
        setPersistentQuoteDetails(quoteResponseData);

        const possibleShopId = responseData?.policy?.genericData?.shopId;

        if (possibleShopId && possibleShopId !== selectedBusinessUnit.shopId) {
          const businessUnitResponse = await fetchShopId(possibleShopId);
          const businessUnit = {
            ...businessUnitResponse[0],
            brand:
              businessUnitResponse[0]?.default_brand?.name ??
              selectedBrand.brand,
          };
          saveBusinessUnit(businessUnit, true);
        }
      }
    } catch (error) {
      setError(error);
      if (error?.response?.status === 404) {
        showNotification('COMMON.error.policyNotFound', 'error');
      } else {
        showNotification('COMMON.error.fetchingData', 'error');
      }
      navigate(-1);
      reportError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [policyId, channel, dataCenter]);

  return { policyDetails, persistentQuoteDetails, error, fetchData };
};

export default useFetchPolicyDetails;
