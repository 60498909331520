import React, { memo, useContext, useMemo } from 'react';
import { WelcomePageConfigContext } from '../../../../shared/context/welcome-page-config-context';
import { normalizeUrl } from '../../../../shared/helpers/url.helper';
import { Skeleton } from '@mui/material';

const WelcomeBrandBanner = () => {
  const { headerBanner, isConfigLoading } = useContext(
    WelcomePageConfigContext,
  );
  const imageUrl = useMemo(() => {
    const url = headerBanner?.url || null;
    return url ? normalizeUrl(url) : null;
  }, [headerBanner]);

  if (isConfigLoading) {
    return <>{<Skeleton variant="rounded" width="100%" height={100} />} </>;
  }

  return (
    <>
      {imageUrl && (
        <img
          className="company-banner"
          style={{ width: '100%', objectFit: 'contain' }}
          src={imageUrl}
          alt="fctg"
        />
      )}
    </>
  );
};

export default memo(WelcomeBrandBanner);
