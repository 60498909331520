const formatPriceHelper = (value, currency) => {
  return (
    new Intl.NumberFormat('en-AU', {
      style: 'currency',
      currency: currency,
    }).format(value) +
    ' ' +
    currency
  );
};

export default formatPriceHelper;
