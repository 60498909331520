import React, { useContext } from 'react';
import DashboardWithWidgets from '../dashboard-with-widgets/dashboard-with-widgets';
import DashboardEmpty from '../dashboard-empty/dashboard-empty';
import {
  AppConfigurationContext,
  featuresMap,
} from 'shared/context/configurationContext';

const Dashboard = () => {
  return <DashboardWithWidgets />;
};

Dashboard.propTypes = {};
Dashboard.defaultProps = {};

export default Dashboard;
