import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Typography,
  Checkbox,
  FormControlLabel,
  Grid,
} from '@mui/material';
import dayjs from 'dayjs';
import DashboardLayout from '../../../examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from '../../../examples/Navbars/DashboardNavbar';
import styles from './policyCancellation.module.scss';
import useFetchPolicyDetails from '../policyDetails/useFetchPolicyDetails';
import SoftSelect from '../../../components/SoftSelect';
import { policyCancellationReasons } from './const/policyCancellationReasons';
import { NotificationContext } from '../../../shared/context/notificationContext';
import {
  CancellationRejectCauses,
  checkForPolicyCancellationRejectCause,
} from '../policyDetails/policyDetails.helper';
import useCancelPolicy from './helpers/useCancelPolicy';
import useFetchPolicyRefund from './helpers/useFetchPolicyRefund';
import CancelledPolicyDetails from './cancelledPolicyDetails';
import PriceDiffSection from './PriceDiffSection/PriceDiffSection';
import SoftButton from 'components/SoftButton';
import Breadcrumbs from 'examples/Breadcrumbs';

const PolicyCancellationLayout = () => {
  const { t } = useTranslation();
  const { policyId } = useParams();
  const { showNotification } = useContext(NotificationContext);
  const navigate = useNavigate();
  const { policyDetails, fetchData } = useFetchPolicyDetails({
    policyId,
    persistentQuoteId: null,
    bookingDataId: null,
  });
  const { policyRefund, refundDate, fetchRefundData } = useFetchPolicyRefund();
  const { cancelPolicy } = useCancelPolicy();
  const [reason, setReason] = useState(null);
  const [confirmed, setConfirmed] = useState(false);

  const handleReasonChange = (reason) => {
    setReason(reason);
  };

  const handleConfirmChange = (event) => {
    setConfirmed(event.target.checked);
  };

  const isCancelled = useMemo(
    () =>
      policyDetails?.policy?.status?.label === 'cancelled' ||
      policyDetails?.policy?.status.status === 13,
    [policyDetails],
  );

  const handleCancelPolicy = async () => {
    const cancellationRejectCause =
      checkForPolicyCancellationRejectCause(policyDetails);
    if (cancellationRejectCause) {
      if (
        cancellationRejectCause ===
        CancellationRejectCauses.POLICY_ALREADY_ENDED
      ) {
        showNotification(
          t('POLICY_DETAILS.daysPolicyCancellationLimit'),
          'error',
        );
      } else if (
        cancellationRejectCause ===
        CancellationRejectCauses.POLICY_CONTAINS_ENDORSEMENTS
      ) {
        showNotification(
          t('POLICY_DETAILS.cancellationOfPoliciesWithEndorsements'),
          'error',
        );
      }
      return Promise.reject();
    }
    await cancelPolicy(policyDetails, policyRefund, refundDate, reason.value);
    await fetchData(policyId);
  };

  const navigateToPolicyDetails = () => {
    const queryParams = new URLSearchParams(location.search);
    const persistentQuoteId = queryParams.get('persistentQuoteId');
    const searchParams = new URLSearchParams();

    if (persistentQuoteId)
      searchParams.set('persistentQuoteId', persistentQuoteId);

    navigate(`/policy-details/${policyId}?${searchParams.toString()}`);
  };

  useEffect(() => {
    if (policyId) {
      fetchData(policyId);
    }
  }, [policyId]);

  useEffect(() => {
    if (policyDetails) {
      fetchRefundData(policyDetails, dayjs().toDate());
    }
  }, [policyDetails]);

  if (!policyDetails) {
    return <></>;
  }

  return (
    <DashboardLayout>
      <DashboardNavbar>
      <Breadcrumbs
          title={t('COMMON.cancel')}
          route={[
            { title: t('POLICIES.header'), link: '/policies' },
            {
              title: policyDetails?.policy.policyNumber,
              link: `/policy-details/${policyDetails?.policy.policyNumber}`,
            },
            {
              title: 'cancel',
            }
          ]}
        />
      </DashboardNavbar>
      <Box className={styles.PolicyCancellation}>
        {isCancelled ? (
          <CancelledPolicyDetails
            policyDetails={policyDetails}
            policyRefund={policyRefund}
          />
        ) : (
          <>
            <Typography variant="h2">
              {t('POLICY_CANCELLATION.cancelPolicy')}
            </Typography>

            <Typography
              variant="body2"
              className={styles.PolicyNumber}
              color="grey.700"
            >
              {t('POLICY_CANCELLATION.policyNumber')}:{' '}
              <Typography
                variant="body2"
                component="span"
                color="gradients.info.main"
                fontWeight={700}
              >
                {policyDetails.policy.policyNumber}
              </Typography>
            </Typography>

            <PriceDiffSection
              policyDetails={policyDetails}
              policyRefund={policyRefund}
              isCancelled={isCancelled}
            />

            <Box className={styles.reasonDropdown}>
              <SoftSelect
                placeholder={t('POLICY_CANCELLATION.selectReason')}
                options={policyCancellationReasons}
                value={reason}
                onChange={handleReasonChange}
                className="reason-dropdown"
              />
            </Box>
            <FormControlLabel
              control={
                <Checkbox
                  checked={confirmed}
                  onChange={handleConfirmChange}
                  name="confirm"
                  color="primary"
                />
              }
              label={t('POLICY_CANCELLATION.consent')}
              className={styles.consentCheckbox}
            />

            <Grid container spacing={2} className={styles.actions}>
              <Grid item>
                <SoftButton
                  variant="outlined"
                  color="secondary"
                  onClick={() => navigateToPolicyDetails()}
                  sx={{ '&:hover': { borderColor: '#0a76ec' } }}
                >
                  {t('COMMON.back')}
                </SoftButton>
              </Grid>
              <Grid item>
                <SoftButton
                  variant="contained"
                  color="info"
                  onClick={handleCancelPolicy}
                  disabled={!reason || !confirmed}
                  sx={{ '&:hover': { borderColor: '#0a76ec' } }}
                >
                  {t('POLICY_CANCELLATION.cancelPolicy')}
                </SoftButton>
              </Grid>
            </Grid>
          </>
        )}
      </Box>
    </DashboardLayout>
  );
};

export default PolicyCancellationLayout;
