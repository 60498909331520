import React, { useContext } from 'react';
import { UserDataContext } from '../../../shared/context/userDataContext';
import { getToken } from '../../../shared/const/token.helper';
import DashboardLayout from '../../../examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from '../../../examples/Navbars/DashboardNavbar';
import Grid from '@mui/material/Grid';
import { useLocation, useParams } from 'react-router-dom';

const SimplifiedBooking = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const { sessionContext, dataCenter } = useContext(UserDataContext);
  const { bookingUUID } = useParams();
  const token = getToken();
  const productCode = searchParams.get('productCode');
  const config = searchParams.get('config');
  let iframeUrl = `${dataCenter.widgetUrl}?brand=${config}&bookingType=simplified&bookingDataId=${bookingUUID}&token=${token}&sessionToken=${sessionContext}`;
  if (productCode) {
    iframeUrl = `${iframeUrl}&productCode=${productCode}`;
  }
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Grid container style={{ marginTop: '0' }} spacing={3}>
        <iframe src={iframeUrl} allow="clipboard-write"></iframe>
      </Grid>
    </DashboardLayout>
  );
};

export default SimplifiedBooking;
