import React, { useContext, useEffect, useState } from 'react';
import { UserDataContext } from '../../../../shared/context/userDataContext';
import { getToken } from '../../../../shared/const/token.helper';

const WelcomeGetQuote = () => {
  const {
    selectedBusinessUnit,
    dataCenter,
    userRoles,
    sessionContext,
  } = useContext(UserDataContext);
  const [iframeUrl, setIframeUrl] = useState();
  const token = getToken();

  useEffect(() => {
    setIframeUrl(
      `${dataCenter.widgetUrl}?brand=${selectedBusinessUnit?.brand}&token=${token}&sessionToken=${sessionContext}&redirectUrl=/`,
    );
  }, [sessionContext]);

  return (
    <>
      {dataCenter.widgetUrl && userRoles.sales && (
        <div
          style={{
            minHeight: '50px',
            marginBottom: '1rem',
            borderRadius: '.75rem',
            overflow: 'hidden',
            backgroundColor: 'transparent',
          }}
          className="welcome-get-quote"
        >
          <iframe
            id="bookingWidget"
            style={{ backgroundColor: 'transparent' }}
            src={iframeUrl}
            allow="clipboard-write"
          ></iframe>
        </div>
      )}
    </>
  );
};

export default WelcomeGetQuote;
