import { useContext, useState } from 'react';
import useDidMountEffect from 'shared/utils/useDidMountEffect';
import { LoaderContext } from '../../../shared/context/loaderContext';
import { NotificationContext } from '../../../shared/context/notificationContext';
import { CONFIG_TABLE } from '../../../shared/const/app.config';
import { UserDataContext } from 'shared/context/userDataContext';
import { useCm360Api } from '../../../shared/const/api-backend';
import { reportError } from 'shared/services/raygunService';

const useFetchCustomersData = () => {
  const { selectedBusinessUnit, selectedBrand, sessionContext } =
    useContext(UserDataContext);
  const { setLoading } = useContext(LoaderContext);
  const { showNotification } = useContext(NotificationContext);
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [searchParams, setSearchParams] = useState({
    firstName: '',
    lastName: '',
    email: '',
  });
  const API = useCm360Api();

  const fetchData = async (firstName, lastName, email) => {
    setLoading(true);
    try {
      let apiUrl = `customers?offset=0&limit=${CONFIG_TABLE.MAX_ROWS_FROM_BACKEND}`;

      const finalFirstName = firstName ?? searchParams.firstName;
      const finalLastName = lastName ?? searchParams.lastName;
      const finalEmail = email ?? searchParams.email;

      if (finalFirstName) apiUrl += `&firstname=${finalFirstName}`;
      if (finalLastName) apiUrl += `&lastname=${finalLastName}`;
      if (finalEmail) apiUrl += `&email=${finalEmail}`;

      setSearchParams({
        firstName: finalFirstName,
        lastName: finalLastName,
        email: finalEmail,
      });
      const response = await API.get(apiUrl);
      setData(response.data);
    } catch (error) {
      showNotification('COMMON.error.fetchingData', 'error');
      reportError(error);
    } finally {
      setLoading(false);
    }
  };

  useDidMountEffect(() => {
    if (!selectedBusinessUnit || !selectedBrand) {
      showNotification('COMMON.error.noBusinessUnitSelected', 'error');
      return;
    }
    fetchData();
  }, [sessionContext]);

  return { data, error, fetchData };
};

export default useFetchCustomersData;
