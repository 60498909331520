/**
 =========================================================
 * Soft UI Dashboard PRO React - v4.0.0
 =========================================================

 * Product Page: https://material-ui.com/store/items/soft-ui-pro-dashboard/
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import { useContext, useEffect, useState } from 'react';

// react-router-dom components
import { NavLink, useLocation } from 'react-router-dom';

// prop-types is a library for typechecking of props.
import PropTypes from 'prop-types';

// @mui material components
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import Icon from '@mui/material/Icon';
import './Sidenav.scss';

// Soft UI Dashboard PRO React components
import SoftBox from 'components/SoftBox';
import SoftTypography from 'components/SoftTypography';

// Soft UI Dashboard PRO React example components
import SidenavCollapse from 'examples/Sidenav/SidenavCollapse';
import SidenavList from 'examples/Sidenav/SidenavList';
import SidenavItem from 'examples/Sidenav/SidenavItem';
import SidenavSkeleton from 'examples/Sidenav/SidenavSkeleton';

// Custom styles for the Sidenav
import SidenavRoot from 'examples/Sidenav/SidenavRoot';
import logo from '../../assets/images/logo.svg';
import logoMini from '../../assets/images/logo-mini.png';

// Soft UI Dashboard PRO React context
import { setMiniSidenav, useSoftUIController } from 'context';
import Box from '@mui/material/Box';
import Footer from '../Footer';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { UserDataContext } from 'shared/context/userDataContext';
import { AuthContext } from '../../AuthContext';
import SoftSelect from 'components/SoftSelect';
import { useTranslation } from 'react-i18next';

function Sidenav({ color, brand, brandName, routes, isLoading, ...rest }) {
  const [openCollapse, setOpenCollapse] = useState(false);
  const [openNestedCollapse, setOpenNestedCollapse] = useState(false);
  const [controller, dispatch] = useSoftUIController();
  const { platform } = useContext(AuthContext);
  const { selectedBrand, brands, saveBrand, userData } =
    useContext(UserDataContext);
  const [defaultBrand, setDefaultBrand] = useState(() => {
    if (!selectedBrand) {
      return null;
    }
    return {
      value: selectedBrand.code,
      label: selectedBrand.name,
    };
  });
  const { miniSidenav, transparentSidenav } = controller;
  const location = useLocation();
  const { pathname } = location;
  const { t } = useTranslation();
  const collapseName = pathname.split('/').slice(1)[0];
  const itemName = pathname.split('/').slice(1)[1];
  const handleMiniSidenav = () => {
    setMiniSidenav(dispatch, !miniSidenav);
  };

  const brandOptions = brands.map((brand) => ({
    value: brand.code,
    label: brand.name,
  }));

  const closeSidenav = () => setMiniSidenav(dispatch, true);

  useEffect(() => {
    if (selectedBrand) {
      setDefaultBrand({
        value: selectedBrand.code,
        label: selectedBrand.name,
      });
    } else if (brands && brands.length > 0) {
      setDefaultBrand({
        value: brands[0].code,
        label: brands[0].name,
      });
    } else {
      setDefaultBrand(null);
    }
  }, [selectedBrand, brands]);

  useEffect(() => {
    // A function that sets the mini state of the sidenav.
    function handleMiniSidenav() {
      setMiniSidenav(dispatch, window.innerWidth < 1200);
    }

    /**
         The event listener that's calling the handleMiniSidenav function when resizing the window.
         */
    window.addEventListener('resize', handleMiniSidenav);

    // Call the handleMiniSidenav function to set the state with the initial value.
    handleMiniSidenav();

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleMiniSidenav);
  }, [dispatch, location]);

  const handleSelectChange = (selectedOption) => {
    setDefaultBrand(selectedOption);
    const newSelectedBrand = brands.find(
      (brand) => brand.code === selectedOption.value,
    );
    if (newSelectedBrand) {
      saveBrand(newSelectedBrand);
    }
  };

  // Render all the nested collapse items from the routes.js
  const renderNestedCollapse = (collapse) => {
    const filteredCollapse = collapse.filter(
      (collapse) => collapse.route || collapse.href,
    );
    const template = filteredCollapse.map(({ name, route, key, href }) =>
      href ? (
        <Link
          key={key}
          href={href}
          target="_blank"
          rel="noreferrer"
          sx={{ textDecoration: 'none' }}
        >
          <SidenavItem name={name} nested />
        </Link>
      ) : (
        <NavLink to={route} key={key} sx={{ textDecoration: 'none' }}>
          <SidenavItem name={name} active={route === pathname} nested />
        </NavLink>
      ),
    );

    return template;
  };

  // Render the all the collpases from the routes.js
  const renderCollapse = (collapses) =>
    collapses.map(
      ({ name, collapse, route, href, key, routesTriggeringActiveState }) => {
        let returnValue;
        if (collapse) {
          returnValue = (
            <SidenavItem
              key={key}
              name={name}
              active={
                routesTriggeringActiveState?.includes(itemName) ||
                key === itemName
              }
              open={openNestedCollapse === name}
              onClick={() =>
                openNestedCollapse === name
                  ? setOpenNestedCollapse(false)
                  : setOpenNestedCollapse(name)
              }
            >
              {renderNestedCollapse(collapse)}
            </SidenavItem>
          );
        } else {
          returnValue = href ? (
            <Link
              href={href}
              key={key}
              target="_blank"
              rel="noreferrer"
              sx={{ textDecoration: 'none' }}
            >
              <SidenavItem
                name={name}
                active={
                  routesTriggeringActiveState?.includes(itemName) ||
                  key === itemName
                }
              />
            </Link>
          ) : (
            <NavLink to={route} key={key} sx={{ textDecoration: 'none' }}>
              <SidenavItem name={name} active={key === itemName} />
            </NavLink>
          );
        }
        return <SidenavList key={key}>{returnValue}</SidenavList>;
      },
    );

  // Render all the routes from the routes.js (All the visible items on the Sidenav)
  const renderRoutes = routes.map(
    ({
      type,
      name,
      icon,
      title,
      collapse,
      noCollapse,
      key,
      href,
      route,
      roles,
      visibleForBrand,
      routesTriggeringActiveState,
    }) => {
      let returnValue;

      const shouldDisplayRoute = () => {
        if (!roles || roles.length === 0) return true; // Display if no roles specified

        // Check if the user's role matches any of the roles specified for this route
        return roles.some((role) => role === userData.role?.type);
      };
      const isVisibleInMenu = () => type !== 'collapse' || (name && icon);

      const isBrandVisible = () => {
        if (!visibleForBrand) return true;
        return visibleForBrand.indexOf(platform) > -1;
      };
      // Check if the route should be displayed based on roles
      if (shouldDisplayRoute() && isVisibleInMenu() && isBrandVisible()) {
        if (type === 'collapse') {
          if (href) {
            returnValue = (
              <Link
                href={href}
                key={key}
                target="_blank"
                rel="noreferrer"
                sx={{ textDecoration: 'none' }}
              >
                <SidenavCollapse
                  name={name}
                  icon={icon}
                  active={
                    routesTriggeringActiveState?.includes(collapseName) ||
                    key === collapseName
                  }
                  noCollapse={noCollapse}
                />
              </Link>
            );
          } else if (noCollapse && route) {
            returnValue = (
              <NavLink to={route} key={key}>
                <SidenavCollapse
                  name={name}
                  icon={icon}
                  noCollapse={noCollapse}
                  active={
                    routesTriggeringActiveState?.includes(collapseName) ||
                    key === collapseName
                  }
                >
                  {collapse ? renderCollapse(collapse) : null}
                </SidenavCollapse>
              </NavLink>
            );
          } else {
            returnValue = (
              <SidenavCollapse
                key={key}
                name={name}
                icon={icon}
                active={key === collapseName}
                open={openCollapse === key}
                onClick={() =>
                  openCollapse === key
                    ? setOpenCollapse(false)
                    : setOpenCollapse(key)
                }
              >
                {collapse ? renderCollapse(collapse) : null}
              </SidenavCollapse>
            );
          }
        } else if (type === 'title') {
          returnValue = (
            <SoftTypography
              key={key}
              display="block"
              variant="caption"
              fontWeight="bold"
              textTransform="uppercase"
              opacity={0.6}
              pl={3}
              mt={2}
              mb={1}
              ml={1}
            >
              {title}
            </SoftTypography>
          );
        } else if (type === 'divider') {
          returnValue = <Divider key={key} />;
        }
      } else {
        returnValue = null; // Route should not be displayed
      }

      return returnValue;
    },
  );

  return (
    <SidenavRoot
      {...rest}
      variant="permanent"
      ownerState={{ transparentSidenav, miniSidenav }}
    >
      <span
        className={miniSidenav ? 'icon-collapse collapsed' : 'icon-collapse'}
      >
        {!miniSidenav && (
          <KeyboardArrowLeftIcon
            fontSize={'medium'}
            onClick={() => handleMiniSidenav()}
          ></KeyboardArrowLeftIcon>
        )}
        {miniSidenav && (
          <ChevronRightIcon
            fontSize={'medium'}
            onClick={() => handleMiniSidenav()}
          ></ChevronRightIcon>
        )}
      </span>
      <div>
        <SoftBox pt={1} pb={1} px={4} textAlign="center">
          <SoftBox
            component={NavLink}
            to="/"
            display="flex"
            sx={{ flexDirection: 'column' }}
            alignItems="center"
          >
            <Box
              component="img"
              src={miniSidenav ? logoMini : logo}
              sx={{
                height: miniSidenav ? '3rem' : '8rem',
                mt: 1,
                mb: 1,
              }}
            />
          </SoftBox>
        </SoftBox>
        <Divider />
        {!miniSidenav && (
          <SoftBox
            sx={{
              marginLeft: 2,
              marginBottom: 2,
              marginRight: 2,
              zIndex: '1050',
              position: 'relative',
              textAlign: 'center',
            }}
          >
            <SoftTypography pr={1} sx={{ fontSize: '1rem', marginBottom: 1 }}>
              {t('HOME.MENU.brand')}
            </SoftTypography>
            <SoftSelect
              options={brandOptions}
              onChange={handleSelectChange}
              value={defaultBrand}
              placeholder={t('HOME.MENU.brandPlaceholder')}
            />
          </SoftBox>
        )}
        <List>{isLoading ? <SidenavSkeleton /> : renderRoutes}</List>
      </div>
      <Footer />
    </SidenavRoot>
  );
}

// Setting default values for the props of Sidenav
Sidenav.defaultProps = {
  color: 'info',
  brand: '',
  isLoading: false,
};

// Typechecking props for the Sidenav
Sidenav.propTypes = {
  color: PropTypes.oneOf([
    'primary',
    'secondary',
    'info',
    'success',
    'warning',
    'error',
    'dark',
  ]),
  brand: PropTypes.string,
  brandName: PropTypes.string.isRequired,
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
  isLoading: PropTypes.bool,
};

export default Sidenav;
