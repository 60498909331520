import { useContext } from 'react';
import { LoaderContext } from '../../../../shared/context/loaderContext';
import { NotificationContext } from '../../../../shared/context/notificationContext';
import { useCm360Api } from 'shared/const/api-backend';
import { UserDataContext } from '../../../../shared/context/userDataContext';
import { reportError } from '../../../../shared/services/raygunService';

const useCancelPolicy = () => {
  const { channel } = useContext(UserDataContext);
  const { setLoading } = useContext(LoaderContext);
  const { showNotification } = useContext(NotificationContext);
  const API = useCm360Api();

  const cancelPolicy = async (
    policy,
    refundAmount,
    refundDate,
    cancellationReason,
  ) => {
    const policyId = policy.policy.policyNumber;
    const catalog = policy.product.iac;
    const { beneficiaries, insuranceData, subscriber, product } = policy;
    const { startDate, endDate, bookingDate } = insuranceData;
    setLoading(true);
    let result;

    try {
      const apiUrl = `/ui-proxy/ws-partners/api/platforms/${channel.psPlatform}/catalogs/${catalog}/policies/${policyId}`;
      const body = {
        refundAmount: +refundAmount,
        refundDate,
        cancellationReason,
        beneficiaries: beneficiaries.map((b) => ({
          birthDate: b.birthDate,
          firstName: b.firstName,
          lastName: b.lastName,
          subscriber: b.subscriber,
          countryCode: b.countryCode,
          idNumber: b.idNumber,
        })),
        insurance: {
          startDate,
          endDate,
          bookingDate,
          pnr: policy.policy.pnr,
          insuranceData: {
            insuranceAddons: policy.policy.genericData.insuranceAddons,
            geographicalZone: policy.policy.genericData.geographicalZone,
            insuranceOptions: policy.policy.genericData.excess,
            state: policy.policy.genericData.state,
            partnerCode: policy.policy.genericData.partnerCode,
            brand: policy.policy.genericData.brand,
            isFamilyOrCouple: policy.policy.genericData.isFamilyOrCouple,
          },
        },

        subscriber: {
          firstName: subscriber.firstName,
          lastName: subscriber.lastName,
          birthdate: subscriber.birthDate,
          email: subscriber.email,
          phone: subscriber.phone,
          mobilePhone: subscriber.mobilePhone,
          address1: subscriber.address1,
          address2: subscriber.address2,
          postalCode: subscriber.postalCode,
          city: subscriber.city,
          countryCode: subscriber.country,
          civility: subscriber.civility,
        },
        product: {
          productGroup: [
            {
              code: product.productCode,
            },
          ],
        },
        subscription: {
          price: policy.policy.price.grossPremiumBeforePromotion,
          currency: policy.policy.price.currency,
        },
        promotion: {
          promotionCode: policy.policy.price.promotionCode,
        },
      };
      await API.post(apiUrl, body);
      showNotification('Policy cancelled successfully', 'success');
      result = true;
    } catch (error) {
      showNotification('Error cancelling policy', 'error');
      reportError(error);
    } finally {
      setLoading(false);
      return result;
    }
  };

  return { cancelPolicy };
};

export default useCancelPolicy;
