import React, { useEffect } from 'react';
import DashboardNavbar from '../../../../examples/Navbars/DashboardNavbar';
import DashboardLayout from '../../../../examples/LayoutContainers/DashboardLayout';
import { Card, CardContent, Grid } from '@mui/material';
import useFetchImportantUpdatesData from '../useFetchImportantUpdatesData';
import { useTranslation } from 'react-i18next';
import typography from '../../../../assets/theme/base/typography';
import { format } from 'date-fns';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import SoftButton from 'components/SoftButton';
import BlockRenderer from 'components/StrapiBlockRenderer';
import SoftTypography from '../../../../components/SoftTypography';
import colors from '../../../../assets/theme/base/colors';

const ImportanUpdate = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data, fetchData } = useFetchImportantUpdatesData();
  const { body2 } = typography;
  const { id } = useParams();

  useEffect(() => {
    fetchData(id);
  }, []);

  const handleBackButton = () => {
    navigate('/important-updates');
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <div data-testid="ImportantUpdate">
        <Grid container spacing={2} ml={0} pr={15} mt={5}>
          <Grid item xs={12}>
            {data ? (
              <Card>
                <CardContent sx={{ margin: 5 }}>
                  <SoftTypography variant="caption" fontSize={body2.fontSize}>
                    {format(
                      new Date(data.attributes.publishedAt),
                      data.attributes.dateFormat,
                    )}
                  </SoftTypography>
                  <SoftTypography
                    variant="h2"
                    mb={2}
                    sx={{ color: colors.black.main }}
                  >
                    {data.attributes.title}
                  </SoftTypography>
                  <BlockRenderer content={data.attributes.fullDescription} />
                </CardContent>
              </Card>
            ) : (
              <SoftTypography>
                {t('TRAVEL_ALERTS.noneAvailable')}
              </SoftTypography>
            )}
          </Grid>
          <Grid item>
            <SoftButton
              variant="outlined"
              color="secondary"
              sx={{ '&:hover': { borderColor: '#0a76ec' } }}
              mt={10}
              onClick={handleBackButton}
            >
              {t('TRAVEL_ALERTS.backButton')}
            </SoftButton>
          </Grid>
        </Grid>
      </div>
    </DashboardLayout>
  );
};

ImportanUpdate.propTypes = {};

ImportanUpdate.defaultProps = {};

export default ImportanUpdate;
