import React from 'react';
import SuiInput from 'components/SoftInput';
import PropTypes from 'prop-types';

const ValidatableSuiInput = ({ errorMessage, ...props }) => {
  return (
    <div>
      <SuiInput {...props} />
      {errorMessage && (
        <p style={{ color: 'red', fontSize: '12px', margin: '4px 0 0' }}>
          {errorMessage}
        </p>
      )}
    </div>
  );
};

ValidatableSuiInput.propTypes = {
  errorMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

export default ValidatableSuiInput;
