import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import styles from './policyCancellation.module.scss';
import PriceDiffSection from './PriceDiffSection/PriceDiffSection';

const CancelledPolicyDetails = ({ policyDetails, policyRefund }) => {
  const { t } = useTranslation();

  return (
    <>
      <Typography variant="h2">
        {t('POLICY_CANCELLATION.policyCancelled')}
      </Typography>

      <Typography
        variant="body2"
        className={styles.PolicyNumber}
        color="grey.700"
      >
        {t('POLICY_CANCELLATION.policyNumber')}:{' '}
        <Typography
          variant="body2"
          component="span"
          color="gradients.info.main"
          fontWeight={700}
        >
          {policyDetails.policy.policyNumber}
        </Typography>
      </Typography>

      <PriceDiffSection
        policyDetails={policyDetails}
        policyRefund={policyRefund}
        isCancelled={true}
      />
    </>
  );
};

CancelledPolicyDetails.propTypes = {
  policyDetails: PropTypes.object.isRequired,
  policyRefund: PropTypes.number.isRequired,
};

export default CancelledPolicyDetails;
