import React, { useContext } from 'react';
import DashboardNavbar from '../../../examples/Navbars/DashboardNavbar';
import DashboardLayout from '../../../examples/LayoutContainers/DashboardLayout';
import {
  Container,
  Card,
  CardContent,
  Icon,
  ListItem,
  List,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import SoftTypography from '../../../components/SoftTypography';
import SoftBox from '../../../components/SoftBox';
import SoftButton from '../../../components/SoftButton';
import { WelcomePageConfigContext } from '../../../shared/context/welcome-page-config-context';

const SubmitAClaim = () => {
  const { t } = useTranslation();
  const { eClaimsLink } = useContext(WelcomePageConfigContext);

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <Container
        maxWidth="sm"
        style={{
          marginTop: '6rem',
        }}
      >
        <SoftTypography
          variant="h2"
          fontWeight="bold"
          sx={{ marginBottom: '2rem' }}
        >
          {t('CLAIMS.claims')}
        </SoftTypography>
        <Card sx={{ padding: '3rem' }}>
          <CardContent>
            <SoftBox sx={{ marginBottom: '2rem' }}>
              <SoftTypography variant="h3" fontWeight="bold" color="secondary">
                {t('CLAIMS.submitAClaim')}
              </SoftTypography>
            </SoftBox>
            <SoftBox sx={{ marginBottom: '2rem' }}>
              <SoftTypography variant="body2">
                {t('CLAIMS.description')}
              </SoftTypography>
              <SoftTypography variant="body2">
                {t('CLAIMS.createAccountDescription')}
              </SoftTypography>

              <List
                component="ol"
                sx={{
                  marginTop: '1rem',
                  listStyleType: 'decimal',
                  paddingLeft: '1.5rem',
                  '& li': {
                    display: 'list-item',
                  },
                }}
              >
                <ListItem component="li">
                  <SoftTypography variant="body2">
                    {t('CLAIMS.createAccountStep1')}
                  </SoftTypography>
                </ListItem>
                <ListItem component="li">
                  <SoftTypography variant="body2">
                    {t('CLAIMS.createAccountStep2')}
                  </SoftTypography>
                </ListItem>
                <ListItem component="li">
                  <SoftTypography variant="body2">
                    {t('CLAIMS.createAccountStep3')}
                  </SoftTypography>
                </ListItem>
              </List>
            </SoftBox>
            <SoftBox>
              <SoftButton
                variant="outlined"
                color="secondary"
                size="small"
                href={eClaimsLink ?? 'about:blank'}
                target="_blank"
                rel="noopener noreferrer"
                sx={{
                  display: 'inline-flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  width: 'auto',
                  '&:hover': { borderColor: '#0a76ec' }
                }}
              >
                {t('CLAIMS.openeClaims')}
                <Icon sx={{ fontWeight: 'bold', ml: 3 }}>
                  arrow_forward_ios
                </Icon>
              </SoftButton>
            </SoftBox>
          </CardContent>
        </Card>
      </Container>
    </DashboardLayout>
  );
};

export default SubmitAClaim;
