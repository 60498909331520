import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';

import PropTypes from 'prop-types';
import { AuthContext } from '../../AuthContext';
import { reportError } from 'shared/services/raygunService';
import { NotificationContext } from 'shared/context/notificationContext';
import { getLoginRouteByPlatform } from 'shared/helpers/loginPageRoute.helper';

export const featuresMap = {
  welcomePage: 'enableWelcomePage',
  dashboardWithCharts: 'enableDashboardWithCharts',
};

const contextDefaultValues = {
  portalConfiguration: null,
  appConfiguration: null,
  updateConfiguration: ({ appConfiguration, portalConfiguration }) => undefined,
  fetchPortalConfig: async () => {},
  isRouteAvailableForRole: () => false,
  isRouteAvailableForBrand: () => false,
};

export const AppConfigurationContext = createContext(contextDefaultValues);

export const AppConfigurationContextProvider = ({ children }) => {
  const { isAuthenticated, platform, token, logout } = useContext(AuthContext);
  const { showNotification } = useContext(NotificationContext);
  const navigate = useNavigate();

  const [appConfiguration, setAppConfig] = useState(
    contextDefaultValues.appConfiguration,
  );
  const [portalConfiguration, setPortalConfig] = useState(
    contextDefaultValues.portalConfiguration,
  );

  const updateConfiguration = ({ appConfiguration, portalConfiguration }) => {
    if (appConfiguration !== undefined) {
      setAppConfig(appConfiguration);
    }
    if (portalConfiguration !== undefined) {
      if (typeof portalConfiguration !== 'object') {
        throw Error(
          'Wrong type of portalConfiguration provided. Object expected',
        );
      }
      setPortalConfig(portalConfiguration);
    }
  };

  const fetchPortalConfig = async () => {
    const configfileName = platform ?? 'default';
    try {
      const res = await fetch(`/config/config_${configfileName}.json`, {
        headers: {
          'Cache-Control': 'no-cache',
        },
      });
      return await res.json();
    } catch (error) {
      throw new Error('errorFetchingPortalConfig');
    }
  };

  const fetchAndSetPortalConfig = async () => {
    try {
      const data = await fetchPortalConfig();
      setPortalConfig(data);
    } catch (error) {
      showNotification('COMMON.error.configurationError', 'error');
      reportError('Error fetching config');
      logout();
      navigate(getLoginRouteByPlatform(platform));
    }
  };

  useEffect(() => {
    if (!isAuthenticated) {
      return;
    }
    if (!portalConfiguration) {
      fetchAndSetPortalConfig();
    }
  }, [isAuthenticated, token]);

  const isRouteAvailableForRole = (route, userData, selectedBrand) => {
    const { portalPermissions } = userData;
    if (!route.availableForRoles || route.availableForRoles.length === 0) {
      return true;
    }
    return portalPermissions.some((permission) =>
      route.availableForRoles.includes(permission.key),
    );
  };

  const isRouteAvailableForBrand = (route, selectedBrand) => {
    if (!selectedBrand) return false;
    if (
      !route.notAvailableForBrands ||
      route.notAvailableForBrands.length === 0
    ) {
      return true;
    }
    return !route.notAvailableForBrands.includes(selectedBrand.code);
  };

  const isRouteAvailableWhenQuotesLocked = (route, userData) => {
    const { quotesLocked } = userData;
    const availableForQuotesLocked = route.availableForQuotesLocked ?? true;
    return !quotesLocked || availableForQuotesLocked;
  };

  const appConfig = useMemo(
    () => ({
      appConfiguration,
      portalConfiguration,
      updateConfiguration,
      fetchPortalConfig,
      isRouteAvailableForBrand,
      isRouteAvailableForRole,
      isRouteAvailableWhenQuotesLocked,
    }),
    [appConfiguration, portalConfiguration],
  );

  return (
    <AppConfigurationContext.Provider value={appConfig}>
      {children}
    </AppConfigurationContext.Provider>
  );
};

AppConfigurationContextProvider.propTypes = {
  children: PropTypes.node,
};
