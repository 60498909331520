import WelcomeList from '../welcomeLinksList/welcomeLinksList';
import { useContext } from 'react';
import { WelcomePageConfigContext } from '../../../../shared/context/welcome-page-config-context';
import { useTranslation } from 'react-i18next';

const WelcomeQuickLinks = () => {
  const { quickLinks } = useContext(WelcomePageConfigContext);

  const { t } = useTranslation();
  const contactUs = [
    ...(quickLinks || []),
    {
      internalLink: '/submit-a-claim',
      title: t('WELCOME_PAGE.QUICK_LINKS.firstLink'),
    },
    {
      internalLink: '/faq',
      title: t('WELCOME_PAGE.QUICK_LINKS.secondLink'),
    },
    {
      internalLink: '/policies',
      title: t('WELCOME_PAGE.QUICK_LINKS.thirdLink'),
    },
  ];

  return (
    <WelcomeList
      title={t('WELCOME_PAGE.QUICK_LINKS.title')}
      quickLinks={contactUs}
    />
  );
};

export default WelcomeQuickLinks;
