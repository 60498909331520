/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://material-ui.com/store/items/soft-ui-pro-dashboard/
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from 'prop-types';

// @mui material components
import Card from '@mui/material/Card';

// Soft UI Dashboard PRO React components
import SoftBox from 'components/SoftBox';
import SoftTypography from 'components/SoftTypography';

// Timeline context
import { TimelineProvider } from 'examples/Timeline/context';

function TimelineList({ title, dark, children }) {
  return (
    <TimelineProvider value={dark}>
      <Card>
        <SoftBox bgColor={dark ? 'dark' : 'white'} variant="gradient">
          {title && (
            <SoftBox pt={3} px={3}>
              <SoftTypography
                variant="h6"
                fontWeight="medium"
                color={dark ? 'white' : 'dark'}
              >
                {title}
              </SoftTypography>
            </SoftBox>
          )}
          <SoftBox p={2}>{children}</SoftBox>
        </SoftBox>
      </Card>
    </TimelineProvider>
  );
}

// Setting default values for the props of TimelineList
TimelineList.defaultProps = {
  dark: false,
  title: '',
};

// Typechecking props for the TimelineList
TimelineList.propTypes = {
  title: PropTypes.string,
  dark: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

export default TimelineList;
