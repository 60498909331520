import React, { useContext } from 'react';
import { NotificationContext } from '../../context/notificationContext';
import SoftAlert from '../../../components/SoftAlert';
import SoftBox from '../../../components/SoftBox';
import './Notification.scss';
import { useTranslation } from 'react-i18next';

const Notification = () => {
  const { notification } = useContext(NotificationContext);
  const { t } = useTranslation();

  if (!notification) {
    return null;
  }

  return (
    <div className={'notificationSystem'} data-cy="agentP_notif_modal">
      <SoftBox pt={2} px={2}>
        <SoftAlert color={notification.type} dismissible>
          {t(`${notification.message}`)}
        </SoftAlert>
      </SoftBox>
    </div>
  );
};

export default Notification;
