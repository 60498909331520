import React, { useContext, useEffect } from 'react';
import DashboardNavbar from '../../../examples/Navbars/DashboardNavbar';
import DashboardLayout from '../../../examples/LayoutContainers/DashboardLayout';
import Grid from '@mui/material/Grid';
import './dashboard-empty.scss';
import useFetchPendingQuotes from '../pending-quotes/useFetchPendingQuotes';
import PendingQuotesTable from '../pending-quotes/pending-quotes-table/pending-quotes-table';
import QuoteCard from '../new-quote/quote-card/quote-card';
import { UserDataContext } from 'shared/context/userDataContext';

const DashboardEmpty = () => {
  const { sessionContext, userData } =
    useContext(UserDataContext);
  const { data, error, fetchData } = useFetchPendingQuotes();
  const shortcuts = userData.brands;

  useEffect(() => {
    fetchData();
  }, [sessionContext]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Grid container spacing={2}>
        {shortcuts &&
          shortcuts.map((item, index) => (
            <Grid key={index} item lg={4}>
              <QuoteCard item={item} />
            </Grid>
          ))}
      </Grid>

      <PendingQuotesTable data={data} />
    </DashboardLayout>
  );
};

DashboardEmpty.propTypes = {};
DashboardEmpty.defaultProps = {};

export default DashboardEmpty;
