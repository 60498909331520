import { useContext, useState } from 'react';
import { LoaderContext } from '../../../shared/context/loaderContext';
import { NotificationContext } from '../../../shared/context/notificationContext';
import { useCm360Api } from '../../../shared/const/api-backend';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { CONFIG_APP } from '../../../shared/const/app.config';

const useFetchDashboardWidgetsData = () => {
  const { t } = useTranslation();
  const { setLoading } = useContext(LoaderContext);
  const { showNotification } = useContext(NotificationContext);
  const [data, setData] = useState(null);
  const API = useCm360Api();

  const fetchData = async (dateRange, agentIds) => {
    setLoading(true);
    try {
      const startDate = dayjs(dateRange[0]).format(
        CONFIG_APP.PLAIN_DATE_FORMAT,
      );
      const endDate = dayjs(dateRange[1]).format(CONFIG_APP.PLAIN_DATE_FORMAT);
      const url = `/dashboard`;

      const params = new URLSearchParams();
      params.append('startDate', startDate);
      params.append('endDate', endDate);

      if (agentIds.length > 0) {
        params.append('agents', agentIds.join(','));
      }

      const response = await API.get(url, { params });
      if (response.status !== 200) {
        throw new Error(t('COMMON.error.responseError'));
      }
      setData(response.data);
    } catch (error) {
      showNotification('COMMON.error.fetchingData', 'error');
    } finally {
      setLoading(false);
    }
  };

  return { data, fetchData };
};

export default useFetchDashboardWidgetsData;
