export const CONFIG_TABLE = {
  MAX_ROWS_FROM_BACKEND: 500,
  DEFAULT_NUMBER_OF_ROWS_PER_PAGE: 10,
};

export const CONFIG_APP = {
  DATE_FORMAT: 'dd/MM/yyyy',
  PLAIN_DATE_FORMAT: 'YYYY-MM-DD',
  DATE_FORMAT_DATEPICKER: 'DD/MM/YYYY',
  DATE_STRING_FORMAT: 'YYYY-MM-DDTHH:mm:ss.SSS[Z]',
  BIRTH_DATE_FORMAT: 'dd/MM/yyyy',
  DATE_RANGE_FORMAT_DATEPICKER: 'd/m/Y',
  BIRTH_DATE_FORMAT_DATEPICKER: 'd/m/Y',
  CHART_DATE_FORMAT: 'DD MMM',
  DEFAULT_DASHBOARD_DATE_RANGE: 30,
};
