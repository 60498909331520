import { useContext, useState } from 'react';
import { LoaderContext } from 'shared/context/loaderContext';
import { NotificationContext } from 'shared/context/notificationContext';
import { usePortalApi } from 'shared/const/api-backend';
import { reportError } from 'shared/services/raygunService';

const apiStrapiUrl = process.env.REACT_APP_STRAPI_API;

const useUpdateCustomerDetails = () => {
  const { setLoading } = useContext(LoaderContext);
  const { showNotification } = useContext(NotificationContext);
  const [updateError, setUpdateError] = useState(null);
  const API = usePortalApi();
  const updateCustomerDetails = async (customerId, updatedCustomer) => {
    setLoading(true);
    try {
      const response = await API.put(`${apiStrapiUrl}customers/${customerId}`, {
        customer: updatedCustomer,
      });
    } catch (error) {
      setUpdateError(error);
      showNotification('CUSTOMERS.ERRORS.updateFailed', 'error');
      reportError(error);
    } finally {
      setLoading(false);
    }
  };

  return { updateCustomerDetails, updateError };
};

export default useUpdateCustomerDetails;
