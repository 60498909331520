export const policyCancellationReasons = [
  {
    value: 'payment_dispute',
    label: 'POLICY_CANCELLATION.reasons.paymentDispute',
    isAdminOnly: true,
  },
  {
    value: 'change_of_mind',
    label: 'POLICY_CANCELLATION.reasons.changeOfMind',
    isAdminOnly: false,
  },
  {
    value: 'supplier_change',
    label: 'POLICY_CANCELLATION.reasons.supplierChange',
    isAdminOnly: false,
  },
  {
    value: 'trip_cancelled',
    label: 'POLICY_CANCELLATION.reasons.tripCancelled',
    isAdminOnly: false,
  },
  {
    value: 'other',
    label: 'POLICY_CANCELLATION.reasons.other',
    isAdminOnly: true,
  },
  {
    value: 'not_eligible',
    label: 'POLICY_CANCELLATION.reasons.notEligible',
    isAdminOnly: false,
  },
];
