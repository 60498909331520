import React, { useContext, useEffect, useRef, useState } from 'react';
import { t } from 'i18next';
import SoftButton from '../../../../../components/SoftButton';
import CheckIcon from '@mui/icons-material/Check';
import PropTypes from 'prop-types';
import { AppConfigurationContext } from '../../../../../shared/context/configurationContext';
import { LoaderContext } from '../../../../../shared/context/loaderContext';
import { NotificationContext } from '../../../../../shared/context/notificationContext';
import { useCm360Api } from '../../../../../shared/const/api-backend';
import { reportError } from 'shared/services/raygunService';

const PciPal = ({
  policyDetails,
  endorsementPrice,
  isFormValid,
  registerEndorsement,
}) => {
  const [sessionData, setSessionData] = useState(null);
  const [endorsementPriceValue, setEndorsementPrice] = useState(null);
  const formRef = useRef(null);
  const { setLoading } = useContext(LoaderContext);
  const { showNotification } = useContext(NotificationContext);
  const { appConfiguration } = useContext(AppConfigurationContext);
  const { region, environment, tenantId } =
    appConfiguration.attributes.PaymentConfigPCIPAL.PCIPAL;
  const API = useCm360Api();

  const createSession = async () => {
    setLoading(true);
    const sessionPayload = {
      policyId: policyNumber,
      amount: +endorsementPrice,
    };
    API.post(`/pcipal/session/endorsement`, sessionPayload)
      .then((res) => {
        if (res.status !== 200) {
          showNotification('unexpectedError', 'error', false);
        } else {
          setSessionData(res.data);
        }
      })
      .catch((error) => {
        showNotification('unexpectedError', 'error', false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (
      isFormValid &&
      (!sessionData || +endorsementPrice !== endorsementPriceValue) &&
      +endorsementPrice &&
      +endorsementPrice > 0
    ) {
      setEndorsementPrice(+endorsementPrice);
      createSession();
    }
  }, [isFormValid, sessionData, endorsementPrice]);

  const checkout = async () => {
    setLoading(true);
    try {
      const sessionPollPayload = {
        policyId: policyDetails.policy.policyNumber,
        sessionId: sessionData?.Id,
        keepAlive: true,
      };
      const response = await API.post(
        `/pcipal/poll/endorsement`,
        sessionPollPayload
      );
      if (response.status === 200) {
        const poolData = response.data;
        if (poolData.IsSessionComplete) {
          if (
            poolData.VariableData.OProcessorResponseText.Value.toLowerCase() ===
            'approved'
          ) {
            registerEndorsement(poolData.VariableData.OTransactionId.Value);
            setLoading(false);
          } else {
            setLoading(false);
            showNotification('unexpectedErrorPayment', 'error', false);
          }
        } else {
          setTimeout(checkout, 5000);
        }
      }
    } catch (error) {
      showNotification('unexpectedError', 'error', false);
      setLoading(false);
      reportError(error);
    }
  };

  const policyNumber = policyDetails.policy.policyNumber;

  const submitPciPal = () => {
    if (formRef.current) {
      checkout();
      formRef.current.submit();
    }
  };

  return (
    <div className="pciPalContainer">
      {sessionData !== null && endorsementPrice && (
        <form
          ref={formRef}
          id="embedForm"
          name="embedForm"
          method="post"
          target="_blank"
          action={`https://${region}.${environment}.cloud/session/${tenantId}/view/${sessionData.Id}/framed`}
        >
          <input
            type="hidden"
            name="X-BEARER-TOKEN"
            value={sessionData.accessToken}
          />
          <input
            type="hidden"
            name="X-REFRESH-TOKEN"
            value={sessionData.refreshToken}
          />
          <SoftButton
            type="submit"
            size="small"
            color="info"
            onClick={(e) => {
              e.preventDefault();
              submitPciPal();
            }}
          >
            <CheckIcon sx={{ marginRight: '0.2rem' }} />
            {t('COMMON.pay')}
          </SoftButton>
        </form>
      )}
    </div>
  );
};

PciPal.propTypes = {
  policyDetails: PropTypes.object,
  endorsementPrice: PropTypes.number,
  isFormValid: PropTypes.bool,
  registerEndorsement: PropTypes.func,
};

export default PciPal;
