/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://material-ui.com/store/items/soft-ui-pro-dashboard/
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from 'App';
import './i18n'; // Import the i18n configuration

import { SoftUIControllerProvider } from 'context';
import { AuthProvider } from './AuthContext';
import { AppConfigurationContextProvider } from 'shared/context/configurationContext';
import { UserDataProvider } from 'shared/context/userDataContext';
import { NotificationProvider } from './shared/context/notificationContext';
import { WelcomePageConfigProvider } from './shared/context/welcome-page-config-context';
import GoogleTagManager from './shared/components/GoogleTagManager/GoogleTagManager';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <BrowserRouter>
    <GoogleTagManager />
    <SoftUIControllerProvider>
      <NotificationProvider>
        <AuthProvider>
          <UserDataProvider>
            <AppConfigurationContextProvider>
              <WelcomePageConfigProvider>
                <App />
              </WelcomePageConfigProvider>
            </AppConfigurationContextProvider>
          </UserDataProvider>
        </AuthProvider>
      </NotificationProvider>
    </SoftUIControllerProvider>
  </BrowserRouter>,
);
