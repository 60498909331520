import { useContext, useEffect, useState } from 'react';
import { LoaderContext } from '../../../shared/context/loaderContext';
import { NotificationContext } from '../../../shared/context/notificationContext';
import { useCm360Api } from '../../../shared/const/api-backend';
import { CONFIG_TABLE } from '../../../shared/const/app.config';
import { UserDataContext } from 'shared/context/userDataContext';
import { reportError } from 'shared/services/raygunService';

const useFetchPoliciesData = () => {
  const { selectedBusinessUnit, selectedBrand, sessionContext } = useContext(UserDataContext);
  const { setLoading } = useContext(LoaderContext);
  const { showNotification } = useContext(NotificationContext);
  const [error, setError] = useState(null);
  const [data, setData] = useState([]);
  const API = useCm360Api();

  const fetchData = async (policyId) => {
    setLoading(true);
    try {
      let apiUrl = `policies?offset=0&limit=${CONFIG_TABLE.MAX_ROWS_FROM_BACKEND}`;
      if (policyId) {
        apiUrl += `&filter=${policyId}`;
      }

      const response = await API.get(apiUrl);
      setData(response.data);
    } catch (error) {
      showNotification('COMMON.error.fetchingData', 'error');
      reportError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!selectedBusinessUnit || !selectedBrand) {
      showNotification('COMMON.error.noBusinessUnitSelected', 'error');
      return;
    } else if (
      selectedBusinessUnit.length === 0 ||
      selectedBrand.length === 0
    ) {
      return;
    }
    fetchData();
  }, [sessionContext]);

  return { data, error, fetchData };
};

export default useFetchPoliciesData;
