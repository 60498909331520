import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import { IconButton } from '@mui/material';
import GenericTable from 'shared/components/GenericTable/genericTable';
import { LoaderContext } from 'shared/context/loaderContext';
import './endorsements-table.scss';
import SoftBox from 'components/SoftBox';

const EndorsementsTable = ({ data, openDetailsHandler }) => {
  const { t } = useTranslation();
  const { loading } = useContext(LoaderContext);
  const path = 'POLICY_DETAILS.ENDORSEMENTS';

  const columns = [
    {
      label: t(`${path}.endorsementNumber`),
      value: (endorsement) => endorsement.endorsementNumber,
    },
    {
      label: t(`${path}.status`),
      value: (endorsement) => {
        if (endorsement.statusId === 8)
          return (
            <SoftBox sx={{ color: 'green' }}>
              {t('POLICY_DETAILS.ENDORSEMENTS.validated')}
            </SoftBox>
          );
        if (endorsement.statusId === 13)
          return (
            <SoftBox sx={{ color: 'red' }}>
              {t('POLICY_DETAILS.ENDORSEMENTS.cancelled')}
            </SoftBox>
          );
      },
    },
    {
      label: t(`${path}.actions`),
      value: () => (
        <IconButton size="small" color="info">
          <PlayCircleIcon />
        </IconButton>
      ),
      width: '70px',
    },
  ];

  return (
    <div className="endorsements-table" data-testid="EndorsementsTable">
      {!data || data.length === 0 ? (
        <p className="no-data">{!loading && t(`${path}.noValuables`)}</p>
      ) : (
        <>
          <GenericTable
            data={data}
            columns={columns}
            path={path}
            onRowClick={(endorsement) =>
              openDetailsHandler(endorsement.endorsementNumber)
            }
          />
        </>
      )}
    </div>
  );
};

EndorsementsTable.propTypes = {
  data: PropTypes.array.isRequired,
  openDetailsHandler: PropTypes.func.isRequired,
};

EndorsementsTable.defaultProps = {};

export default EndorsementsTable;
