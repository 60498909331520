import { Skeleton } from '@mui/material';
import ListItem from '@mui/material/ListItem';

import SoftBox from 'components/SoftBox';

const SidenavSkeleton = () => {
  return (
    <>
      {[...Array(5)].map((_, index) => {
        return (
          <ListItem key={index} component="li" sx={{ marginBottom: '8px' }}>
            <SoftBox
              sx={{ width: '100%', marginLeft: '16px', marginRight: '16px' }}
            >
              <Skeleton
                variant="rectangular"
                sx={{
                  width: '100%',
                  height: 50,
                  borderRadius: '8px',
                }}
              />
            </SoftBox>
          </ListItem>
        );
      })}
    </>
  );
};

export default SidenavSkeleton;
