import React, { useState } from 'react';
import styles from './policies.module.scss';
import DashboardNavbar from '../../../examples/Navbars/DashboardNavbar';
import DashboardLayout from '../../../examples/LayoutContainers/DashboardLayout';
import { useTranslation } from 'react-i18next';
import useFetchPoliciesData from './useFetchPoliciesData';
import SuiInput from '../../../components/SoftInput';
import PoliciesTable from './policies-table.js/policies-table';
import SoftTypography from 'components/SoftTypography';
import SoftButton from '../../../components/SoftButton';
import SoftBox from '../../../components/SoftBox';
import { useGenerateUuid } from 'shared/utils/useGenerateUuid';
import { emit$, AppEvents } from 'shared/services/app-events';

const Policies = () => {
  const { t } = useTranslation();
  const [searchPolicyId, setSearchPolicyId] = useState('');
  const { data, fetchData } = useFetchPoliciesData();

  const tableUuid = useGenerateUuid();

  const handleSearchButtonClick = () => {
    emit$(AppEvents.RESET_TABLE_COUNTER, tableUuid);
    fetchData(searchPolicyId.trim());
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSearchButtonClick();
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <div className={styles.Policies} data-testid="Policies">
        <SoftBox
          sx={{
            marginTop: '6rem',
          }}
        >
          <SoftTypography
            variant="h2"
            fontWeight="bold"
            sx={{ marginBottom: '2rem' }}
          >
            {t('POLICIES.header')}
          </SoftTypography>
        </SoftBox>
        <div className={`${styles['search-container']}`}>
          <SoftBox className={styles['search-input']}>
            <SuiInput
              type="search"
              placeholder={t('POLICIES.search')}
              label={t('POLICIES.search')}
              value={searchPolicyId}
              onChange={(event) => setSearchPolicyId(event.target.value)}
              variant="outlined"
              onKeyPress={handleKeyPress}
            />
          </SoftBox>
          <SoftButton
            variant="outlined"
            color="info"
            onClick={handleSearchButtonClick}
            style={{ color: '#0a76ec', borderColor: '#0a76ec' }}
          >
            {t('POLICIES.searchButton')}
          </SoftButton>
        </div>
        <PoliciesTable tableUuid={tableUuid} data={data}></PoliciesTable>
      </div>
    </DashboardLayout>
  );
};

Policies.propTypes = {};

Policies.defaultProps = {};

export default Policies;
