import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import SuiPagination from '../../../components/SoftPagination';
import Icon from '@mui/material/Icon';
import { CONFIG_TABLE } from '../../const/app.config';
import './genericTable.scss';
import { UserDataContext } from '../../context/userDataContext';
import { useTranslation } from 'react-i18next';
import { AppEvents } from 'shared/services/app-events';
import { subscribe$, unsubscribe$ } from 'shared/services/app-events';
import colors from '../../../assets/theme/base/colors';

const GenericTable = ({ data, columns, onRowClick, tableUuid }) => {
  const { t } = useTranslation();

  const { selectedBusinessUnit, selectedBrand } = useContext(UserDataContext);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = CONFIG_TABLE.DEFAULT_NUMBER_OF_ROWS_PER_PAGE;
  const defaultBackgroundColor = colors.background.default;

  useEffect(() => {
    const resetCurrentPage = (recivedUuid) => {
      if (recivedUuid && typeof recivedUuid !== 'string') {
        throw new Error(
          `[resetCurrentPage] expected event payload: string; recived ${typeof recivedUuid}`,
        );
      }

      if (tableUuid === recivedUuid) {
        setCurrentPage(1);
      }
    };

    subscribe$(AppEvents.RESET_TABLE_COUNTER, resetCurrentPage);

    return () => unsubscribe$(AppEvents.RESET_TABLE_COUNTER, resetCurrentPage);
  }, [tableUuid]);

  return (
    <div className="GenericTable" data-testid="GenericTable">
      {!data || data.length === 0 ? (
        selectedBusinessUnit && selectedBrand ? (
          <p className="dataError"> {t('COMMON.noDataAvailable')} </p>
        ) : (
          <p className="dataError"> {t('COMMON.noBusinessUnitSelected')} </p>
        )
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead sx={{ backgroundColor: defaultBackgroundColor }}>
              <TableRow>
                {columns.map((column, index) => (
                  <TableCell
                    key={index}
                    style={{ width: column.width || 'auto' }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data
                .slice(
                  (currentPage - 1) * itemsPerPage,
                  currentPage * itemsPerPage,
                )
                .map((row, index) => (
                  <TableRow
                    className="tableRow"
                    key={index}
                    onClick={() => onRowClick(row)}
                  >
                    {columns.map((column, columnIndex) => (
                      <TableCell key={columnIndex}>
                        {row
                          ? column.value
                            ? column.value(row)
                            : row[column.field]
                          : ''}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <div className="paginationContainer">
        <SuiPagination>
          <SuiPagination
            item
            onClick={() => setCurrentPage(currentPage - 1)}
            disabled={currentPage === 1}
            className="paginationArrow"
          >
            <Icon>keyboard_arrow_left</Icon>
          </SuiPagination>
          <SuiPagination
            item
            onClick={() => setCurrentPage(currentPage + 1)}
            disabled={currentPage === Math.ceil(data.length / itemsPerPage)}
            className="paginationArrow"
          >
            <Icon>keyboard_arrow_right</Icon>
          </SuiPagination>
        </SuiPagination>
      </div>
    </div>
  );
};

GenericTable.propTypes = {
  data: PropTypes.array.isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.func,
    }),
  ).isRequired,
  onRowClick: PropTypes.func.isRequired,
  tableUuid: PropTypes.string,
};

export default GenericTable;
