import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardActionArea, CardContent, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import SoftTypography from '../../../../../components/SoftTypography';
import typography from '../../../../../assets/theme/base/typography';
import colors from '../../../../../assets/theme/base/colors';

const UpdateCard = ({ update }) => {
  const { size, fontWeightBold } = typography;
  return (
    <Card sx={{ mb: '1.5rem' }}>
      <CardActionArea component={Link} to={`/important-updates/${update.id}`}>
        <CardContent sx={{ px: '4rem', py: '1.5rem' }}>
          <SoftTypography
            variant="h5"
            sx={{
              color: colors.text.headingGrey,
              fontWeight: fontWeightBold,
              fontSize: size.lg,
            }}
          >
            {update.attributes.title}
          </SoftTypography>
          <SoftTypography
            variant="body2"
            mt={1}
            sx={{ color: colors.black.main }}
          >
            {update.attributes.shortDescription}
          </SoftTypography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

UpdateCard.propTypes = {
  update: PropTypes.shape({
    id: PropTypes.string.isRequired,
    attributes: PropTypes.shape({
      title: PropTypes.string.isRequired,
      shortDescription: PropTypes.string,
    }).isRequired,
  }).isRequired,
};

export default UpdateCard;
