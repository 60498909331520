const USER_ROLE = {
  SALES_RO: 'sales-ro',
  SALES: 'sales',
  SALES_POST: 'sales-post',
  SALES_ADVANCE: 'sales-advance',
  CLAIMS: 'claims',
  VIEW_ALL: 'view-all'
};

const BRAND = {
  FCB: 'FCB',
};

export { USER_ROLE, BRAND };
