export const endorsementTypes = [
  {
    value: 'VALUABLES',
    label: 'Valuables',
    endorsementTypeId: 1,
  },
  {
    value: 'TRIP DURATION',
    label: 'Trip duration',
    endorsementTypeId: 3,
  },
];
