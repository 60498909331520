import React, { useContext, useEffect, useState } from 'react';
import DashboardNavbar from '../../../examples/Navbars/DashboardNavbar';
import DashboardLayout from '../../../examples/LayoutContainers/DashboardLayout';
import Grid from '@mui/material/Grid';
import './dashboard-with-widgets.scss';
import { UserDataContext } from 'shared/context/userDataContext';
import useFetchDashboardWidgetsData from './useFetchDashboardWidgetsData';
import SoftBox from 'components/SoftBox';
import SoftDatePicker from 'components/SoftDatePicker';
import dayjs from 'dayjs';
import { Icon } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { CONFIG_APP } from 'shared/const/app.config';
import SalesChartWidget from './widgets/sales-chart-widget/sales-chart-widget';
import PolicyRevenueWidget from './widgets/policy-revenue-widget/policy-revenue-widget';
import ProductsWidget from './widgets/products-widget/products-widget';
import ExpiringQuotesWidget from './widgets/expiring-quotes-widget/expiring-quotes-widget';
import ConversionRateWidget from './widgets/conversion-rate-widget/conversion-rate-widget';

const DashboardWithWidgets = () => {
  const { t } = useTranslation();
  const { selectedBusinessUnit, sessionContext } = useContext(UserDataContext);
  const { data, fetchData } = useFetchDashboardWidgetsData();
  const [widgetData, setWidgetData] = useState(null);
  const [openedWidget, setOpenedWidget] = useState(null);

  const getCurrentMonthRange = () => {
    const start = dayjs()
      .subtract(CONFIG_APP.DEFAULT_DASHBOARD_DATE_RANGE, 'day')
      .toDate();
    const end = dayjs().toDate();
    return [start, end];
  };
  const [dateRange, setDateRange] = useState(getCurrentMonthRange());

  useEffect(() => {
    if (selectedBusinessUnit?.id) {
      // TODO: handle agent array in ESHOP-499
      fetchData(dateRange, []);
    }
  }, [sessionContext, dateRange]);

  useEffect(() => {
    if (data) {
      setWidgetData([
        {
          name: 'sales-chart-widget',
          component: (
            <SalesChartWidget
              data={{
                chart: {
                  labels: data.sales?.storeCurrent?.map((s) =>
                    dayjs(s?.date).format(CONFIG_APP.CHART_DATE_FORMAT),
                  ),
                  datasets: [
                    {
                      label: t('HOME.DASHBOARD.widgets.yourSales'),
                      color: 'info',
                      data: data.sales?.data?.map((s) => s?.value),
                    },
                    {
                      label: t('HOME.DASHBOARD.widgets.yourPreviously'),
                      color: 'secondary',
                      data: data.sales?.comparison?.data?.map((s) => s?.value),
                    },
                    {
                      label: t('HOME.DASHBOARD.widgets.storeSales'),
                      color: 'warning',
                      data: data.sales?.storeCurrent?.map((s) => s?.value),
                    },
                  ],
                },
                total: `${data.sales?.totalSum ?? 0}/${data.sales?.totalStoreSum ?? 0}`,
                diffPercentage: data.sales?.comparison?.diffPercentage,
                trend: data.sales?.comparison?.trend,
              }}
              openWidget={() => setOpenedWidget('sales-chart-widget')}
              closeWidget={() => setOpenedWidget(null)}
              isOpened={openedWidget === 'sales-chart-widget'}
            />
          ),
        },
        {
          name: 'conversion-rate-widget',
          component: (
            <ConversionRateWidget
              data={{
                chart: {
                  labels: data.conversionRate?.data?.map((s) =>
                    dayjs(s?.date).format(CONFIG_APP.CHART_DATE_FORMAT),
                  ),
                  datasets: [
                    {
                      label: t('HOME.DASHBOARD.widgets.conversionRate'),
                      color: 'info',
                      data: data.conversionRate?.data?.map((s) => s?.value),
                    },
                    {
                      label: t('HOME.DASHBOARD.widgets.previously'),
                      color: 'secondary',
                      data: data.conversionRate?.comparison?.data?.map(
                        (s) => s?.value,
                      ),
                    },
                  ],
                },
                total: data.conversionRate?.totalSum,
                diffPercentage: data.conversionRate?.comparison?.diffPercentage,
                trend: data.conversionRate?.comparison?.trend,
              }}
              openWidget={() => setOpenedWidget('conversion-rate-widget')}
              closeWidget={() => setOpenedWidget(null)}
              isOpened={openedWidget === 'conversion-rate-widget'}
            />
          ),
        },
        {
          name: 'policy-revenue-widget',
          component: (
            <PolicyRevenueWidget
              data={{
                chart: {
                  labels: data.policyRevenue?.data?.map((r) =>
                    dayjs(r?.date).format(CONFIG_APP.CHART_DATE_FORMAT),
                  ),
                  datasets: [
                    {
                      label: t('HOME.DASHBOARD.widgets.policyRevenue'),
                      color: 'info',
                      data: data.policyRevenue?.data?.map((r) => r?.value),
                    },
                    {
                      label: t('HOME.DASHBOARD.widgets.previously'),
                      color: 'secondary',
                      data: data.policyRevenue?.comparison?.data?.map(
                        (r) => r?.value,
                      ),
                    },
                  ],
                },
                total: data.policyRevenue?.totalSum,
                diffPercentage: data.policyRevenue?.comparison?.diffPercentage,
                trend: data.policyRevenue?.comparison?.trend,
                currency: data.policyRevenue?.currencyCode,
              }}
              openWidget={() => setOpenedWidget('policy-revenue-widget')}
              closeWidget={() => setOpenedWidget(null)}
              isOpened={openedWidget === 'policy-revenue-widget'}
            />
          ),
        },
        {
          name: 'products-widget',
          component: (
            <ProductsWidget
              data={{
                chart: {
                  labels: data?.productSplit?.map((p) => p?.name),
                  datasets: {
                    backgroundColors: [
                      'info',
                      'primary',
                      'dark',
                      'secondary',
                      'light',
                      'warning',
                      'success',
                      'error',
                    ],
                    data: data?.productSplit?.map((p) => p?.value),
                  },
                },
              }}
              openWidget={() => setOpenedWidget('products-widget')}
              closeWidget={() => setOpenedWidget(null)}
              isOpened={openedWidget === 'products-widget'}
            />
          ),
        },
        {
          name: 'expiring-quotes-widget',
          component: (
            <ExpiringQuotesWidget
              data={{
                tableData: data?.expiringQuotesList?.quotes,
                total: data?.expiringQuotesList?.totalNumberOfQuotes,
              }}
              openWidget={() => setOpenedWidget('expiring-quotes-widget')}
              closeWidget={() => setOpenedWidget(null)}
              isOpened={openedWidget === 'expiring-quotes-widget'}
            />
          ),
        },
      ]);
    }
  }, [data, openedWidget]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox sx={{ maxWidth: '238px', mb: 2 }}>
        <SoftDatePicker
          type="date"
          input={{
            placeholder: t('COMMON.selectDateRange'),
            icon: {
              component: (
                <Icon fontSize="small" color="inherit">
                  date_range
                </Icon>
              ),
              direction: 'left',
            },
          }}
          onChange={(e) => {
            if (e?.length === 2) {
              setDateRange(e);
            }
          }}
          options={{
            mode: 'range',
            defaultDate: getCurrentMonthRange(),
            dateFormat: CONFIG_APP.DATE_RANGE_FORMAT_DATEPICKER,
            minDate: dayjs().subtract(2, 'year').toISOString(),
            maxDate: dayjs().toISOString(),
          }}
        />
      </SoftBox>
      {openedWidget ? (
        <>{widgetData.find((d) => d.name === openedWidget)?.component}</>
      ) : (
        <SoftBox sx={{ flexDirection: 'column' }}>
          <SoftBox className="widgets">
            {data &&
              widgetData?.map((item) => (
                <SoftBox key={item.name}>{item.component}</SoftBox>
              ))}
          </SoftBox>
        </SoftBox>
      )}
    </DashboardLayout>
  );
};

DashboardWithWidgets.propTypes = {};
DashboardWithWidgets.defaultProps = {};

export default DashboardWithWidgets;
