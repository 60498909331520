import { EventEmitter } from 'events';

const eventEmitter = new EventEmitter();

export const AppEvents = {
    RESET_TABLE_COUNTER: 'RESET_TABLE_COUNTER'
};

export const subscribe$ = (eventName, callback) => 
  eventEmitter.on(eventName, callback);

export const emit$ = (eventName, data) => 
  eventEmitter.emit(eventName, data);

export const unsubscribe$ = (eventName, callback) => 
  eventEmitter.off(eventName, callback);

export const createAppEventHandler = (eventName) => ({
  subscribe: (callback) => subscribe$(eventName, callback),
  emit: (data) => emit$(eventName, data),
  unsubscribe: (callback) => unsubscribe$(eventName, callback),
});