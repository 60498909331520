import { useContext, useState } from 'react';
import { LoaderContext } from '../../../shared/context/loaderContext';
import { NotificationContext } from '../../../shared/context/notificationContext';
import { usePortalApi } from '../../../shared/const/api-backend';
import { useTranslation } from 'react-i18next';
import { UserDataContext } from 'shared/context/userDataContext';

const useFetchResourcesData = () => {
  const { t } = useTranslation();
  const { setLoading } = useContext(LoaderContext);
  const { showNotification } = useContext(NotificationContext);
  const { selectedBusinessUnit } = useContext(UserDataContext)
  const [data, setData] = useState(null);
  const API = usePortalApi();

  const fetchData = async () => {
    setLoading(true);
    try {
      const url = `/resources?populate=image&sort[0]=publishedAt:asc&filters[countries][country][$eqi]=${selectedBusinessUnit.country.country}`;
      const response = await API.get(url);
      if (response.status !== 200) {
        throw new Error(t('COMMON.error.responseError'));
      }
      setData(response.data.data);
    } catch (error) {
      showNotification('COMMON.error.fetchingData', 'error');
    } finally {
      setLoading(false);
    }
  };

  return { data, fetchData };
};

export default useFetchResourcesData;
